import { withStyles, } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';

import { SECONDARY_LIGHT, WHITE } from '../../styles/colors';

const StyledTabs = withStyles({
    root: {
        marginTop: "60px",
        backgroundColor: SECONDARY_LIGHT,
        color: WHITE,
    },
    indicator: {
        backgroundColor: '#1890ff',
    },
})(Tabs);

export default StyledTabs;