import { withStyles, } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';

import { WHITE, SECONDARY } from '../../styles/colors';

const StyledTableRow = withStyles(() => ({
    root: {
        backgroundColor: SECONDARY,
        color: WHITE,
        '&:nth-of-type(even)': {
            backgroundColor: "rgba(255, 255, 255, 0.05)"
        },
    }
}
))(TableRow);

export default StyledTableRow;