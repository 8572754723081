import { withStyles, } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { emphasize } from "@material-ui/core/styles/colorManipulator";

import { WHITE, PRIMARY_LIGHT, PRIMARY_DARK } from '../../styles/colors';

const TableIconButton = withStyles(() => ({
    root: {
        backgroundColor: PRIMARY_LIGHT,
        color: WHITE,
        borderRadius: 0,
        margin: "0 0 0 8px",
        width: "45px",
        height: "24px",
        fontSize: "16px",
        "&:hover": {
            backgroundColor: emphasize(PRIMARY_DARK, 0.08),
            boxShadow: "none",
        },
    },
    disabled: {
        color: "#ffffff42!important",
        boxShadow: "none",
        backgroundColor: "#00A8F142!important"
    }
}
))(IconButton);


export default TableIconButton;