export const userForm = [
    {
        id: "salutation",
        label: "Anrede",
        type: "select",
        value: "",
        required: false,
        colLg: 3,
        colMd: 6,
        colSm: 12,
        options: [{ value: "mrs", text: "Frau" }, { value: "mr", text: "Herr" }],
    },
    {
        id: "academic_degree",
        label: "Titel",
        type: "text",
        inputType: "text",
        value: "",
        required: false,
        max: 31,
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "job_title",
        label: "Job Funktion",
        type: "select",
        value: "",
        required: false,
        max: 255,
        colLg: 3,
        colMd: 6,
        colSm: 12,
        options: [
            { value: "therapist", text: "Therapy Specialist" },
            { value: "senior_therapist", text: "Senior Therapy Specialist" },
            { value: "sales", text: "Sales und Therapy Specialist" },
            { value: "market_development", text: "Market Development Specialist" },
            { value: "regional_sales", text: "Regional Sales Manager" },
            { value: "product", text: "Product Manager" },
            { value: "network", text: "Hospital Network Specialist" },
            { value: "senior_network", text: "Senior Hospital Network Specialist" }
        ],
    },
    {
        id: "first_name",
        label: "Vorname",
        type: "text",
        inputType: "text",
        value: "",
        required: false,
        max: 255,
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "name",
        label: "Nachname",
        type: "text",
        inputType: "text",
        value: "",
        required: true,
        max: 255,
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "tel",
        label: "Telefon",
        type: "text",
        inputType: "tel",
        value: "",
        required: false,
        max: 255,
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "email",
        label: "E-Mail",
        type: "text",
        inputType: "email",
        value: "",
        required: true,
        max: 255,
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "city",
        label: "Ort",
        type: "text",
        inputType: "text",
        value: "",
        required: false,
        max: 255,
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "country",
        label: "Land",
        type: "text",
        inputType: "text",
        value: "",
        required: false,
        max: 255,
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "password",
        label: "Passwort",
        type: "text",
        inputType: "password",
        value: "",
        required: true,
        min: 8,
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "password_confirmation",
        label: "Passwort wiederholen",
        type: "text",
        inputType: "password",
        value: "",
        required: true,
        min: 8,
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
]

export const userData = {
    salutation: "",
    academic_degree: "",
    job_title: "",
    first_name: "",
    name: "",
    tel: "",
    email: "",
    city: "",
    country: "",
    password: "",
    password_confirmation: "",
    role: "external",
}