import React from 'react';

const Role = (props) => {
    let role ="";

    if(props.role) {
        if (props.role[0] === "admin") role = "Admin";
        if (props.role[0] === "external") role = "Außendienst";
        if (props.role[0] === "internal") role = "Innendienst";
    }

    return (
        <span>{role}</span>
    );
}

export default Role;