import { withStyles, } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import { SECONDARY_LIGHT } from '../../styles/colors';

const StyledTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: SECONDARY_LIGHT,
      color: 'rgba(255, 255, 255, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
    arrow: {
        color: SECONDARY_LIGHT,
      }
  }))(Tooltip);

export default StyledTooltip;