import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    container: {
        maxWidth: "1180px",
        margin: "150px auto",
        boxSizing: "conten-box",
        padding: "0 1rem",
        textAlign: "center",
    }
}));

const Error401 = (props) => {
    const classes = useStyles();
    return(
        <section>
            <div className={classes.container}>
                <h1><strong>Fehler 401</strong> - Nicht autorisiert</h1>
                <p>Hey Sie, bitte stoppen. Autorisierung erforderlich.</p>
            </div>
        </section>
    );
}

export default Error401;