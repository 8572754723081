import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from "react-router-dom";

import "../deleteDialog/DeleteDialog.css";
import { SECONDARY, WHITE } from "../../../styles/colors";
import { StyledButton } from "../..";

const useStyles = makeStyles(() => ({
    paper: {
        backgroundColor: SECONDARY,
        color: WHITE,
        borderRadius: 0,
    },
    content: {
        color: WHITE,
    },
}));



export const DuplicateDoctorDialog = ({ isOpen, handleClose, physicians, forceCreation }) => {
    const classes = useStyles();
    const history = useHistory();

    const getName = (lastname, title, firstname) => {
        let name = lastname;
        if (title !== null || firstname !== "") {
            name += (", ");
            if (title !== null) {
                name += title;
                name += " ";
            }
            if (firstname !== "") {
                name += firstname;
            }
        }
        return name
    }

    const navigateToDetail = (id) => {
        history.push("/doctor/" + id);
    };


    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: classes.paper }}
            disableScrollLock={true}
        >
            <DialogTitle id="alert-dialog-title">{"Duplikat Warnung"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" classes={{ root: classes.content }}>
                    Möglicherweise gibt es bereits eine Übereinstimmung für diesen Arzt. Bitte schauen Sie sich die potenziellen Duplikate an und entscheiden, ob der Arzt dennoch erstellt werden soll.
        </DialogContentText>
                <List>
                    {physicians.map((physician) => (
                        <ListItem
                            key={physician.id}
                            button
                            onClick={() => navigateToDetail(physician.id)}
                        >
                            <ListItemText
                                primary={getName(physician.last_name, physician.academic_degree, physician.first_name)}
                                secondary={physician.email}
                            />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={forceCreation}
                    color="secondary"
                    className="secondaryBtn"
                >
                    Erstellen erzwingen
        </Button>
                <StyledButton
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    autoFocus
                >schließen</StyledButton>
            </DialogActions>
        </Dialog>
    );
};

DuplicateDoctorDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    forceCreation: PropTypes.func.isRequired,
    pysicians: PropTypes.array,
};

export default DuplicateDoctorDialog;
