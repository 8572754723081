import React, { useState, useEffect } from "react";
import { TopContent } from "../../components";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import EditSharpIcon from "@material-ui/icons/EditSharp";
import DeleteForeverSharp from "@material-ui/icons/DeleteForeverSharp";
import TableFooter from "@material-ui/core/TableFooter";
import { useHistory } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import http from "../../utils/http-common";
import { SECONDARY, WHITE_TRANSPARENT } from "../../styles/colors";
import {
  StyledTableRow,
  TableIconButton,
  NormalTableCell,
  FunctionsTableCell,
  TablePaginationActions,
  StyledButton,
  StyledTooltip,
  SearchBar,
  FilterableTableHead,
  DeleteDialog,
} from "../../components";
import { getAuthUser } from "../../utils/authUser";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 800,
  },
  title: {
    flex: "1 1 100%",
    textTransform: "uppercase",
    letterSpacing: "1px",
  },
  tableToolbar: {
    padding: "0",
    minHeight: "40px",
    marginTop: "50px",
    marginBottom: "10px",
  },
  darkerSection: {
    backgroundColor: SECONDARY,
  },
  pagination: {
    color: WHITE_TRANSPARENT,
    border: 0,
    backgroundColor: "#1a617f",
  },
  selectIcon: {
    color: WHITE_TRANSPARENT,
  },
  hoverTableRow: {
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      cursor: "pointer",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const headCells = [
  { id: "users.id", label: "ID", filterable: true },
  { id: "users.name", label: "Name", filterable: true },
  { id: "users.city", label: "Ort", filterable: true },
  { id: "users.role", label: "Zuordnung", filterable: false },
];

const Users = (props) => {
  const [users, setUsers] = useState([]);
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalEntries, setTotalEntries] = React.useState(0);
  const history = useHistory();
  const [searchStr, setSearchStr] = useState("");
  const [order, setOrder] = useState("ASC");
  const [orderBy, setOrderBy] = useState("id");
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [name, setName] = useState("");
  const [id, setId] = useState(0);
  const [dataChanged, setDataChanged] = useState(false);
  const ownUser = getAuthUser();
  const [role, setRole] = useState("external");

  const manageDetailButton = (userRole) => {
    switch (role) {
      case "external":
        return false;
      case "internal":
        switch (userRole) {
          case "admin":
            return false;
          default:
            return true;
        }
      case "admin":
        return true;
      default:
        return false;
    }
  };

  const manageEditButton = (userId, userRole) => {
    if (ownUser.id === userId) {
      return true
    } else {
      switch (role) {
        case "external":
          return false;
        case "internal":
          switch (userRole) {
            case "admin":
              return false;
            default:
              return true;
          }
        case "admin":
          return true;
        default:
          return false;
      }
    }
  };

  const manageDeleteButton = (userRole) => {
    switch (role) {
      case "external":
        return false;
      case "internal":
        return false;
      case "admin":
        return true;
      default:
        return false;
    }
  };

  const navigateToEdit = (id) => {
    if(ownUser.id === id){
      history.push("/profile_edit")
    } else {
      history.push("/user_edit/" + id);
    }
  }

  useEffect(() => {
    if (ownUser) {
      setRole(ownUser.roles[0]);
    }
  }, [ownUser]);

  const navigateToDetail = (id) => {
    history.push("/user/" + id);
  };

  // Delete Dialog
  function openDeleteDialog(id, name) {
    setIsDeleteOpen(true);
    setName(name);
    setId(id);
  }

  const handleDeleteDialogClose = () => {
    setIsDeleteOpen(false);
  };

  const handleDeleteDialogCloseOnSubmit = () => {
    setIsDeleteOpen(false);
    if (totalEntries % rowsPerPage === 1) {
      setPage(page - 1);
    } else {
      setDataChanged(!dataChanged);
    }
  };

  // Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  // Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const search = async (str) => {
    setSearchStr(str);
    setPage(0);
  };

  const sorting = (order, orderBy) => {
    setOrder(order.toUpperCase());
    setOrderBy(orderBy);
    setPage(0);
  };

  const alert = (result) => {
    setSuccessMessage(result);
    setSnackbarOpen(true);
  };

  // Data fetching
  useEffect(() => {
    const fetchInitialUsers = async (page, rowsPerPage) => {
      setIsLoading(true);
      await http
        .get(`/users/${page + 1}/${rowsPerPage}`, {
          params: { search: searchStr, sort: orderBy, sort_dir: order },
        })
        .then(function (response) {
          setTotalEntries(response.data.users.total);
          setUsers(response.data.users.data);
          //console.log(response.data.users.data);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    fetchInitialUsers(page, rowsPerPage);
  }, [page, rowsPerPage, props, searchStr, order, orderBy]);

  return (
    <div>
      <section className={classes.darkerSection}>
        <div className="wrapper">
          <TopContent page="Benutzer" />
          <div className="buttonSection">
            {role === "external" ? null : (
              <StyledButton
                endIcon={<AddSharpIcon />}
                classes={{ root: classes.buttonMargin }}
                onClick={() => history.push("/user_new")}
              >
                neu anlegen
              </StyledButton>
            )}
          </div>

          <Toolbar className={classes.tableToolbar}>
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Benutzer
            </Typography>
            <SearchBar callBack={search} />
          </Toolbar>
          <TableContainer>
            <Table className={classes.table} aria-label="Benutzer Tabelle">
              <FilterableTableHead headCells={headCells} sortFunc={sorting} />
              <TableBody>
                {users.map((user) => (
                  <StyledTableRow
                    key={user.id}
                    classes={{ root: classes.hoverTableRow }}
                  >
                    <NormalTableCell component="th" scope="row" onClick={() => navigateToDetail(user.id)}>
                      {user.id}
                    </NormalTableCell>
                    <NormalTableCell onClick={() => navigateToDetail(user.id)}>
                      {user.name}, {user.academic_degree} {user.first_name}
                    </NormalTableCell>
                    <NormalTableCell onClick={() => navigateToDetail(user.id)}>{user.city}</NormalTableCell>
                    <NormalTableCell onClick={() => navigateToDetail(user.id)}>{user.role_label}</NormalTableCell>
                    <FunctionsTableCell align="right">
                      {manageDetailButton(user.role) && (
                        <StyledTooltip
                          title="ansehen"
                          arrow={true}
                          enterDelay={600}
                          enterNextDelay={600}
                        >
                          <TableIconButton
                            aria-label="delete"
                            size="small"
                            onClick={() => navigateToDetail(user.id)}
                          >
                            <VisibilityOutlinedIcon style={{ fontSize: 18 }} />
                          </TableIconButton>
                        </StyledTooltip>
                      )}
                      {manageEditButton(user.id, user.role) && (
                        <StyledTooltip
                          title="bearbeiten"
                          arrow={true}
                          enterDelay={600}
                          enterNextDelay={600}
                        >
                          <TableIconButton
                            aria-label="delete"
                            size="small"
                            onClick={() => navigateToEdit(user.id)}
                          >
                            <EditSharpIcon style={{ fontSize: 18 }} />
                          </TableIconButton>
                        </StyledTooltip>
                      )}
                      {manageDeleteButton(user.role) && (
                        <StyledTooltip
                          title="löschen"
                          arrow={true}
                          enterDelay={600}
                          enterNextDelay={600}
                        >
                          <TableIconButton
                            aria-label="delete"
                            size="small"
                            onClick={() =>
                              openDeleteDialog(
                                user.id,
                                (user.academic_degree
                                  ? user.academic_degree + " "
                                  : "") +
                                (user.first_name
                                  ? user.first_name + " "
                                  : "") +
                                user.name
                              )
                            }
                          >
                            <DeleteForeverSharp style={{ fontSize: 18 }} />
                          </TableIconButton>
                        </StyledTooltip>
                      )}
                    </FunctionsTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
              <TableFooter>
                <StyledTableRow>
                  <TablePagination
                    className={classes.pagination}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    colSpan={5}
                    count={totalEntries}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "Einträge pro Seite" },
                      classes: { icon: classes.selectIcon },
                      MenuProps: {disableScrollLock: true},
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelRowsPerPage={"Einträge pro Seite"}
                  />
                </StyledTableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </section>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DeleteDialog
        callback={alert}
        isOpen={isDeleteOpen}
        handleClose={handleDeleteDialogClose}
        handleCloseOnSubmit={handleDeleteDialogCloseOnSubmit}
        name={name}
        id={id}
        type="user"
        message=" wird dauerhaft gelöscht und ist kein Teilnehmer mehr in den jeweiligen Veranstaltungen."
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MuiAlert
          elevation={0}
          variant="filled"
          severity={successMessage[0]}
          onClose={handleSnackbarClose}
        >
          {successMessage[1]}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Users;
