import { withStyles, } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';

import { SECONDARY_LIGHT, WHITE } from '../../styles/colors';

const StyledTab = withStyles({
    root: {
        backgroundColor: SECONDARY_LIGHT,
        color: WHITE,
        width: "200px",
    },
    selected: {
        color: WHITE,
    }
})(Tab);

export default StyledTab;