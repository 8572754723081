// externe Imports
import React, { useEffect, useState } from "react";
import { StyledButton, TopContent } from "../../components";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from "@material-ui/core/Typography";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

// Interne Imports
import { CreateBackupDialog, FilterableTableHead, StyledTableRow, NormalTableCell, TablePaginationActions } from "../../components";
import { WHITE_TRANSPARENT } from "../../styles/colors";
import http from "../../utils/http-common";

// Styles
const useStyles = makeStyles((theme) => ({
    buttonMargin: {
        marginRight: "8px",
    },
    title: {
        flex: "1 1 100%",
        textTransform: "uppercase",
        letterSpacing: "1px",
    },
    tableToolbar: {
        padding: "0",
        minHeight: "40px",
        marginTop: "50px",
        marginBottom: "10px",
    },
    pagination: {
        color: WHITE_TRANSPARENT,
        border: 0,
        backgroundColor: "#1a617f",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    selectIcon: {
        color: WHITE_TRANSPARENT,
    },
}));

const headCells = [
    { id: "backups.name", label: "Datei", filterable: false },
    { id: "backups.created_at", label: "Datum / Zeit", filterable: true },
    { id: "backups.file_size", label: "Datei-Größe", filterable: false },
    { id: "backups.downloads", label: "Downloads", filterable: false },
    { id: "backups.images", label: "Bilder", filterable: false },
    { id: "backups.version", label: "Version", filterable: false },
];

// Backup-Page Component
const Backup = (props) => {
    // Variablen
    const classes = useStyles();
    const [isCreateBackupDialogOpen, setIsCreateBackupDialogOpen] = useState(false);
    const [dataChanged, setDataChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalEntries, setTotalEntries] = useState(0);
    const [backups, setBackups] = useState([]);
    const [order, setOrder] = useState("ASC");
    const [orderBy, setOrderBy] = useState("id");

    // CreateBackupDialog
    const openCreateBackupDialog = () => {
        setIsCreateBackupDialogOpen(true);
    }

    const handleCreateBackupDialogClose = () => {
        setIsCreateBackupDialogOpen(false);
    }

    // Sortieren
    const sorting = (order, orderBy) => {
        setOrder(order.toUpperCase());
        setOrderBy(orderBy);
        setPage(0);
    };

    const handleCreateBackupDialogCloseOnSubmit = () => {
        // TODO: Backup erstellen
        setIsCreateBackupDialogOpen(false);
        // Force Component to Reload
        setDataChanged(!dataChanged);
    }

    // Pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    // Data Fetching
    useEffect(() => {
        const fetchBackups = async () => {
            setIsLoading(true);
            await http
                .get(`/backups/${page + 1}/${rowsPerPage}`)
                .then(function (response) {
                    setBackups(response.data.data.data);
                    setTotalEntries(response.data.data.total);
                })
                .catch(function (error) {
                    console.log(error)
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
        fetchBackups();
    }, [page, rowsPerPage, order, orderBy])

    // gerendertes HTML
    return (
        <div>
            <section>
                <div className="wrapper">
                    <TopContent page="Backup" />
                    <div className="buttonSection">
                        <StyledButton
                            startIcon={<AddSharpIcon />}
                            classes={{ root: classes.buttonMargin }}
                            onClick={() => {
                                openCreateBackupDialog()
                            }}>neu anlegen</StyledButton>
                    </div>
                    <Toolbar className={classes.tableToolbar}>
                        <Typography
                            className={classes.title}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                        >
                            Backup
                        </Typography>
                    </Toolbar>
                    <TableContainer>
                        <Table className={classes.table} aria-label="Backup Tabelle">
                            <FilterableTableHead headCells={headCells} sortFunc={sorting} />
                            <TableBody>
                                {backups.map((backup) => (
                                    <StyledTableRow>
                                        <NormalTableCell component="th" scope="row">{backup.name}</NormalTableCell>
                                        <NormalTableCell>{backup.created_at}</NormalTableCell>
                                        <NormalTableCell>{backup.file_size}</NormalTableCell>
                                        <NormalTableCell>{backup.downloads}</NormalTableCell>
                                        <NormalTableCell>{backup.images}</NormalTableCell>
                                        <NormalTableCell>{backup.version}</NormalTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <StyledTableRow>
                                    <TablePagination
                                        className={classes.pagination}
                                        rowsPerPageOptions={[5, 10, 25, 50]}
                                        colSpan={7}
                                        count={totalEntries}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'Einträge pro Seite' },
                                            classes: { icon: classes.selectIcon },
                                            MenuProps: {disableScrollLock: true},
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                        labelRowsPerPage={"Einträge pro Seite"}
                                    />
                                </StyledTableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
            </section>
            <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <CreateBackupDialog
                isOpen={isCreateBackupDialogOpen}
                handleClose={handleCreateBackupDialogClose}
                handleCloseOnSubmit={handleCreateBackupDialogCloseOnSubmit}
            />
        </div>
    );
}

export default Backup;