import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';

import { TopContent, CreateForm, StyledButton } from "../../components";
import { SECONDARY } from "../../styles/colors";
import http from "../../utils/http-common";
import Alert from "../../components/Alert/Alert";


const useStyles = makeStyles((theme) => ({
    darkerSection: {
        backgroundColor: SECONDARY,
    }
}));

const DoctorEdit = () => {
    const [doctor, setDoctor] = useState({});
    const [isSuccessfull, setSuccess] = useState(false);
    const [isFailed, setFail] = useState(false);
    const history = useHistory();
    let { id } = useParams();
    const classes = useStyles();
    let companies = { 0: null };

    if (doctor.companies !== undefined) {
        if (doctor.companies.length > 0) {
            companies = { 0: { id: doctor.companies[0].id, company_name: doctor.companies[0].company_name } };
        }
    }

    const form = [
        {
            id: "salutation",
            label: "Anrede",
            type: "select",
            value: doctor.salutation ?? "",
            options: [{ value: "mrs", text: "Frau" }, { value: "mr", text: "Herr" }],
            colLg: 6,
            colMd: 6,
            colSm: 6,
            required: false,
        },
        {
            id: "academic_degree",
            label: "Titel",
            type: "text",
            value: doctor.academic_degree ?? "",
            max: 31,
            colLg: 6,
            colMd: 6,
            colSm: 6,
            required: true,
        },
        {
            id: "first_name",
            label: "Vorname",
            type: "text",
            value: doctor.first_name ?? "",
            required: true,
            max: 255,
            colLg: 6,
            colMd: 6,
            colSm: 6
        },
        {
            id: "last_name",
            label: "Nachname",
            type: "text",
            value: doctor.last_name ?? "",
            required: true,
            max: 255,
            colLg: 6,
            colMd: 6,
            colSm: 6
        },
        {
            id: "email",
            label: "Email",
            type: "email",
            value: doctor.email ?? "",
            format: "email",
            required: true,
            max: 255,
            colLg: 6,
            colMd: 6,
            colSm: 6
        },
        {
            id: "company",
            label: "Klinik / Praxis",
            type: "autofill",
            value: companies,
            required: true,
        },
    ];

    const dataFromProps = ["id"];
    const dataFromValues = [
        { name: "salutation", type: "string" },
        { name: "academic_degree", type: "string" },
        { name: "first_name", type: "string" },
        { name: "last_name", type: "string" },
        { name: "email", type: "string" },
        { name: "company", type: "object" }
    ];

    const goBack = (ready) => {
        if (ready) {
            history.goBack();
        }
    }

    const alert = (result) => {
        if (result === "success") {
            setSuccess(true);
        } else {
            setFail(true)
        }
    };


    useEffect(() => {
        const fetchDoctor = async () => {
            await http
                .get("/physician/" + id)
                .then(function (response) {
                    if (response.data.result === "Token is Expired") {
                        console.log("expired")
                    } else {
                        setDoctor(response.data.physician);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        };
        fetchDoctor();
    }, [id])


    return (
        <section className={classes.darkerSection}>
            <div className="wrapper">
                <TopContent page="Arzt bearbeiten" />

                <div className="buttonSection">
                    <StyledButton startIcon={<ArrowBackIosSharpIcon />} onClick={() => { history.goBack(); }}>zurück</StyledButton>
                </div>

                <CreateForm
                    form={form}
                    data={{ dataFromProps: dataFromProps, dataFromValues: dataFromValues }}
                    id={id}
                    url="physician/update"
                    method="PUT"
                    result="physician"
                    callBack={alert}
                />
                {isSuccessfull && <Alert type="success" message="Daten erfolgreich aktualisiert!" callBack={goBack} />}
                {isFailed && <Alert type="error" message="Fehler beim Aktualisieren der Daten!" callBack={goBack} />}
            </div>
        </section>
    );
}

export default DoctorEdit;