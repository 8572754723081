import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import DeleteForeverSharpIcon from '@material-ui/icons/DeleteForeverSharp';
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FilterListSharpIcon from '@material-ui/icons/FilterListSharp';


import http from "../../utils/http-common";
import { SECONDARY, WHITE_TRANSPARENT } from "../../styles/colors";
import {
  StyledTableRow,
  TableIconButton,
  NormalTableCell,
  FunctionsTableCell,
  SearchBar,
  FilterableTableHead,
  StyledButton,
  TopContent,
  StyledTooltip,
  ProgressBar,
  DatePicker,
  StyledSelect,
  CheckboxFilter,
  DeleteEventDialog,
  TablePaginationActions,
  FilterDialog
} from "../../components";
import { getAuthUser } from "../../utils/authUser";


const headCells = [
  { id: "events.id", label: "ID", width: "4%", filterable: true },
  { id: "events.date_from", label: "Datum", width: "9%", filterable: true },
  { id: "events.city", label: "Ort", width: "12%", filterable: true },
  { id: "events.event_name", label: "Bezeichnung", width: "35%", filterable: true },
  { id: "events.capacity", label: "Auslastung", width: "18%", filterable: false },
];


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 1050,
  },
  title: {
    flex: "1 1 100%",
    textTransform: "uppercase",
    letterSpacing: "1px",
  },
  tableToolbar: {
    padding: "0",
    minHeight: "40px",
    marginTop: "50px",
    marginBottom: "10px",
  },
  darkerSection: {
    backgroundColor: SECONDARY,
  },
  pagination: {
    color: WHITE_TRANSPARENT,
    border: 0,
    backgroundColor: "#1a617f",
  },
  selectIcon: {
    color: WHITE_TRANSPARENT,
  },
  buttonMargin: {
    marginRight: "8px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  hoverTableRow: {
    '&:hover': {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      cursor: "pointer",
    }
  },
}));

const Events = (props) => {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [events, setEvents] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalEntries, setTotalEntries] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [name, setName] = useState("");
  const [id, setId] = useState(0);
  const history = useHistory();
  const [successMessage, setSuccessMessage] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [pastEventFilter, setPastEventFilter] = useState(0);
  const [order, setOrder] = useState("ASC");
  const [orderBy, setOrderBy] = useState("events.date_from");
  const matches = useMediaQuery('(max-width:1016px)');
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const user = getAuthUser();
  const [role, setRole] = useState("external");

  useEffect(() => {
    if (user) {
      setRole(user.roles[0]);
    }
  }, [user])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const openFilterDialog = () => {
    setFilterDialogOpen(true);
  }

  const closeFilterDialog = () => {
    setFilterDialogOpen(false)
  }

  const closeFilterDialogOnSubmit = (search, category, date, pastEvent) => {
    setSearchStr(search);
    setCategoryFilter(category);
    setDateFilter(date);
    setPastEventFilter(pastEvent);
    setFilterDialogOpen(false);
  }

  function openDeleteDialog(id, name) {
    setIsDeleteOpen(true);
    setName(name);
    setId(id);
  }

  const handleDeleteDialogClose = () => {
    setIsDeleteOpen(false);
  };

  const handleDeleteDialogCloseOnSubmit = () => {
    setIsDeleteOpen(false);
    setDataChanged(!dataChanged);
  };

  const alert = (result) => {
    setSuccessMessage(result);
    setSnackbarOpen(true);
  };

  const search = (str) => {
    setSearchStr(str);
    setPage(0);
  };

  const sorting = (order, orderBy) => {
    setOrder(order.toUpperCase());
    setOrderBy(orderBy);
    setPage(0);
  };

  const showPastEvents = (value) => {
    if (value === true) {
      setPastEventFilter(1);
    } else {
      setPastEventFilter(0);
    }
  };

  const sortByCategory = (selectedCategory) => {
    setCategoryFilter(selectedCategory);
  };

  const sortByDate = (selectedDate) => {
    if (selectedDate === null) {
      setDateFilter("")
    } else {
      setDateFilter(format(selectedDate, 'yyyy-MM-dd'))
    }
  };

  const formatDate = (dateStr) => {
    //let date = new Date(dateStr + "Z");
    let date = new Date(dateStr);
    return (date.toLocaleDateString("de-DE"));
  };




  useEffect(() => {
    const fetchCategories = async () => {
      await http
        .get("/categories")
        .then(function (response) {
          setCategories(response.data.categories);
        })
        .catch(function (error) {
          console.log(error);

        });
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      setIsLoading(true);
      await http
        .get(`/events/${page + 1}/${rowsPerPage}`, {
          params: { search: searchStr, sort: orderBy, sort_dir: order, category_filter: categoryFilter, count_physicians_state: "accepted", past_event: pastEventFilter, month_filter: dateFilter }
        })
        .then(function (response) {
          setEvents(response.data.events.data);
          //console.log(response.data.events.data)
          setTotalEntries(response.data.events.total);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchEvents();
  }, [page, rowsPerPage, dataChanged, searchStr, categoryFilter, pastEventFilter, dateFilter, order, orderBy]);

  return (
    <div>
      <section className={classes.darkerSection}>
        <div className="wrapper">
          <TopContent page="Veranstaltungen" />
          <div className="buttonSection">
            {role === "external" ?
              null :
              <StyledButton
                endIcon={<AddSharpIcon />}
                classes={{ root: classes.buttonMargin }}
                onClick={() => history.push("/event_new")}
              >
                neu anlegen
            </StyledButton>
            }
          </div>
          {matches === false ?
            <Toolbar className={classes.tableToolbar}>
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Veranstaltungen
            </Typography>

              <DatePicker callBack={sortByDate} currentValue={dateFilter} />
              <StyledSelect
                default="Alle Kategorien"
                array={categories}
                callBack={sortByCategory}
                currentValue={categoryFilter}
                MenuProps={{
                  disableScrollLock: true,
                }}
              />
              <CheckboxFilter
                name="pastEvents"
                label="Vergangene anzeigen"
                callBack={showPastEvents}
                currentValue={pastEventFilter === 0 ? false : true}
              />
              <SearchBar callBack={search} currentValue={searchStr} />
            </Toolbar> :
            <Toolbar className={classes.tableToolbar}>
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Veranstaltungen
          </Typography>
              <StyledTooltip
                title="filtern"
                arrow={true}
                enterDelay={600}
                enterNextDelay={600}
              >
                <TableIconButton aria-label="ansehen" size="small" onClick={() => openFilterDialog()}>
                  <FilterListSharpIcon style={{ fontSize: 18 }} />
                </TableIconButton>
              </StyledTooltip>
            </Toolbar>}
          <TableContainer>
            <Table className={classes.table} aria-label="customized table">
              <FilterableTableHead headCells={headCells} sortFunc={sorting} />
              <TableBody>
                {events.map((event) => (
                  <StyledTableRow key={event.id} classes={{ root: classes.hoverTableRow }}>
                    <NormalTableCell component="th" scope="row" onClick={() => { history.push("/event/" + event.id) }}>
                      {event.id}
                    </NormalTableCell>
                    <NormalTableCell onClick={() => { history.push("/event/" + event.id) }}>{formatDate(event.date_from)}</NormalTableCell>
                    <NormalTableCell onClick={() => { history.push("/event/" + event.id) }}>{event.city}</NormalTableCell>
                    <NormalTableCell onClick={() => { history.push("/event/" + event.id) }}>{event.event_name}</NormalTableCell>
                    <NormalTableCell onClick={() => { history.push("/event/" + event.id) }}>
                      <ProgressBar participant_limit={event.participant_limit ? event.participant_limit : 0} participant_number={event.counted_physicians ? event.counted_physicians : 0} />
                    </NormalTableCell>
                    <FunctionsTableCell align="right">
                      <StyledTooltip
                        title="Ansehen"
                        arrow={true}
                        enterDelay={600}
                        enterNextDelay={600}
                      >
                        <TableIconButton aria-label="ansehen" size="small" onClick={() => { history.push("/event/" + event.id) }}>
                          <VisibilityOutlinedIcon style={{ fontSize: 18 }} />
                        </TableIconButton>
                      </StyledTooltip>
                      {role === "external" ? null :
                      <StyledTooltip
                        title="bearbeiten"
                        arrow={true}
                        enterDelay={600}
                        enterNextDelay={600}
                      >
                        <TableIconButton aria-label="bearbeiten" size="small" onClick={() => { history.push("/event_edit/" + event.id) }}>
                          <EditSharpIcon style={{ fontSize: 18 }} />
                        </TableIconButton>
                      </StyledTooltip>}
                      {role === "external" ? null :
                        <StyledTooltip
                          title="löschen"
                          arrow={true}
                          enterDelay={600}
                          enterNextDelay={600}
                        >
                          <TableIconButton aria-label="delete" size="small" onClick={() =>
                            openDeleteDialog(event.id, event.event_name)
                          }>
                            <DeleteForeverSharpIcon style={{ fontSize: 18 }} />
                          </TableIconButton>
                        </StyledTooltip>
                        }
                    </FunctionsTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
              <TableFooter>
                <StyledTableRow>
                  <TablePagination
                    className={classes.pagination}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    colSpan={headCells.length + 1}
                    count={totalEntries}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "Einträge pro Seite" },
                      classes: { icon: classes.selectIcon },
                      MenuProps: {disableScrollLock: true},
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </StyledTableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </section>
      <DeleteEventDialog
        callback={alert}
        isOpen={isDeleteOpen}
        handleClose={handleDeleteDialogClose}
        handleCloseOnSubmit={handleDeleteDialogCloseOnSubmit}
        name={name}
        id={parseInt(id)}
        type="event"
        invitedCount={5}
      />
      <FilterDialog
        isOpen={filterDialogOpen}
        handleClose={closeFilterDialog}
        handleCloseOnSubmit={closeFilterDialogOnSubmit}
        searchVal={searchStr}
        dateVal={dateFilter}
        categoryVal={categoryFilter}
        pastEventVal={pastEventFilter === 0 ? false : true}
        categories={categories}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MuiAlert
          elevation={0}
          variant="filled"
          severity={successMessage[0]}
          onClose={handleSnackbarClose}
        >
          {successMessage[1]}
        </MuiAlert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Events;
