import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    container: {
        maxWidth: "1180px",
        margin: "150px auto",
        boxSizing: "conten-box",
        padding: "0 1rem",
        textAlign: "center",
    }
}));

const Error404 = (props) => {
    const classes = useStyles();
    return (
        <section>
            <div className={classes.container}>
                <h1>Fehler 404 - Seite nicht gefunden</h1> 
                <p>Das schwarze Loch des Internets... Die angeforderte Seite konnte leider nicht gefunden werden!</p>
            </div>
        </section>
    );
}

export default Error404;