import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Container, Col, Row } from "react-bootstrap";
import SaveSharpIcon from '@material-ui/icons/SaveSharp';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";

import http from "../../../utils/http-common";
import "../deleteDialog/DeleteDialog.css";
import { StyledButton } from "../..";
import { changePasswordForm } from "../../../pages/users/userData";
import { SECONDARY, WHITE, SECONDARY_LIGHT } from "../../../styles/colors";

const useStyles = makeStyles(() => ({
    paper: {
        backgroundColor: SECONDARY,
        color: WHITE,
        borderRadius: 0,
    },
    content: {
        color: WHITE,
    },
    field: {
        width: "100%",
        backgroundColor: SECONDARY_LIGHT + "!important",
        '&:-webkit-autofill': {
            webkitBoxSha: "0 0 0 30px white inset !important",
        },
    },
    label: {
        textTransform: "none",
        marginTop: "8px",
        fontSize: "13px",
    },
    required: {
        fontSize: "13px",
        marginTop: "16px",
        color: "#fffc !important",
    },
    error: {
        color: "#F26C51"
    },
}));

const changePasswordValues = {
    id: "",
    password: "",
    password_confirmation: "",
};

export const ChangePasswordDialog = ({ isOpen, handleClose, handleCloseOnSubmit, callback, id }) => {
    const classes = useStyles();
    const [isError, setError] = useState({});
    const [values, setValues] = useState(changePasswordValues);

    useEffect(() => {
       setValues(values => values = { ...values, "id": id } )
    }, [id])

    const closeDialog = () => {
        setValues({ ...values, "password": "", "password_confirmation": ""  });
        setError({})
        handleClose();
    }

    const handleOnBlur = (fieldId) => (event) => {
        setError({ ...isError, [fieldId]: undefined });
        changePasswordForm.forEach((field) => {
            if (fieldId === field.id && !document.getElementById(fieldId).validity.valid) {
                if (field.required === true) {
                    setError({ ...isError, [fieldId]: "Füllen Sie bitte dieses Feld aus." });
                }
            }
            if (fieldId === field.id && event.target.value.length < field.min) {
                setError({ ...isError, [fieldId]: "Die minimale Länge dieses Feldes entspricht " + field.min + " Zeichen." });
            }
        })
    }

    const handleChange = (fieldId) => (event) => {
        setValues({ ...values, [fieldId]: event.target.value });
        setError({ ...isError, [fieldId]: undefined });
        changePasswordForm.forEach((field) => {
            if (fieldId === field.id && event.target.value.length > field.max) {
                setError({ ...isError, [fieldId]: "Die maximale Länge dieses Feldes entspricht " + field.max + " Zeichen." });
            }
        })
    }

    const handleConfirm = async (event) => {
        event.preventDefault();

        if (values.password !== values.password_confirmation) {
            setError({ ...isError, "password_confirmation": "Die Passwörter müssen gleich sein." });
        }

        let errorList = {}
        for (const [key, value] of Object.entries(isError)) {
            if (value !== undefined) {
                errorList[key] = value
            }
        }

        if (values.password === values.password_confirmation) {
            if (Object.keys(errorList).length === 0) {
                http
                    .put("/user/changePassword", values)
                    .then(result => {
                        if (result.status === 200 || result.status === 201) {
                            callback(["success", "Passwort geändert."]);
                        } else {
                            console.log("Status: " + result.status + ", " + result.statusText);
                            callback(["error", "Fehler beim Ändern des Passwortes."]);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        callback(["error", "Fehler beim Ändern des Passwortes."]);
                    }).finally(function () {
                        setValues(changePasswordValues);
                        setError({})
                        handleCloseOnSubmit();
                    });
            }
        }

    }

    const formFields = changePasswordForm.map((field) => {
        if (field.type === "text") {
            return (
                <Col key={field.id} lg={field.colLg} md={field.colMd} sm={field.colSm}>
                    <div className={classes.label}>{field.label}{field.required === true ? "*" : ""}</div>
                    <OutlinedInput
                        id={field.id}
                        type={field.inputType}
                        required={field.required}
                        margin="dense"
                        size="small"
                        className={classes.field}
                        classes={{ input: classes.field }}
                        onChange={handleChange(field.id)}
                        onBlur={handleOnBlur(field.id)}
                        autoComplete="off"
                        value={values[field.id] || ""}
                    />
                    {isError[field.id] ? <FormHelperText id={"error-text-" + field.id} className={classes.error}>{isError[field.id]}</FormHelperText> : ""}
                </Col>
            )
        } else {
            return "Input-Type not defined"
        }
    });

    return (
        <Dialog
            open={isOpen}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: classes.paper }}
            disableScrollLock={true}
        >
            <DialogTitle id="alert-dialog-title">Passwort ändern</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" classes={{ root: classes.content }}>
                    Um Ihr Passwort zu ändern, füllen Sie bitte die nachfolgenden Felder aus.
                </DialogContentText>
                <form>
                    <Container style={{ padding: 0 }}>
                        <Row>
                            {formFields}
                        </Row>
                    </Container>
                </form>
                <div className={classes.required}>* Pflichtfeld</div>
            </DialogContent>
            <DialogActions classes={{ root: classes.actions }}>
                <Button
                    onClick={closeDialog}
                    color="secondary"
                    className="secondaryBtn"
                >Abbrechen</Button>
                <StyledButton
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    endIcon={<SaveSharpIcon />}
                    onClick={handleConfirm}
                    autoFocus
                    type="submit"
                >speichern</StyledButton>
            </DialogActions>
        </Dialog>
    );
}

ChangePasswordDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleCloseOnSubmit: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
};

export default ChangePasswordDialog;