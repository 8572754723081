import TableCell from '@material-ui/core/TableCell';
import { withStyles, } from '@material-ui/core/styles';

import { WHITE, WHITE_TRANSPARENT, SECONDARY_LIGHT } from '../../../styles/colors';

const NormalTableCell = withStyles(() => ({
    head: {
        backgroundColor: SECONDARY_LIGHT,
        color: WHITE_TRANSPARENT,
        lineHeight: "normal",
        fontWeight: "400",
    },
    body: {
        fontSize: 14,
        color: WHITE,
        padding: "10px 24px 10px 16px",
    },
    root: {
        borderBottom: "none",
    }
}))(TableCell);

export default NormalTableCell