import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { DESIGN_GREEN, DESIGN_GREEN_LIGHT, WHITE } from "../../styles/colors";

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 24,
    borderRadius: 0,
  },
  colorPrimary: {
    backgroundColor: DESIGN_GREEN_LIGHT,
  },
  bar: {
    borderRadius: 0,
    backgroundColor: DESIGN_GREEN,
  },
}))(LinearProgress);

const useStyles = makeStyles({
  label: {
    color: WHITE,
  },
});

const ProgressBar = (props) => {
  const classes = useStyles();
  let value = (props.participant_number / props.participant_limit) * 100;
  const matches = useMediaQuery("(max-width:850px)");

  if (matches === false) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%">
          <BorderLinearProgress
            variant="determinate"
            value={value || 0}
            aria-label="Auslastung der Veranstaltung"
          />
        </Box>
        <Box minWidth={35} style={{ position: "absolute", paddingLeft: "5px" }}>
          <Typography variant="body2" className={classes.label}>
            {props.participant_number}/{props.participant_limit}
          </Typography>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box display="flex" alignItems="center">
        <Box minWidth={35}>
          <Typography variant="body2" className={classes.label}>
            {props.participant_number}/{props.participant_limit}
          </Typography>
        </Box>
      </Box>
    );
  }
};

ProgressBar.propTypes = {
  participant_limit: PropTypes.number.isRequired,
  participant_number: PropTypes.number.isRequired,
};

export default ProgressBar;
