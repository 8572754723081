import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import FirstPageSharpIcon from '@material-ui/icons/FirstPageSharp';
import KeyboardArrowLeftSharpIcon from '@material-ui/icons/KeyboardArrowLeftSharp';
import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import LastPageSharpIcon from '@material-ui/icons/LastPageSharp';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { WHITE_TRANSPARENT } from '../../styles/colors';

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

const StyledPaginationIcon = withStyles(() => ({
    root: {
        color: WHITE_TRANSPARENT,
    }, 
    disabled: {
        color: "#ffffff3b!important",
    }

}))(IconButton);

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <StyledPaginationIcon
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="erste Seite"
            >
                {theme.direction === 'rtl' ? <LastPageSharpIcon /> : <FirstPageSharpIcon />}
            </StyledPaginationIcon>
            <StyledPaginationIcon onClick={handleBackButtonClick} disabled={page === 0} aria-label="vorherige Seite">
                {theme.direction === 'rtl' ? <KeyboardArrowRightSharpIcon /> : <KeyboardArrowLeftSharpIcon />}
            </StyledPaginationIcon>
            <StyledPaginationIcon
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="nächste Seite"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeftSharpIcon /> : <KeyboardArrowRightSharpIcon />}
            </StyledPaginationIcon>
            <StyledPaginationIcon
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="letzte Seite"
            >
                {theme.direction === 'rtl' ? <FirstPageSharpIcon /> : <LastPageSharpIcon />}
            </StyledPaginationIcon>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export default TablePaginationActions;
