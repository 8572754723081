import React from "react";
import { Navbar, NavbarBrand } from "react-bootstrap";
import "./Header.css";
import Logo from "../../img/abbott-logo.png";

const HeaderLogin = () => {
  return (
    <Navbar fixed="top" variant="dark" className="text-white nav">
      <div className="nav-container navbar-top" />
      <div className="nav-container navbar-bottom">
        <NavbarBrand href="/" aria-label="Link zu Abbott">
          <img
            alt="Abbott Logo"
            src={Logo}
            width="103"
            height="26"
            className="d-inline-block"
          />{" "}
        </NavbarBrand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end header-login-text">
          VERANSTALTUNGS-KALENDER // ADMINTOOL
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default HeaderLogin;
