export const categoryForm = [
    {
        id: "category_name",
        label: "Name",
        type: "text",
        inputType: "text",
        value: "",
        required: true,
        max: 255,
        colLg: 12,
        colMd: 12,
        colSm: 12,
    },
    {
        id: "description",
        label: "Beschreibung",
        type: "textarea",
        value: "",
        required: true,
        colLg: 12,
        colMd: 12,
        colSm: 12,
    },
    {
        id: "color",
        label: "Farbe",
        type: "color",
        value: "",
        required: false,
        colLg: 12,
        colMd: 12,
        colSm: 12,
    }, 
]

export const topicForm = [
    {
        id: "topic_name",
        label: "Name",
        type: "text",
        inputType: "text",
        value: "",
        required: true,
        max: 255,
        colLg: 12,
        colMd: 12,
        colSm: 12,
    },
]

export const organizerForm = [
    {
        id: "organizer_name",
        label: "Name",
        type: "text",
        inputType: "text",
        value: "",
        required: true,
        max: 255,
        colLg: 12,
        colMd: 12,
        colSm: 12,
    },
    {
        id: "organizer_street",
        label: "Straße",
        type: "text",
        inputType: "text",
        value: "",
        required: false,
        max: 255,
        colLg: 12,
        colMd: 12,
        colSm: 12,
    },
    {
        id: "organizer_post_code",
        label: "PLZ",
        type: "text",
        inputType: "number",
        value: "",
        required: false,
        max: 255,
        colLg: 4,
        colMd: 4,
        colSm: 4,
    },
    {
        id: "organizer_city",
        label: "Stadt",
        type: "text",
        inputType: "text",
        value: "",
        required: false,
        max: 255,
        colLg: 8,
        colMd: 8,
        colSm: 8,
    },
    {
        id: "organizer_web",
        label: "Webseite",
        type: "text",
        inputType: "url",
        value: "",
        required: false,
        max: 255,
        colLg: 12,
        colMd: 12,
        colSm: 12,
    },
    {
        id: "contact_person_last_name",
        label: "Nachname (Kontaktperson)",
        type: "text",
        inputType: "text",
        value: "",
        required: false,
        max: 127,
        colLg: 6,
        colMd: 6,
        colSm: 6,
    },
    {
        id: "contact_person_first_name",
        label: "Vorname (Kontaktperson)",
        type: "text",
        inputType: "text",
        value: "",
        required: false,
        max: 127,
        colLg: 6,
        colMd: 6,
        colSm: 6,
    },
    {
        id: "contact_person_phone",
        label: "Telefon (Kontaktperson)",
        type: "text",
        inputType: "tel",
        value: "",
        required: false,
        max: 255,
        colLg: 6,
        colMd: 6,
        colSm: 6,
    },
    {
        id: "contact_person_fax",
        label: "Fax (Kontaktperson)",
        type: "text",
        inputType: "tel",
        value: "",
        required: false,
        max: 255,
        colLg: 6,
        colMd: 6,
        colSm: 6,
    },
    {
        id: "contact_person_email",
        label: "E-Mail (Kontaktperson)",
        type: "text",
        inputType: "email",
        value: "",
        required: false,
        max: 255,
        colLg: 12,
        colMd: 12,
        colSm: 12,
    },
]

export const sponsorForm = [
    {
        id: "name",
        label: "Name",
        type: "text",
        inputType: "text",
        value: "",
        required: true,
        max: 255,
        colLg: 12,
        colMd: 12,
        colSm: 12,
    },
    {
        id: "website",
        label: "Webseite",
        type: "text",
        inputType: "text",
        value: "",
        required: true,
        max: 255,
        colLg: 12,
        colMd: 12,
        colSm: 12,
    },
    {
        id: "image",
        label: "Logo",
        type: "image",
        inputType: "image",
        value: "",
        required: true,
        colLg: 12,
        colMd: 12,
        colSm: 12,
    },
]

export const companyForm = [
    {
        id: "company_name",
        label: "Name",
        type: "text",
        inputType: "text",
        value: "",
        required: true,
        max: 255,
        colLg: 12,
        colMd: 12,
        colSm: 12,
    },
    {
        id: "website",
        label: "Webseite",
        type: "text",
        inputType: "text",
        value: "",
        required: true,
        max: 255,
        colLg: 12,
        colMd: 12,
        colSm: 12,
    },
]