import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import DeleteForeverSharpIcon from '@material-ui/icons/DeleteForeverSharp';
import TableFooter from "@material-ui/core/TableFooter";
/* import SystemUpdateAltSharpIcon from '@material-ui/icons/SystemUpdateAltSharp'; */
import { useHistory } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import FavoriteBorderSharpIcon from '@material-ui/icons/FavoriteBorderSharp';
import FavoriteSharpIcon from '@material-ui/icons/FavoriteSharp';
import IconButton from '@material-ui/core/IconButton';

import http from "../../utils/http-common";
import { useAuth } from "../../context/auth";
import { SECONDARY, WHITE, WHITE_TRANSPARENT } from "../../styles/colors";
import {
  StyledTableRow,
  TableIconButton,
  NormalTableCell,
  FunctionsTableCell,
  DeleteDialog,
  TablePaginationActions,
  SearchBar,
  FilterableTableHead,
  StyledButton,
  TopContent,
  StyledTooltip,
} from "../../components";
import { getAuthUser } from "../../utils/authUser";

const headCells = [
  { id: "fav", label: <FavoriteBorderSharpIcon aria-label="Favoriten"/>, filterable: true },
  { id: "physicians.id", label: "ID", filterable: true },
  { id: "physicians.last_name", label: "Name", filterable: true },
  { id: "companies.company_name", label: "Klinik / Praxis", filterable: true },
  { id: "physicians.email", label: "Email", filterable: false },
];


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 800,
  },
  title: {
    flex: "1 1 100%",
    textTransform: "uppercase",
    letterSpacing: "1px",
  },
  tableToolbar: {
    padding: "0",
    minHeight: "40px",
    marginTop: "50px",
    marginBottom: "10px",
  },
  darkerSection: {
    backgroundColor: SECONDARY,
  },
  pagination: {
    color: WHITE_TRANSPARENT,
    border: 0,
    backgroundColor: "#1a617f",
  },
  selectIcon: {
    color: WHITE_TRANSPARENT,
  },
  buttonMargin: {
    marginRight: "8px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  iconButtonRoot: {
    color: WHITE,
    padding: 0,
    fontSize: "18px",
  },
  hoverTableRow: {
    '&:hover': {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      cursor: "pointer",
    }
  },
}));

const Doctor = (props) => {
  const [doctors, setDoctors] = useState([]);
  const classes = useStyles();
  const { setAuthTokens } = useAuth();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalEntries, setTotalEntries] = useState(0);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [name, setName] = useState("");
  const [id, setId] = useState(0);
  const history = useHistory();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [order, setOrder] = useState("ASC");
  const [orderBy, setOrderBy] = useState("id");
  const [successMessage, setSuccessMessage] = useState([])
  const [favoritesList, setFavoritesList] = useState([])
  const [disableDeleteButton, setDisableDeleteButton] = useState(false);
  const user = getAuthUser();

  useEffect(() => {
    if (user) {
      setDisableDeleteButton(user.roles.includes("external"));
    }
  }, [user])


  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  function openDeleteDialog(id, name) {
    setIsDeleteOpen(true);
    setName(name);
    setId(id);
  }

  const navigateToDetail = (id) => {
    history.push("/doctor/" + id);
  };

  const navigateToEdit = (id) => {
    history.push("/doctor_edit/" + id);
  }

  const handleDeleteDialogClose = () => {
    setIsDeleteOpen(false);
  };

  const handleDeleteDialogCloseOnSubmit = () => {
    setIsDeleteOpen(false);
    if (totalEntries % rowsPerPage === 1) {
      setPage(page - 1)
    } else {
      setDataChanged(!dataChanged);
    }
  };

  const alert = (result) => {
    setSuccessMessage(result);
    setSnackbarOpen(true);
  };

  const sorting = (order, orderBy) => {
    setOrder(order.toUpperCase());
    setOrderBy(orderBy);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const search = async (str) => {
    setSearchStr(str);
    setPage(0);
  };

  const getName = (lastname, title, firstname) => {
    let name = lastname;
    if (title !== null || firstname !== "") {
      name += (", ");
      if (title !== null) {
        name += title;
        name += " ";
      }
      if (firstname !== "") {
        name += firstname;
      }
    }
    return name
  }

  const addFavorite = async (doctorId) => {
    let temp = favoritesList;
    await http
      .post('/user/attach/physician', { physician_id: doctorId })
      .then((response) => {
        temp.push(doctorId);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(function () {
        setFavoritesList([...temp]);
      })
  }

  const removeFavorite = async (doctorId) => {
    let temp = favoritesList;
    await http
      .post('/user/detach/physician', { physician_id: doctorId })
      .then((response) => {
        let index = temp.indexOf(doctorId)
        temp.splice(index, 1);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(function () {
        setFavoritesList([...temp]);
      })
  }


  useEffect(() => {
    let mounted = true;
    let initalFavoritesList = []
    const fetchDoctors = async () => {
      if (mounted) setIsLoading(true);
      await http
        .get(`/physicians/${page + 1}/${rowsPerPage}`, {
          params: { search: searchStr, sort: orderBy, sort_dir: order },
        })
        .then(function (response) {
          setTotalEntries(response.data.physicians.total);
          setDoctors(response.data.physicians.data);
          //console.log(response.data.physicians.data)
          response.data.physicians.data.forEach((physician) => {
            if (physician.fav === 1) {
              initalFavoritesList.push(physician.id);
            }
          });
          setFavoritesList(initalFavoritesList);
        })
        .catch(function (error) {
          console.log(error);
          if (error.response.data.status === "Token is Expired") {
            props.useLogout();
          }
        })
        .finally(() => {
          if (mounted) setIsLoading(false);
        });
    };

    fetchDoctors();

    return () => {
      mounted = false;
    }

  }, [searchStr, page, rowsPerPage, dataChanged, order, orderBy, setAuthTokens, props]);

  return (
    <div>
      <section className={classes.darkerSection}>
        <div className="wrapper">
          <TopContent page="Ärzte" />

          <div className="buttonSection">
            <StyledButton
              endIcon={<AddSharpIcon />}
              classes={{ root: classes.buttonMargin }}
              onClick={() => {
                history.push("/doctor_new")
              }}
            >
              neu anlegen
            </StyledButton>
            {/*  <StyledButton endIcon={<SystemUpdateAltSharpIcon />}>
              importieren
            </StyledButton> */}
          </div>

          <Toolbar className={classes.tableToolbar}>
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Ärzte
            </Typography>
            <SearchBar callBack={search} />
          </Toolbar>
          <TableContainer>
            <Table className={classes.table} aria-label="customized table">
              <FilterableTableHead headCells={headCells} sortFunc={sorting} />
              <TableBody>
                {doctors.map((doctor) => (
                  <StyledTableRow key={doctor.id} classes={{ root: classes.hoverTableRow }}>
                    <NormalTableCell component="th" scope="row">
                      <IconButton aria-label="Arzt favorisieren" component="span" classes={{ root: classes.iconButtonRoot }} onClick={
                        !favoritesList.includes(doctor.id)
                          ? () => addFavorite(doctor.id)
                          : () => removeFavorite(doctor.id)
                      }>
                        {favoritesList.includes(doctor.id) ? <FavoriteSharpIcon /> : <FavoriteBorderSharpIcon />}
                      </IconButton>
                    </NormalTableCell>
                    <NormalTableCell component="th" scope="row" onClick={() => navigateToDetail(doctor.id)}>
                      {doctor.id}
                    </NormalTableCell>
                    <NormalTableCell onClick={() => navigateToDetail(doctor.id)}>
                      {getName(doctor.last_name, doctor.academic_degree, doctor.first_name)}
                    </NormalTableCell>
                    <NormalTableCell onClick={() => navigateToDetail(doctor.id)}>
                      {doctor.company_name}
                    </NormalTableCell>
                    <NormalTableCell onClick={() => navigateToDetail(doctor.id)}>
                      {doctor.email}
                    </NormalTableCell>
                    <FunctionsTableCell align="right">
                      <StyledTooltip
                        title="Ansehen"
                        arrow={true}
                        enterDelay={600}
                        enterNextDelay={600}
                      >
                        <TableIconButton
                          aria-label="ansehen"
                          size="small"
                          onClick={() => navigateToDetail(doctor.id)}
                        >
                          <VisibilityOutlinedIcon style={{ fontSize: 18 }} />
                        </TableIconButton>
                      </StyledTooltip>
                      <StyledTooltip
                        title="bearbeiten"
                        arrow={true}
                        enterDelay={600}
                        enterNextDelay={600}
                      >
                        <TableIconButton aria-label="bearbeiten" size="small" onClick={() => navigateToEdit(doctor.id)} >
                          <EditSharpIcon style={{ fontSize: 18 }} />
                        </TableIconButton>
                      </StyledTooltip>
                      {!disableDeleteButton &&
                        <StyledTooltip
                          title="löschen"
                          arrow={true}
                          enterDelay={600}
                          enterNextDelay={600}
                        >
                          <TableIconButton
                            aria-label="löschen"
                            size="small"
                            /* disabled={disableDeleteButton} */
                            onClick={() =>
                              openDeleteDialog(
                                doctor.id,
                                (doctor.academic_degree
                                  ? doctor.academic_degree + " "
                                  : "") +
                                (doctor.first_name
                                  ? doctor.first_name + " "
                                  : "") +
                                doctor.last_name
                              )
                            }
                          >
                            <DeleteForeverSharpIcon style={{ fontSize: 18 }} />
                          </TableIconButton>
                        </StyledTooltip>}
                    </FunctionsTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>

              <TableFooter>
                <StyledTableRow>
                  <TablePagination
                    className={classes.pagination}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    colSpan={headCells.length + 1}
                    count={totalEntries}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "Einträge pro Seite" },
                      classes: { icon: classes.selectIcon },
                      MenuProps: {disableScrollLock: true},
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </StyledTableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </section>
      <DeleteDialog
        callback={alert}
        isOpen={isDeleteOpen}
        handleClose={handleDeleteDialogClose}
        handleCloseOnSubmit={handleDeleteDialogCloseOnSubmit}
        name={name}
        id={id}
        type="physician"
        message=" wird dauerhaft gelöscht und ist kein Teilnehmer mehr in den jeweiligen Veranstaltungen."
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MuiAlert
          elevation={0}
          variant="filled"
          severity={successMessage[0]}
          onClose={handleSnackbarClose}
        >
          {successMessage[1]}
        </MuiAlert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Doctor;
