import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Container, Col, Row } from "react-bootstrap";
import { makeStyles, fade } from "@material-ui/core/styles";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SaveSharpIcon from "@material-ui/icons/SaveSharp";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import FormData from "form-data";

import http from "../../utils/http-common";
import { TopContent, StyledButton } from "../../components";
import { SECONDARY, SECONDARY_LIGHT, WHITE } from "../../styles/colors";
import { userFormEdit } from "./userData";
import { getAuthUser } from "../../utils/authUser";
import imageCompression from "browser-image-compression";

const useStyles = makeStyles(() => ({
  darkerSection: {
    backgroundColor: SECONDARY,
  },
  label: {
    textTransform: "none",
    marginTop: "8px",
    fontSize: "13px",
  },
  field: {
    width: "100%",
    backgroundColor: SECONDARY_LIGHT + "!important",
    "&:-webkit-autofill": {
      webkitBoxSha: "0 0 0 30px white inset !important",
    },
  },
  select: {
    padding: "10.5px 14px",
    backgroundColor: SECONDARY_LIGHT + "!important",
    "&:hover": {
      backgroundColor: fade(WHITE, 0.25) + "!important",
    },
    color: WHITE,
    width: "100%",
    fontSize: "16px",
  },
  selectIcon: {
    color: "#ffffff80!important",
  },
  required: {
    fontSize: "13px",
    marginTop: "16px",
    color: "#fffc !important",
  },
  imagePicker: {
    width: "100%",
    backgroundColor: SECONDARY_LIGHT + "!important",
    "&:-webkit-autofill": {
      webkitBoxSha: "0 0 0 30px white inset !important",
    },
    "&:hover": {
      backgroundColor: fade(WHITE, 0.25) + "!important",
      cursor: "pointer",
    },
  },
  error: {
    color: "#F26C51",
  },
}));

const UserEdit = () => {
  const [user, setUser] = useState({});
  const history = useHistory();
  let { id } = useParams();
  const classes = useStyles();
  const [values, setValues] = useState({
    id: "",
    salutation: "",
    academic_degree: "",
    role: "",
    job_title: "",
    first_name: "",
    name: "",
    phone: "",
    email: "",
    city: "",
    country: "",
  });
  const [isError, setError] = useState({});
  const imageUploader = React.useRef(null);
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState([]);
  const currentUser = getAuthUser();

  useEffect(() => {
    if (currentUser && user && user.roles) {
      switch (currentUser.roles[0]) {
        case "internal":
          switch (user.roles[0].name) {
            case "external":
              break;
            case "internal":
              break;
            default:
              history.push("/error401");
              break;
          }
          break;
        case "admin":
          break;
        default:
          history.push("/error401");
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, user]);

  // Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);

    if (successMessage[0] === "success") {
      history.goBack();
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      await http
        .get("/user/" + id)
        .then(function (response) {
          if (response.data.result === "Token is Expired") {
          } else {
            setUser(response.data.user);
            setImagePreview(response.data.user.baseImage);
            //console.log(response.data.user);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    fetchUser();
  }, [id]);

  useEffect(() => {
    if (user && user.roles) {
      setValues({
        id: user.id,
        salutation: user.salutation !== null ? user.salutation : "",
        academic_degree:
          user.academic_degree !== null ? user.academic_degree : "",
        role: user.roles[0].name,
        job_title: user.job_title !== null ? user.job_title : "",
        first_name: user.first_name !== null ? user.first_name : "",
        name: user.name,
        tel: user.tel !== null ? user.tel : "",
        email: user.email,
        city: user.city !== null ? user.city : "",
        country: user.country !== null ? user.country : "",
      });
    }
  }, [user]);

  // User types in Input Field
  const handleChange = (fieldId) => (event) => {
    setValues({ ...values, [fieldId]: event.target.value });
    setError({ ...isError, [fieldId]: undefined });

    userFormEdit.forEach((field) => {
      if (fieldId === field.id && event.target.value.length > field.max) {
        setError({
          ...isError,
          [fieldId]:
            "Die maximale Länge dieses Feldes entspricht " +
            field.max +
            " Zeichen.",
        });
      }
    });
  };

  const handleOnBlur = (fieldId) => (event) => {
    setError({ ...isError, [fieldId]: undefined });

    userFormEdit.forEach((field) => {
      if (
        fieldId === field.id &&
        !document.getElementById(fieldId).validity.valid
      ) {
        if (field.required === true) {
          setError({
            ...isError,
            [fieldId]: "Füllen Sie bitte dieses Feld aus.",
          });
        }
        if (field.inputType === "email") {
          setError({ ...isError, [fieldId]: "Ungültige Emailadresse." });
        }
        if (field.inputType === "url") {
          setError({
            ...isError,
            [fieldId]:
              "Ungültige URL. Bitte benutzen Sie folgendes Schema: 'urlschema://restderurl'",
          });
        }
        if (field.inputType === "tel") {
          setError({ ...isError, [fieldId]: "Ungültige Nummer." });
        }
      }
      if (fieldId === field.id && event.target.value.length < field.min) {
        setError({
          ...isError,
          [fieldId]:
            "Bitte geben Sie mindestens " +
            field.min +
            " Zeichen ein.",
        });
      }
    });
  };

  console.log(values);

  const handleConfirm = async (event) => {
    event.preventDefault();

    let errorList = {};
    for (const [key, value] of Object.entries(isError)) {
      if (value !== undefined) {
        errorList[key] = value;
        setSuccessMessage([
          "error",
          "Füllen Sie die Felder bitte richtig aus.",
        ]);
        setSnackbarOpen(true);
      }
    }

    userFormEdit.forEach((field) => {
      if (field.required && values[field.id] === "") {
        errorList[field.id] = "Füllen Sie bitte dieses Feld aus.";
        setSuccessMessage([
          "error",
          "Füllen Sie bitte alle erforderlichen Felder aus.",
        ]);
        setSnackbarOpen(true);
      }
      setError(errorList);
    });

    if (Object.keys(errorList).length === 0) {
      let data = new FormData();
      if (image && image !== "") {
        data.append("image", image);
      }
      Object.keys(values).forEach(function (key) {
        //console.log(key, values[key])
        data.append(key, values[key]);
      });

      /* for (var value of data.values()) {
        console.log(value);
      } */

      http
        .post("/user/update", data, {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          },
        })
        .then((result) => {
          if (result.status === 200 || result.status === 201) {
            setSuccessMessage(["success", "Erfolgreich bearbeitet."]);
            setValues({
              id: "",
              salutation: "",
              academic_degree: "",
              role: "",
              job_title: "",
              first_name: "",
              name: "",
              phone: "",
              email: "",
              city: "",
              country: "",
            });
            setImagePreview("");
            setImage("");
            setError({});
          } else {
            console.log("Status: " + result.status + ", " + result.statusText);
            setSuccessMessage(["error", "Fehler beim Bearbeiten."]);
          }
        })
        .catch(function (error) {
          console.log(error);
          if (JSON.parse(error.response.data)) {
            for (const [key, value] of Object.entries(JSON.parse(error.response.data))) {
              setSuccessMessage(["error", `${key}: ${value}`]);
            }
          } else {
            setSuccessMessage(["error", "Fehler beim Erstellen."]);
          }
        })
        .finally(function () {
          setSnackbarOpen(true);
        });
    }
  };

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      var filesize = (e.target.files[0].size / 1024 / 1024).toFixed(4); // MB
      reader.onload = (e) => {
        if (filesize > 2) {
          setError({
            ...isError,
            image:
              "Maximale Dateigröße von 2MB überschritten. Bitte wählen Sie ein kleineres Bild.",
          });
          setValues({ ...values, image: "" });
          setImage("");
          setImagePreview("");
        } else {
          //setValues({ ...values, image: e.target.result });
          /* setImage(file);
          setImagePreview(e.target.result);
          setError({ ...isError, image: undefined }); */
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 150,
            useWebWorker: true,
          };
          imageCompression(file, options)
            .then((compressedBlob) => {
              const convertedBlobFile = new File([compressedBlob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });
              setImage(convertedBlobFile);
              setImagePreview(e.target.result);
              setError({ ...isError, image: undefined });
            })
            .catch((e) => {
              console.log(e);
            });
        }
      };
      reader.onerror = (e) => {
        setError({ ...isError, image: e.target.error });
      };
      reader.readAsDataURL(file);
    }
  };

  const generateFormFields = (formFields) =>
    formFields.map((field) => {
      if (field.type === "text") {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
          >
            <div className={classes.label}>
              {field.label}
              {field.required === true ? "*" : ""}
            </div>
            <OutlinedInput
              id={field.id}
              type={field.inputType}
              required={field.required}
              margin="dense"
              size="small"
              className={classes.field}
              classes={{ input: classes.field }}
              onChange={handleChange(field.id)}
              onBlur={handleOnBlur(field.id)}
              autoComplete="off"
              value={values[field.id] || ""}
              inputProps={{
                "aria-label": field.label,
              }}
            />
            {isError[field.id] ? (
              <FormHelperText
                id={"error-text-" + field.id}
                className={classes.error}
              >
                {isError[field.id]}
              </FormHelperText>
            ) : (
                ""
              )}
          </Col>
        );
      }
      if (field.type === "select") {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
          >
            <FormControl style={{ width: "100%" }}>
              <div className={classes.label}>
                {field.label}
                {field.required === true ? "*" : ""}
              </div>
              <Select
                disableUnderline={true}
                value={values[field.id] || ""}
                onChange={handleChange(field.id)}
                defaultValue={""}
                className={classes.field}
                classes={{ root: classes.select, icon: classes.selectIcon }}
                MenuProps={{
                  disableScrollLock: true,
                }}
              >
                <MenuItem className={classes.selectValues} value={""}>
                  <em>Keine Angabe</em>
                </MenuItem>
                {field.options.map((option) => {
                  if (
                    currentUser &&
                    currentUser.roles.includes("internal") &&
                    option.value === "admin"
                  ) {
                    return "";
                  }
                  return (
                    <MenuItem
                      key={option.value}
                      className={classes.selectValues}
                      value={option.value}
                    >
                      {option.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {isError[field.id] ? (
              <FormHelperText
                id={"error-text-" + field.id}
                className={classes.error}
              >
                {isError[field.id]}
              </FormHelperText>
            ) : (
                ""
              )}
          </Col>
        );
      }
      if (field.type === "image") {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
          >
            <div className={classes.label}>
              {field.label}
              {field.required === true ? "*" : ""}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                type="file"
                accept="image/png, image/jpg, image/jpeg"
                onChange={handleImageUpload}
                ref={imageUploader}
                style={{
                  display: "none",
                }}
              />
              <div
                className={classes.imagePicker}
                style={{
                  height: "100px",
                  padding: "10.5px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
                onClick={() => imageUploader.current.click()}
              >
                {imagePreview && imagePreview !== "" ? (
                  <img
                    src={imagePreview}
                    style={{
                      width: "auto",
                      height: "100%",
                    }}
                    alt=""
                  />
                ) : (
                    <p style={{ fontSize: "14px", margin: "auto" }}>
                      Bild auswählen <br />
                    Maximale Dateigröße: 2MB, erlaubte Dateiendungen: png, jpg,
                    jpeg
                    </p>
                  )}
              </div>
            </div>
            {isError[field.id] ? (
              <FormHelperText
                id={"error-text-" + field.id}
                className={classes.error}
              >
                {isError[field.id]}
              </FormHelperText>
            ) : (
                ""
              )}
          </Col>
        );
      } else {
        return "Input-Type not defined";
      }
    });

  return (
    <section className={classes.darkerSection}>
      <div className="wrapper">
        <TopContent page={user.name + " bearbeiten"} />

        <div className="buttonSection">
          <StyledButton
            startIcon={<ArrowBackIosSharpIcon />}
            onClick={() => {
              history.goBack();
            }}
          >
            zurück
          </StyledButton>
        </div>

        <form style={{ marginTop: "50px" }}>
          <Container fluid style={{ padding: 0, margin: 0 }}>
            <Row>{generateFormFields(userFormEdit)}</Row>
          </Container>
        </form>
        <div className={classes.required}>* Pflichtfeld</div>
        <div style={{ textAlign: "end", marginTop: "12px" }}>
          <StyledButton
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={<SaveSharpIcon />}
            onClick={handleConfirm}
            autoFocus
            type="submit"
          >
            speichern
          </StyledButton>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MuiAlert
          elevation={0}
          variant="filled"
          severity={successMessage[0]}
          onClose={handleSnackbarClose}
        >
          {successMessage[1]}
        </MuiAlert>
      </Snackbar>
    </section>
  );
};

export default UserEdit;
