import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";

import "../deleteDialog/DeleteDialog.css";
import { SECONDARY, WHITE } from "../../../styles/colors";
import { StyledButton } from "../..";
import http from "../../../utils/http-common";

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: SECONDARY,
    color: WHITE,
    borderRadius: 0,
  },
  content: {
    color: WHITE,
  },
}));

export const LogDialog = ({ isOpen, handleClose, logEntry }) => {
  const classes = useStyles();
  const [logData, setLogData] = useState({});

  const formatDate = (date) => {
    return format(new Date(date), "dd.MM.yyy");
  };

  const formatTime =(date) => {
    return format(new Date(date), "HH:mm:ss");
  }

  useEffect(() => {
    const fetchLogData = async () => {
      if (typeof logEntry === "number") {
        await http
          .get("/protocol/" + logEntry)
          .then(function (response) {
            console.log(response);
            setLogData(response.data.result);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    };
    fetchLogData();
  }, [logEntry]);

  const getContent = () => {
    if (
      logData &&
      Object.keys(logData).length === 0 &&
      logData.constructor === Object
    ) {
      return "";
    } else {
      if (logData.protocol.method === "Datensatz gelöscht") {
        return (
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              classes={{ root: classes.content }}
            >
              {logData.protocol.tbl} wurde am{" "}
              {formatDate(logData.protocol.updated_at)} um {formatTime(logData.protocol.updated_at)} Uhr von dem/der Benutzer/-in
              "{logData.protocol.user}" gelöscht.
            </DialogContentText>
            {logData.audit.length > 0 && (
              <DialogContentText>
                <b>Daten: </b>
                <br />
                {getItemData(logData.audit[0])}
              </DialogContentText>
            )}
          </DialogContent>
        );
      }
      if (logData.protocol.method === "Datensatz angelegt") {
        return (
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              classes={{ root: classes.content }}
            >
              {logData.protocol.tbl} wurde am{" "}
              {formatDate(logData.protocol.updated_at)} um {formatTime(logData.protocol.updated_at)} Uhr von dem/der Benutzer/-in
              "{logData.protocol.user}" erstellt.
            </DialogContentText>
            {logData.audit.length > 0 && (
              <DialogContentText>
                <b>Daten: </b>
                <br />
                {getItemData(logData.audit[0])}
              </DialogContentText>
            )}
          </DialogContent>
        );
      }
      if (logData.protocol.method === "Datensatz bearbeitet") {
        return (
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              classes={{ root: classes.content }}
            >
              {logData.protocol.tbl} wurde am{" "}
              {formatDate(logData.protocol.updated_at)} um {formatTime(logData.protocol.updated_at)} Uhr von dem/der Benutzer/-in
              "{logData.protocol.user}" bearbeitet.
            </DialogContentText>
            {logData.audit.length > 1 && (
              <DialogContentText>
                <b>Neue Daten: </b>
                <br />
                {getItemData(logData.audit[0])}
              </DialogContentText>
            )}
            {logData.audit.length > 1 && (
              <DialogContentText>
                <b>Alte Daten: </b>
                <br />
                {getItemData(logData.audit[1])}
              </DialogContentText>
            )}
          </DialogContent>
        );
      } if (logData.protocol.method === "Einladung versendet") {
        return (
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              classes={{ root: classes.content }}
            >
              Der/die Benutzer/-in
              "{logData.protocol.user}" hat am{" "}
              {formatDate(logData.protocol.updated_at)} um {formatTime(logData.protocol.updated_at)} Uhr einen/eine Arzt/Ärztin zu einer Veranstaltung eingeladen.
            </DialogContentText>
          </DialogContent>
        );
      }else return "";
    }
  };

  const getItemData = (item) => {
    if (item) {
      return (
        <span >
          {Object.keys(item).map((key) => (
            <span key={key}>
                  <span style={{ textTransform: "capitalize" }}>{key}</span>: {item[key]}
                  <br></br>
            </span>
          ))}
        </span>
      );
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.paper }}
      disableScrollLock={true}
    >
      <DialogTitle id="alert-dialog-title">{"Information"}</DialogTitle>
      {getContent()}
      <DialogActions>
        <StyledButton
          onClick={handleClose}
          variant="contained"
          color="primary"
          className={classes.button}
          autoFocus
        >
          schließen
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

LogDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  logEntry: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default LogDialog;
