import React from "react";

import { TopContent } from "../../components";

const Salesmen = () => {
    return (
        <div className="wrapper">
            <TopContent page="Aussendienstler" />
        </div >
    );
}

export default Salesmen;