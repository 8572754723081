import React, {useState} from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';

import {TopContent, CreateForm, StyledButton} from "../../components";
import { SECONDARY } from "../../styles/colors";
import Alert from "../../components/Alert/Alert";


const useStyles = makeStyles((theme) => ({
    darkerSection: {
        backgroundColor: SECONDARY,
    }
}));

const DoctorNew = () => {
    const history = useHistory();
    const classes = useStyles();
    const [isSuccessfull, setSuccess] = useState(false);
    const [isFailed, setFail] = useState(false);
    const form = [
        {
            id: "salutation",
            label : "Anrede",
            type: "select",
            value: "",
            options: [{value: "mrs", text:"Frau"}, {value: "mr", text: "Herr"}],
            colLg: 6,
            colMd: 6,
            colSm: 6,
            required: false,
        },
        {
            id: "academic_degree",
            label : "Titel",
            type: "text",
            value: "",
            max: 31,
            colLg: 6,
            colMd: 6,
            colSm: 6,
            required: true,
        },
        {
            id: "first_name",
            label : "Vorname",
            type: "text",
            value: "",
            required: true,
            max: 255,
            colLg: 6,
            colMd: 6,
            colSm: 6
        },
        {
            id: "last_name",
            label : "Nachname",
            type: "text",
            value: "",
            required: true,
            max: 255,
            colLg: 6,
            colMd: 6,
            colSm: 6
        },
        {
            id: "email",
            label : "Email",
            type: "email",
            value: "",
            format: "email",
            required: true,
            max: 255,
            colLg: 6,
            colMd: 6,
            colSm: 6
        },
        {
            id: "company",
            label : "Klinik / Praxis",
            type: "autofill",
            value: {0: null},
            required: true,
        },
    ];

    const dataFromProps = [];
    const dataFromValues = [
        {name:"salutation", type:"string"},
        {name:"academic_degree", type:"string"},
        {name:"first_name", type:"string"},
        {name:"last_name", type:"string"},
        {name:"email", type:"string"},
        {name:"company", type:"object"}
    ];

    const goBack = (ready) => {
        if (ready) {
            history.goBack();
        }
    }

    const alert = (result) => {
        if (result === "success") {
            setSuccess(true);
        } else {
            setFail(true)
        }
    };


    return (
        <section className={classes.darkerSection}>
            <div className="wrapper">
                <TopContent page="Arzt anlegen" />

                <div className="buttonSection">
                    <StyledButton startIcon={<ArrowBackIosSharpIcon />} onClick={() => {history.goBack();}}>zurück</StyledButton>
                </div>

                <CreateForm
                    form={form}
                    data={{dataFromProps: dataFromProps, dataFromValues: dataFromValues}}
                    url="physician/insert"
                    method="POST"
                    result="physician"
                    callBack={alert}
                />
            </div>
            {isSuccessfull && <Alert type="success" message="Arzt erfolgreich angelegt!" callBack={goBack}/>}
            {isFailed && <Alert type="error" message="Fehler beim Anlegen des neuen Arztes!" callBack={goBack}/>}
        </section>
    );
}

export default DoctorNew;