import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from './context/auth';
import http from './utils/http-common';
import { getAuthUser, setAuthUser } from './utils/authUser';

function PrivateRoute({ component: Component, roles, ...rest }) {
  const { authTokens } = useAuth();
  const { setAuthTokens } = useAuth();
  const user = getAuthUser();


  useEffect(() => {
    if (user) {
    }
  }, [user, roles])

  if (user) {
    if (roles && roles.indexOf(user.roles[0]) === -1) {
      return <Redirect to={{ pathname: '/error401' }} />
    }
  }


  function logout() {
    http
      .get('/logout')
      .then((result) => {
        if (result.status === 200) {
          console.log(result.data.message);
        } else {
          console.error(result.status + result.statusText);
        }
      })
      .catch((e) => {
        console.error(e);
      });

    //localStorage.removeItem('user');
    setAuthUser(null);
    setAuthTokens(null);
  }


  return (
    <Route
      {...rest}
      render={(props) => (authTokens ? <Component {...props} useLogout={logout} /> : <Redirect to="/login" />)}
    />
  );
}

export default PrivateRoute;
