import React from "react";
import { Col, Row } from 'react-bootstrap'
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { PRIMARY_LIGHT } from "../../styles/colors";
import Profile from "../profile/Profile";

const TopContent = (props) => {
    const matches = useMediaQuery('(min-width:992px)');

    const styles = {
        adminText: {
            color: PRIMARY_LIGHT,
            fontWeight: "normal",
            fontSize: "24px",
            marginBottom: "20px",
            textTransform: "uppercase",
        },
        container: {
            padding: 0,
            margin: 0,
        },
        headingLight: {
            fontWeight: 100,
        },
        heading: {
            fontSize: "34px",
            fontWeight: 500,
            letterSpacing: "1px",
        }
    };

    return (
        <div>
        <Row>
            <Col md={12} lg={8} >
                <div style={styles.adminText}>Veranstaltungs-Kalender<span style={styles.headingLight} > &#47;&#47; Admintool</span></div>
                <h1 style={styles.heading}>{props.page}</h1>
            </Col>
            {matches === true ? <Col md={4}><Profile /></Col> : null}
        </Row>
        {props.subheading ? <Row><Col>{props.subheading}</Col></Row> : null}
        </div>
    );
}

export default TopContent;