import * as SecureLS from 'secure-ls';

const ls = new SecureLS({ encodingType: 'aes' });

export const setAuthToken = (token) => {
  if (token !== null) {
    ls.set('token', { data: token });
  } else {
    ls.remove('token');
  }
};

export const getAuthToken = () => {
  return ls.get('token').data;
}

