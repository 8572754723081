export const generalDataForm = [
    {
        id: "event_name",
        label: "Name",
        type: "text",
        inputType: "text",
        value: "",
        required: true,
        max: 255,
        colLg: 12,
        colMd: 12,
        colSm: 12,
    },
    {
        id: "category_id",
        label: "Kategorie",
        type: "select",
        inputType: "categories",
        value: "",
        required: false,
        colLg: 3,
        colMd: 3,
        colSm: 6,
    },
    {
        id: "topic_id",
        label: "Thema",
        type: "select",
        inputType: "topics",
        value: "",
        required: false,
        colLg: 3,
        colMd: 3,
        colSm: 6,
    },
    {
        id: "participant_limit",
        label: "Maximale Teilnehmeranzahl",
        type: "text",
        inputType: "number",
        value: "",
        required: false,
        colLg: 3,
        colMd: 3,
        colSm: 6,
    },
    {
        id: "costs",
        label: "Preis",
        type: "text",
        inputType: "number",
        value: "",
        required: false,
        colLg: 3,
        colMd: 3,
        colSm: 6,
    },
    {
        id: "date_from",
        label: "Startdatum",
        type: "date",
        inputType: "date",
        value: "",
        required: true,
        colLg: 3,
        colMd: 3,
        colSm: 6,
    },
    {
        id: "date_to",
        label: "Enddatum",
        type: "date",
        inputType: "date",
        value: "",
        required: false,
        colLg: 3,
        colMd: 3,
        colSm: 6,
    },
    {
        id: "time_from",
        label: "Startzeit",
        type: "date",
        inputType: "time",
        value: "",
        required: false,
        max: 255,
        colLg: 3,
        colMd: 3,
        colSm: 6,
    },
    {
        id: "time_to",
        label: "Endzeit",
        type: "date",
        inputType: "time",
        value: "",
        required: false,
        colLg: 3,
        colMd: 3,
        colSm: 6,
    },
    {
        id: "published",
        label: "Veröffentlicht",
        type: "select",
        inputType: "custom",
        value: "",
        required: true,
        options: [{ value: "1", text: "Ja" }, { value: "0", text: "Nein" }],
        colLg: 4,
        colMd: 4,
        colSm: 12,
    },
    {
        id: "published_from",
        label: "Veröffentlicht von",
        type: "date",
        inputType: "date",
        value: "",
        required: false,
        colLg: 4,
        colMd: 4,
        colSm: 6,
    },
    {
        id: "published_to",
        label: "Veröffentlicht bis",
        type: "date",
        inputType: "date",
        value: "",
        required: false,
        colLg: 4,
        colMd: 4,
        colSm: 6,
    },
    {
        id: "organizer_id",
        label: "Veranstalter",
        type: "autocomplete",
        value: "",
        required: false,
        colLg: 12,
        colMd: 12,
        colSm: 12,
    },
    {
        id: "description",
        label: "Beschreibung",
        type: "wysiwyg",
        value: "",
        required: false,
        colLg: 12,
        colMd: 12,
        colSm: 12,
    },
]

export const addressDataForm = [
    {
        id: "address_name",
        label: "Name",
        type: "text",
        inputType: "text",
        value: "",
        required: false,
        max: 255,
        colLg: 12,
        colMd: 12,
        colSm: 12,
    },
    {
        id: "street",
        label: "Straße und Hausnummer",
        type: "text",
        inputType: "text",
        value: "",
        required: false,
        max: 255,
        colLg: 8,
        colMd: 8,
        colSm: 8,
    },
    {
        id: "addition",
        label: "Zusatz",
        type: "text",
        inputType: "text",
        value: "",
        required: false,
        max: 255,
        colLg: 4,
        colMd: 4,
        colSm: 4,
    },
    {
        id: "post_code",
        label: "PLZ",
        type: "text",
        inputType: "number",
        value: "",
        required: false,
        max: 255,
        colLg: 4,
        colMd: 4,
        colSm: 4,
    },
    {
        id: "city",
        label: "Stadt",
        type: "text",
        inputType: "text",
        value: "",
        required: false,
        max: 255,
        colLg: 4,
        colMd: 4,
        colSm: 4,
    },
    {
        id: "country",
        label: "Land",
        type: "text",
        inputType: "text",
        value: "",
        required: false,
        max: 255,
        colLg: 4,
        colMd: 4,
        colSm: 4,
    },
    {
        id: "tel",
        label: "Telefon",
        type: "text",
        inputType: "tel",
        value: "",
        required: false,
        max: 255,
        colLg: 6,
        colMd: 6,
        colSm: 6,
    },
    {
        id: "fax",
        label: "Fax",
        type: "text",
        inputType: "tel",
        value: "",
        required: false,
        max: 255,
        colLg: 6,
        colMd: 6,
        colSm: 6,
    },
    {
        id: "email",
        label: "E-Mail",
        type: "text",
        inputType: "email",
        value: "",
        required: false,
        max: 255,
        colLg: 12,
        colMd: 12,
        colSm: 12,
    },
]

export const itemDataForm = {
    id: "",
    item_description: "",
    date_from: "",
    date_to: "",
    time_from: "",
    time_to: "",
}


export const eventValues = {
    id: "",
    event_name: "",
    date_from: "",
    date_to: "",
    time_from: "",
    time_to: "",
    description: "",
    participant_limit: "",
    costs: "",
    published: "",
    published_from: "",
    published_to: "",
    items: [{}],
    addresses: [
        {
            address_name: "",
            street: "",
            addition: "",
            post_code: "",
            city: "",
            country: "",
            tel: "",
            fax: "",
            email: "",
        }
    ],
    category_id: "",
    topic_id: "",
    organizer_id: "",
    speakers: [{}],
    sponsors: [{}],
}

export const addressValues = [
    {
        address_name: "",
        street: "",
        addition: "",
        post_code: "",
        city: "",
        country: "",
        tel: "",
        fax: "",
        email: "",
    }
]

export const eventDetailFormOverview = [
    {
        id: "event_name",
        label: "Name",
        type: "text",
        colLg: 6,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "category_id",
        label: "Kategorie",
        type: "category",
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "topic_id",
        label: "Thema",
        type: "topic",
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
]

export const eventDetailFormGeneral = [
    {
        id: "event_name",
        label: "Name",
        type: "text",
        colLg: 6,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "organizer_id",
        label: "Veranstalter",
        type: "organizer",
        colLg: 6,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "category_id",
        label: "Kategorie",
        type: "category",
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "topic_id",
        label: "Thema",
        type: "topic",
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "participant_limit",
        label: "Maximale Teilnehmeranzahl",
        type: "text",
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "costs",
        label: "Preis",
        type: "text",
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "date_from",
        label: "Startdatum",
        type: "date",
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "date_to",
        label: "Enddatum",
        type: "date",
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "time_from",
        label: "Startzeit",
        type: "time",
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "time_to",
        label: "Endzeit",
        type: "time",
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "published",
        label: "Veröffentlicht",
        type: "published",
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "published_from",
        label: "Veröffentlicht von",
        type: "date",
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "published_to",
        label: "Veröffentlicht bis",
        type: "date",
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "description",
        label: "Beschreibung",
        type: "wysiwyg",
        colLg: 12,
        colMd: 12,
        colSm: 12,
    },
]

export const eventDetailFormAddress = [
    {
        id: "address_name",
        label: "Name",
        type: "addresses",
        colLg: 6,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "email",
        label: "E-Mail",
        type: "addresses",
        colLg: 6,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "street",
        label: "Straße und Hausnummer",
        type: "addresses",
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "addition",
        label: "Zusatz",
        type: "addresses",
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "post_code",
        label: "PLZ",
        type: "addresses",
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "city",
        label: "Stadt",
        type: "addresses",
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "country",
        label: "Land",
        type: "addresses",
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "tel",
        label: "Telefon",
        type: "addresses",
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "fax",
        label: "Fax",
        type: "addresses",
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    
]

export const eventDetailFormItems = [
    {
        id: "date_from",
        label: "Startdatum",
        type: "item",
        inputType: "date",
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "time_from",
        label: "Startzeit",
        type: "item",
        inputType: "time",
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "item_description",
        label: "Beschreibung",
        type: "item",
        inputType: "text",
        colLg: 6,
        colMd: 6,
        colSm: 12,
    },
]

export const eventDetailFormSpeakers = [
    {
        id: "speaker",
        label: "Name",
        type: "item",
        inputType: "text",
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
    {
        id: "company_name",
        label: "Klinik / Praxis",
        type: "item",
        inputType: "text",
        colLg: 3,
        colMd: 6,
        colSm: 12,
    },
]

export const eventDetailFormSponsors = [
    {
        id: "name",
        label: "Name",
        type: "item",
        inputType: "text",
        colLg: 6,
        colMd: 6,
        colSm: 12,
    },
]

