import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import SaveSharpIcon from '@material-ui/icons/SaveSharp';

import "../deleteDialog/DeleteDialog.css";
import { SECONDARY, WHITE } from "../../../styles/colors";
import { StyledButton } from "../..";

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: SECONDARY,
    color: WHITE,
    borderRadius: 0,
  },
  content: {
    color: WHITE,
  },
}));

export const CreateBackupDialog = ({ isOpen, handleClose, handleCloseOnSubmit}) => {
  const classes = useStyles();

  function handleConfirm() {
    handleCloseOnSubmit();
  } 


  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.paper }}
      disableScrollLock={true}
    >
      <DialogTitle id="alert-dialog-title">{"Neues Backup erstellen"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" classes={{ root: classes.content }}>
          Möchten Sie ein aktuelles Backup der Datenbank erstellen?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="secondary"
          className="secondaryBtn"
        >
          abbrechen
        </Button>
        <StyledButton
          variant="contained"
          color="primary"
          className={classes.button}
          endIcon={<SaveSharpIcon />}
          onClick={handleConfirm}
          autoFocus
        >erstellen</StyledButton>
      </DialogActions>
    </Dialog>
  );
};

CreateBackupDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCloseOnSubmit: PropTypes.func.isRequired,
};

export default CreateBackupDialog;
