import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import SearchSharpIcon from "@material-ui/icons/SearchSharp";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import deLocale from "date-fns/locale/de";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//import http from "../../../utils/http-common";
import "../deleteDialog/DeleteDialog.css";
import {
  SECONDARY,
  WHITE,
  SECONDARY_LIGHT,
  WHITE_TRANSPARENT,
  PRIMARY_LIGHT,
} from "../../../styles/colors";
import { StyledButton /* SearchBar */ } from "../..";
import { InputAdornment } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: SECONDARY,
    color: WHITE,
    borderRadius: 0,
  },
  content: {
    color: WHITE,
  },
  field: {
    width: "100%",
    backgroundColor: SECONDARY_LIGHT + "!important",
    "&:-webkit-autofill": {
      webkitBoxSha: "0 0 0 30px white inset !important",
    },
    borderRadius: 0,
  },
  inputAdornment: {
    color: WHITE_TRANSPARENT,
  },
  label: {
    fontSize: "14px",
    color: WHITE_TRANSPARENT,
    whiteSpace: "nowrap",
    display: "inline-block",
  },
  root: {
    backgroundColor: SECONDARY_LIGHT,
    height: "37.63px",
    margin: "6px 0 0 0",
    width: "100%",
    display: "inline-block",
  },
  inputRoot: {
    backgroundColor: SECONDARY_LIGHT + "!important",
    paddingLeft: "12px",
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25) + "!important",
    },
    width: "100%",
    height: "37.63px",
  },
  input: {
    color: "#ffffff80!important",
    paddingRight: "14px",
    fontSize: "14px!important",
  },
  datePickerField: {
    width: "100%",
    backgroundColor: SECONDARY_LIGHT + "!important",
    "&:-webkit-autofill": {
      webkitBoxSha: "0 0 0 30px white inset !important",
    },
    borderRadius: 0,
    marginTop: "6px",
  },
}));

const StyledCheckbox = withStyles({
  root: {
    color: WHITE_TRANSPARENT,
    "&$checked": {
      color: PRIMARY_LIGHT,
    },
    height: "37.63px",
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const abbottTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: SECONDARY,
      },
    },
    MuiPickersYear: {
      yearSelected: {
        color: PRIMARY_LIGHT,
      },
      root: {
        "&:focus": {
          color: PRIMARY_LIGHT,
        },
      },
    },
    MuiPickersMonth: {
      monthSelected: {
        color: PRIMARY_LIGHT,
      },
      root: {
        "&:focus": {
          color: PRIMARY_LIGHT,
        },
      },
    },
    MuiButton: {
      root: {
        color: SECONDARY + "!important",
      },
    },
  },
});

const StyledSelect = withStyles(() => ({
  root: {
    backgroundColor: SECONDARY_LIGHT + "!important",
    padding: "9.5px 0px 9.5px 12px",
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
    color: "#ffffff80!important",
    width: "100%",
    fontSize: "14px",
  },
  icon: {
    color: "#ffffff80!important",
    marginRight: "14px",
  },
}))(Select);

export const FilterDialog = ({
  isOpen,
  handleClose,
  handleCloseOnSubmit,
  searchVal,
  dateVal,
  categoryVal,
  pastEventVal,
  categories,
}) => {
  const classes = useStyles();
  const [search, setSearch] = useState(searchVal);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [dateFilter, setDateFilter] = useState(null);
  const [pastEventFilter, setPastEventFilter] = useState(pastEventVal);

  useEffect(() => {
    setPastEventFilter(pastEventVal);
  }, [pastEventVal]);

  useEffect(() => {
    setCategoryFilter(categoryVal);
  }, [categoryVal]);

  useEffect(() => {
    if (dateVal !== "") {
      setDateFilter(new Date(dateVal));
    } else {
      setDateFilter(null);
    }
  }, [dateVal]);

  const handleConfirm = () => {
    let pastEvent = boolToNumber(pastEventFilter);
    let date;
    if (dateFilter === null) {
      date = "";
    } else {
      date = format(dateFilter, "yyyy-MM-dd");
    }
    handleCloseOnSubmit(search, categoryFilter, date, pastEvent);
  };

  const handleCheckboxChange = () => {
    setPastEventFilter(!pastEventFilter);
  };

  const boolToNumber = (boolVal) => {
    if (boolVal === true) {
      return 1;
    }
    return 0;
  };

  const handleDateChange = (date) => {
    setDateFilter(date);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.paper }}
      fullWidth={true}
      maxWidth={"xs"}
      disableScrollLock={true}
    >
      <DialogTitle id="alert-dialog-title">{"Events filtern"}</DialogTitle>
      <DialogContent>
        {/* <DialogContentText id="alert-dialog-description" classes={{ root: classes.content }}>
                    test
                </DialogContentText> */}
        {/* <SearchBar callBack={search} /> */}
        {/* Input Felder einfügen */}
        <OutlinedInput
          margin="dense"
          size="small"
          className={classes.field}
          classes={{ input: classes.field }}
          placeholder="Suche"
          endAdornment={
            <InputAdornment
              position="end"
              classes={{ root: classes.inputAdornment }}
            >
              <SearchSharpIcon />
            </InputAdornment>
          }
          defaultValue={searchVal}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          autoComplete="off"
        />
        <FormControlLabel
          control={
            <StyledCheckbox
              name={"pastEvents"}
              checked={pastEventFilter}
              onChange={handleCheckboxChange}
              /*  classes={{root: classes.checkbox, filled: classes.checkboxChecked}} */
            />
          }
          label="Vergangene anzeigen"
          labelPlacement="start"
          classes={{
            root: classes.root,
            label: classes.label,
          }}
        />
        <FormControl style={{ width: "100%", marginTop: "6px" }}>
          <StyledSelect
            displayEmpty
            disableUnderline
            value={categoryFilter}
            onChange={(e) => setCategoryFilter(e.target.value)}
            defaultValue={""}
          >
            <MenuItem value="">Alle Kategorien</MenuItem>
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.category_name}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>

        <div>
          <ThemeProvider theme={abbottTheme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
              <KeyboardDatePicker
                views={["year", "month"]}
                orientation="portrait"
                value={dateFilter}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "Datum ändern",
                  classes: { root: classes.input },
                }}
                className={classes.datePickerField}
                cancelLabel="Abbrechen"
                clearLabel="Zurücksetzen"
                InputProps={{
                  classes: {
                    input: classes.input,
                    root: classes.inputRoot,
                  },
                }}
                inputProps={{
                  "aria-label": "Nach Monat filtern",
                }}
                clearable
                emptyLabel="Monat"
                aria-label="Monat-Filter"
                DialogProps={{
                  disableScrollLock: true,
                }}
              />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          className="secondaryBtn"
          onClick={handleClose}
        >
          abbrechen
        </Button>
        <StyledButton
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleConfirm}
        >
          übernehmen
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

FilterDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCloseOnSubmit: PropTypes.func.isRequired,
  searchVal: PropTypes.string.isRequired,
  dateVal: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
  categoryVal: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  pastEventVal: PropTypes.bool.isRequired,
  categories: PropTypes.array.isRequired,
};

export default FilterDialog;
