import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import PropTypes from "prop-types";
import http from "../../utils/http-common";
import { useAuth } from "../../context/auth";

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from "@material-ui/core/FormHelperText";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import SaveSharpIcon from '@material-ui/icons/SaveSharp';
import { Container, Col, Row } from "react-bootstrap";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { setAuthUser } from "../../utils/authUser";
import FormButton from "./FormButton";
import { Colors } from '../../styles';
import { DuplicateDoctorDialog } from "../"


const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        /* alignItems: 'center', */
        marginTop: '55px'
    },
    container: {
        padding: 0,
        margin: 0,
        maxWidth: "100%"
    },
    col: {
        width: "100%",
        marginBottom: "10px"
    },
    label: {
        textTransform: "none",
    },
    field: {
        width: "100%",
        margin: "5px 0px",
        backgroundColor: Colors.SECONDARY_LIGHT + "!important",
        '&:-webkit-autofill': {
            webkitBoxSha: "0 0 0 30px white inset !important",
        },
        '& div': {
            height: "53px"
        }
    },
    icons: {
        '& .MuiSelect-icon, & .MuiInputBase-root, & .MuiAutocomplete-popupIndicator, & .MuiAutocomplete-clearIndicator': {
            color: "#fff"
        }
    },
    select: {
        '& div': {
            display: "flex",
            alignItems: "center"
        }
    },
    error: {
        color: "#F26C51"
    },
    required: {
        fontSize: "13px",
        marginTop: "16px",
        color: "#fffc !important",
        textAlign: "left",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

const filter = createFilterOptions();


const CreateForm = (props) => {
    const classes = useStyles();
    const { setAuthTokens } = useAuth();
    const [optionList, setOptionList] = useState([]);
    const [isError, setError] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isDuplicateOpen, setIsDuplicateOpen] = useState(false);
    const [duplicatePhysicians, setDuplicatePhysicians] = useState([]);

    // Duplicate Dialog Functions
    const openDuplicateDialog = (duplicates) => {
        setDuplicatePhysicians(duplicates);
        setIsDuplicateOpen(true);
    }

    const handleClose = () => {
        setIsDuplicateOpen(false);
    }

    const handleCloseOnSubmit = () => {
        // TODO
        setIsDuplicateOpen(false);
    }


    // dynamic form values initial state
    const initialState = {};
    props.data.dataFromValues.forEach((data) => {
        if (data.type === "object") {
            initialState[data.name] = { 0: null };
        } else {
            initialState[data.name] = "";
        }
    });
    const [values, setValues] = useState(initialState);

    // dynamic request data
    const requestData = {};
    props.data.dataFromProps.map((data) =>
        requestData[data] = props[data]
    )
    props.data.dataFromValues.map((data) =>
        requestData[data.name] = values[data.name]
    )

    const validEmail = (mail) => {
        // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
        // const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        // https://www.w3resource.com/javascript/form/email-validation.php
        // const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return regex.test(mail);
    }

    const updateValues = (result) => {
        props.callBack("success");

        let updatedValues = {}
        props.data.dataFromValues.forEach((data) => {
            if (data.name === "company") {
                let updatedCompany = { 0: null };
                if (result.data.physician.companies.length > 0) {
                    updatedCompany = { 0: { id: result.data.physician.companies[0].id, company_name: result.data.physician.companies[0].company_name } };
                }
                updatedValues[data.name] = updatedCompany;
            } else {
                updatedValues[data.name] = result.data[props.result][data.name] ?? "";
            }
        })

        setValues(updatedValues);
    }



    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        setError({ ...isError, [prop]: undefined });

        props.form.forEach((field) => {

            if (prop === field.id && field.format === "email" && !validEmail(event.target.value)) {
                setError({ ...isError, [prop]: "Geben Sie bitte eine korrekte Email an." });
            }

            if (prop === field.id && event.target.value.length > field.max) {
                setError({ ...isError, [prop]: "Die maximale Länge dieses Feldes entspricht " + field.max + " Zeichen." });
            }

            if (prop === field.id && event.target.value.length < field.min) {
                setError({ ...isError, [prop]: "Die minimale Länge dieses Feldes entspricht " + field.min + " Zeichen." });
            }

            if (prop === field.id && field.required && event.target.value === "") {
                setError({ ...isError, [prop]: "Füllen Sie bitte dieses Feld aus." });
            }
        })
    };

    const forceCreation = () => {
        http
            .post('/physician/force-insert', requestData).then(result => {
                if (result.status === 200 || result.status === 201) {
                    updateValues(result);
                } else {
                    console.log("Status: " + result.status + ", " + result.statusText);
                    props.callBack("fail");
                }
            }).catch(e => {
                console.log(e);
                props.callBack("fail");
            })
            .finally(() => {
                handleClose();
            });
    }



    const handleSubmit = (event) => {
        event.preventDefault();

        let errorList = {}
        props.form.forEach((field) => {
            if (field.required && values[field.id] === "") {
                errorList[field.id] = "Füllen Sie bitte dieses Feld aus."
            }
            setError(errorList);
        });


        if (Object.keys(errorList).length === 0) {
            if (props.method === "POST") {
                setIsLoading(true);
                http
                    .post(props.url, requestData).then(result => {
                        if (result.status === 200 || result.status === 201) {
                            if (result.data.duplicates) {
                                //console.log(result.data.duplicates)
                                openDuplicateDialog(result.data.duplicates)
                            } else {
                                updateValues(result);
                            }
                        } else {
                            console.log("Status: " + result.status + ", " + result.statusText);
                            props.callBack("fail");
                        }
                    }).catch(e => {
                        console.log(e);
                        props.callBack("fail");

                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }

            if (props.method === "PUT") {
                http
                    .put(props.url, requestData).then(result => {
                        if (result.status === 200 || result.status === 201) {
                            updateValues(result);

                            if (props.url === "/user/update") setAuthUser(result.data.user);

                        } else {
                            console.log("Status: " + result.status + ", " + result.statusText);
                            props.callBack("fail");

                        }
                    }).catch(e => {
                        console.log(e);
                        props.callBack("fail");
                    });
            }
        }
    }


    useEffect(() => {
        const formValues = {}

        props.form.forEach((field) => {
            formValues[field.id] = field.value
        });

        setValues(formValues);

    }, [props.form])


    useEffect(() => {
        const fetchOptions = async () => {
            await http
                .get("/companies/autocomplete")
                .then(function (response) {

                    if (response.data.result === "Token is Expired") {
                        http
                            .get("/logout").then(result => {
                                if (result.status === 200) {
                                    setAuthTokens(null);
                                    setAuthUser(null);
                                } else {
                                    console.error(result.status + result.statusText);
                                }
                            }).catch(e => {
                                console.error(e);
                            });

                        setAuthTokens(null);


                    } else {
                        setOptionList(response.data.data)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        };

        fetchOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const formFields = props.form.map((field) => {

        if (field.type === "select") {
            return (
                <Col lg={field.colLg} md={field.colMd} sm={field.colSm} key={field.id} className={classes.col}>
                    <div className={classes.label}>{field.label}{field.required === true ? "*" : ""}</div>

                    <FormControl className={clsx(classes.field, classes.icons)} variant="outlined">
                        <Select
                            id={field.id}
                            value={values[field.id]}
                            onChange={handleChange(field.id)}
                            className={classes.select}
                            MenuProps={{
                                disableScrollLock: true,
                            }}
                        >
                            {field.options.map((option) => {
                                return (
                                    <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Col>
            )
        } else if (field.type === "autofill") {
            return (
                <Col lg={field.colLg} md={field.colMd} sm={field.colSm} key={field.id} className={classes.col}>
                    <div className={classes.label}>{field.label}{field.required === true ? "*" : ""}</div>

                    <Autocomplete
                        id={field.id}
                        className={clsx(classes.field, classes.icons)}
                        value={values[field.id][0]}
                        onChange={(event, newValue) => {

                            if (typeof newValue === 'string') {
                                setValues({
                                    ...values,
                                    [field.id]: { 0: newValue },
                                });
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                setValues({
                                    ...values,
                                    [field.id]: { 0: { "company_name": newValue.inputValue } },
                                });
                            } else if (newValue === null) {
                                setValues({
                                    ...values,
                                    // [field.id]: {0: {"id": null, "company_name": null}},
                                    [field.id]: { 0: null },
                                });
                            } else {
                                setValues({
                                    ...values,
                                    [field.id]: { 0: newValue },
                                });
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            // Suggest the creation of a new value
                            if (params.inputValue !== '') {
                                filtered.push({
                                    inputValue: params.inputValue,
                                    company_name: `Hinzufügen: "${params.inputValue}"`,
                                });
                            }

                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        freeSolo
                        options={optionList}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // null option
                            if (!option) {
                                return "";
                            }
                            // Regular option
                            return option.company_name;
                        }}
                        renderOption={(option) => option.company_name}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                        )}
                        getOptionSelected={(option, value) => option.company_name === value.company_name}
                    />
                </Col>
            )
        } else {
            return (
                <Col lg={field.colLg} md={field.colMd} sm={field.colSm} key={field.id} className={classes.col}>
                    <div className={classes.label}>{field.label}{field.required === true ? "*" : ""}</div>
                    <FormControl className={classes.field} variant="outlined">
                        <OutlinedInput
                            id={field.id}
                            type={field.type}
                            value={values[field.id]}
                            onChange={handleChange(field.id)}
                            required={field.required}
                            inputProps={{
                                "aria-label": field.label
                            }}
                        />
                    </FormControl>
                    {isError[field.id] && <FormHelperText id={"error-text-" + field.id} className={classes.error}>{isError[field.id]}</FormHelperText>}
                </Col>
            )
        }

    });


    return (
        <div>
            <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Container className={classes.container}>
                    <Row>
                        {formFields}
                    </Row>
                </Container>
                <div className={classes.required}>* Pflichtfeld</div>
                <FormButton
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    endIcon={<SaveSharpIcon />}
                    type="submit"
                >speichern</FormButton>
            </form>
            <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <DuplicateDoctorDialog
                isOpen={isDuplicateOpen}
                handleClose={handleClose}
                handleCloseOnSubmit={handleCloseOnSubmit}
                physicians={duplicatePhysicians}
                forceCreation={forceCreation}
            />
        </div>
    );
}

CreateForm.propTypes = {
    callBack: PropTypes.func.isRequired,
};


export default CreateForm;