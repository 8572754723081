import React, { useState, useEffect } from "react";
/* import Button from "@material-ui/core/Button"; */
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TableContainer from '@material-ui/core/TableContainer';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from "@material-ui/core/Typography";
import FavoriteBorderSharpIcon from '@material-ui/icons/FavoriteBorderSharp';
import FavoriteSharpIcon from '@material-ui/icons/FavoriteSharp';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import { TableRow } from "@material-ui/core";

import http from "../../../utils/http-common";
import "../deleteDialog/DeleteDialog.css";
import { SECONDARY, WHITE, WHITE_TRANSPARENT, PRIMARY_LIGHT, PRIMARY_DARK } from "../../../styles/colors";
import { StyledButton, SearchBar, NormalTableCell, StyledTableRow, TablePaginationActions } from "../../";
import { getAuthUser } from "../../../utils/authUser";

const useStyles = makeStyles(() => ({
    paper: {
        backgroundColor: SECONDARY,
        color: WHITE,
        borderRadius: 0,
    },
    content: {
        color: WHITE,
    },
    tableToolbar: {
        padding: "0",
        minHeight: "40px",
        marginTop: "20px",
        marginBottom: "10px"
    },
    title: {
        flex: "1 1 100%",
        textTransform: "uppercase",
        letterSpacing: "1px",
    },
    root: {
        "&:hover": {
            color: WHITE + "8a!important",
        },
        "&:focus": {
            color: WHITE + "de!important",
        },
    },
    active: {
        color: WHITE + "de!important",
    },
    icon: {
        color: WHITE + "8a!important",
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    pagination: {
        color: WHITE_TRANSPARENT,
        border: 0,
        backgroundColor: "#1a617f",
    },
    selectIcon: {
        color: WHITE_TRANSPARENT,
    },
    actions: {
        padding: "12px 24px",
    },
    iconButtonRoot: {
        '&.Mui-disabled': {
            color: WHITE,
        },
        color: WHITE,
        padding: 0,
        fontSize: "18px",
    },
}));

const StyledCheckbox = withStyles({
    root: {
        color: WHITE_TRANSPARENT,
        "&$checked": {
            color: PRIMARY_LIGHT,
        },
        "&$disabled": {
            color: PRIMARY_DARK,
        }
    },
    checked: {},
    disabled: {},
})((props) => <Checkbox color="default" {...props} />);

const headCells = [
    { id: "fav", label: <FavoriteBorderSharpIcon />, filterable: true  },
    { id: "physicians.id", label: "ID", filterable: true },
    { id: "physicians.last_name", label: "Name", filterable: true},
    { id: "city", label: "Ort", filterable: false },
    { id: "companies.company_name", label: "Klinik / Praxis", filterable: true},
    { id: "physicians.email", label: "E-Mail", filterable: false},
];


export const AddDoctorToEventDialog = ({ isOpen, id, name, handleClose, handleCloseOnSubmit, callback }) => {
    const classes = useStyles();
    const [searchStr, setSearchStr] = useState("");
    const [order, setOrder] = useState("ASC");
    const [orderBy, setOrderBy] = useState("physicians.id");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalEntries, setTotalEntries] = useState(0);
    const [doctors, setDoctors] = useState([]);
    const [selected, setSelected] = useState([]);
    const user = getAuthUser();
    const [role, setRole] = useState("external");

    useEffect(() => {
        if (user) {
            setRole(user.roles[0]);
        }
    }, [user])

    function handleConfirm() {
        handleCloseOnSubmit();
    }

    const search = async (str) => {
        setSearchStr(str);
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const sorting = (order, orderBy) => {
        console.log(orderBy)
        setOrder(order.toUpperCase());
        setOrderBy(orderBy);
        setPage(0);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'ASC';
        setOrder(isAsc ? 'DESC' : 'ASC');
        setOrderBy(property);
        sorting(isAsc ? 'DESC' : 'ASC', property);
    };

    const getName = (lastname, title, firstname) => {
        let name = lastname;
        if (title !== null || firstname !== "") {
            name += (", ");
            if (title !== null) {
                name += title + " ";
            }
            if (firstname !== "") {
                name += firstname;
            }
        }
        return name
    }

    const checkDisabled = (state) => {
        if (state === null || state === "open") {
            return false;
        } else {
            if (role && role === "external") {
                return true;
            } else {
                return false;
            }
        }
    }

    const selectAllPhysicians = (event) => {
        if (event.target.checked) {
            const newSelecteds = doctors.map((doctor) => doctor.id);

            doctors.forEach((doctor) => {
                if (selected.includes(doctor.id)) {
                    return
                } else {
                    setSelected(newSelecteds);
                    attachPhysician(doctor.id)
                }
            })
        } else {
            doctors.forEach((doctor) => {
                detachPhysician(doctor.id)
            })
            setSelected([]);
        }
    }

    const selectPhysician = (event, doctorId) => {
        const selectedIndex = selected.indexOf(doctorId);
        let newSelected = [];

        // Nicht in Selected enthalten -> hinzufügen
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, doctorId);
            attachPhysician(doctorId);
        }
        // In Selected enthalten -> entfernen
        else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
            detachPhysician(doctorId);
        }
        //???
        else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
            detachPhysician(doctorId);
        }
        // In Selected enthalten -> entfernen
        else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
            detachPhysician(doctorId);
        }
        setSelected(newSelected);

    }

    const attachPhysician = async (doctorId) => {
        await http
            .post('/physician/attach/event', { physician_id: doctorId, event_id: id })
            .then(function (response) {

            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const detachPhysician = async (doctorId) => {
        await http
            .post('/physician/detach/event', { physician_id: doctorId, event_id: id })
            .then(function (response) {

            })
            .catch(function (error) {
                console.log(error)
            });
    }

    useEffect(() => {
        let initialCheckedList = []
        const fetchDoctors = async () => {
            await http
                .get(`/physicians/${page + 1}/${rowsPerPage}`, {
                    params: { search: searchStr, sort: orderBy, sort_dir: order, event_detail: id },
                })
                .then(function (response) {
                    setTotalEntries(response.data.physicians.total);
                    setDoctors(response.data.physicians.data);
                    //console.log(response.data.physicians.data)
                    response.data.physicians.data.forEach((physician) => {
                        if (physician.state !== null) {
                            initialCheckedList.push(physician.id);
                        }
                    })
                    setSelected(initialCheckedList);
                })
                .catch(function (error) {
                    console.log(error);
                })
        }
        if (isOpen === true) {
            fetchDoctors();
        }
    }, [searchStr, page, rowsPerPage, order, orderBy, id, isOpen])


    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: classes.paper }}
            fullWidth={true}
            maxWidth="md"
            disableScrollLock={true}
        >
            <DialogTitle id="alert-dialog-title">{"Ärzte hinzufügen"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" classes={{ root: classes.content }}>
                    für <em><b>{name}</b></em>
                </DialogContentText>

                {/* Insert Table here */}

                <Toolbar className={classes.tableToolbar}>
                    <Typography
                        className={classes.title}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        {selected.length > 0 ? selected.length + " ausgewählt" : "Ärzte"}
                    </Typography>
                    <SearchBar callBack={search} />
                </Toolbar>

                <TableContainer >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <NormalTableCell padding="checkbox">
                                    <StyledCheckbox onChange={(event) => selectAllPhysicians(event)} />
                                </NormalTableCell>
                                {headCells.map((headCell) => (
                                    headCell.filterable ?
                                        <NormalTableCell
                                            key={headCell.id}
                                            sortDirection={orderBy === headCell.id ? order.toLowerCase() : false}
                                            style={{ width: headCell.width }}
                                        >
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order.toLowerCase() : 'asc'}
                                                onClick={createSortHandler(headCell.id)}
                                                classes={{ active: classes.active, icon: classes.icon, root: classes.root }}
                                            >
                                                {headCell.label}
                                                {orderBy === headCell.id ? (
                                                    <span className={classes.visuallyHidden}>
                                                        {order === 'DESC' ? 'absteigend sortiert' : 'aufsteigend sortiert'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        </NormalTableCell> : <NormalTableCell
                                            key={headCell.id}
                                            style={{ width: headCell.width }}
                                        >{headCell.label}</NormalTableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {doctors.map((doctor) => (
                                <StyledTableRow key={doctor.id} >
                                    <TableCell component="th" scope="row" padding="checkbox" style={{ borderBottom: "none" }}>
                                        <StyledCheckbox
                                            checked={selected.includes(doctor.id) ? true : false}
                                            onChange={(event) => selectPhysician(event, doctor.id)}
                                            disabled={checkDisabled(doctor.state)}
                                        />
                                    </TableCell>
                                    <NormalTableCell component="th" scope="row">
                                        <IconButton aria-label="Arzt favorisieren" component="span" classes={{ root: classes.iconButtonRoot, disabled: classes.iconButtonRoot }} disabled>
                                            {doctor.fav === 1 ? <FavoriteSharpIcon /> : <FavoriteBorderSharpIcon />}
                                        </IconButton>
                                    </NormalTableCell>
                                    <NormalTableCell>
                                        {doctor.id}
                                    </NormalTableCell>
                                    <NormalTableCell>
                                        {getName(doctor.last_name, doctor.academic_degree, doctor.first_name)}
                                    </NormalTableCell>
                                    <NormalTableCell>
                                        {doctor.city}
                                    </NormalTableCell>
                                    <NormalTableCell>
                                        {doctor.company_name}
                                    </NormalTableCell>
                                    <NormalTableCell>
                                        {doctor.email}
                                    </NormalTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>

                        <TableFooter>
                            <StyledTableRow>
                                <TablePagination
                                    className={classes.pagination}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    colSpan={headCells.length + 1}
                                    count={totalEntries}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { "aria-label": "Einträge pro Seite" },
                                        classes: { icon: classes.selectIcon },
                                        MenuProps: {disableScrollLock: true},
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </StyledTableRow>
                        </TableFooter>
                    </Table>

                </TableContainer>
            </DialogContent>

            <DialogActions classes={{ root: classes.actions }}>
                {/* <Button
                    onClick={handleClose}
                    color="secondary"
                    className="secondaryBtn"
                >
                    Abbrechen
        </Button> */}
                <StyledButton
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    /* endIcon={<SaveSharpIcon />} */
                    onClick={handleConfirm}
                    autoFocus
                >schließen</StyledButton>
            </DialogActions>
        </Dialog>
    );
};

AddDoctorToEventDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleCloseOnSubmit: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    callback: PropTypes.func,
};

export default AddDoctorToEventDialog;
