import React, { useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import { AuthContext } from "./context/auth";
import { Header, HeaderLogin } from "./components";
import {
  Login,
  Register,
  Doctor,
  DoctorNew,
  DoctorDetail,
  DoctorEdit,
  Events,
  EventDetail,
  EventNew,
  EventEdit,
  /* Overview, */
  Salesmen,
  Users,
  Settings,
  User,
  UserEdit,
  UserDetail,
  UserNew,
  ProfileEdit,
  Error401,
  Error404,
  Error500,
  Log,
  Backup
} from "./pages";
import http from "./utils/http-common";
import { getAuthToken, setAuthToken } from "./utils/authToken";
import { setAuthUser } from "./utils/authUser";

function App(props) {
  const existingTokens = getAuthToken();
  const loggedIn = JSON.parse(localStorage.getItem("login"));
  const [authTokens, setAuthTokens] = useState(existingTokens);


  const setTokens = (data) => {
    setAuthTokens(data.token);
    setAuthToken(data.token);
  };

  const SetHeader = (props) => {
    const isLoggedIn = props.isLoggedIn;
    if (isLoggedIn) {
      return <Header useLogout={logout} />;
    }
    return <HeaderLogin />;
  };

  function logout() {
    http
      .get("/logout").then(result => {
        if (result.status === 200) {
          localStorage.removeItem("login");
          setAuthTokens(null);
          setAuthToken(null);
          setAuthUser(null)
        } else {
          console.error(result.status + result.statusText);
        }
      }).catch(e => {
        console.error(e);
      });
  }


  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <Router>
        <SetHeader isLoggedIn={loggedIn} />

        <div className="content">
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            {/* <PrivateRoute path="/overview" component={Overview} /> */}
            <PrivateRoute exact path="/doctor" component={Doctor} />
            <PrivateRoute path="/doctor/:id" component={DoctorDetail} />
            <PrivateRoute path="/doctor_new" component={DoctorNew} />
            <PrivateRoute path="/doctor_edit/:id" component={DoctorEdit} />
            <PrivateRoute exact path="/event" component={Events} />
            <PrivateRoute path="/event_new" component={EventNew} roles={["internal", "admin"]} />
            <PrivateRoute path="/event/:id" component={EventDetail} />
            <PrivateRoute path="/event_edit/:id" component={EventEdit} roles={["internal", "admin"]} />
            <PrivateRoute path="/salesmen" component={Salesmen} />
            <PrivateRoute path="/users" component={Users} />
            <PrivateRoute path="/user_edit/:id" component={UserEdit} roles={["internal", "admin"]} />
            <PrivateRoute path="/user/:id" component={UserDetail} />
            <PrivateRoute path="/user_new" component={UserNew} roles={["internal", "admin"]} />
            <PrivateRoute path="/settings" component={Settings} roles={["internal", "admin"]} />
            <PrivateRoute path="/log" component={Log} roles={["admin"]} />
            <PrivateRoute path="/backup" component={Backup} roles={["admin"]} />
            <PrivateRoute exact path="/user" component={User} />
            <PrivateRoute path="/profile_edit" component={ProfileEdit} />
            <Redirect exact from="/" to="/event" />
            <PrivateRoute path="/error401" component={Error401} />
            <PrivateRoute path="/error500" component={Error500} />
            <PrivateRoute path="*" component={Error404} />
          </Switch>
        </div>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
