import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { makeStyles, fade } from "@material-ui/core/styles";
import SaveSharpIcon from "@material-ui/icons/SaveSharp";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Container, Col, Row } from "react-bootstrap";
import FormData from "form-data";
import imageCompression from "browser-image-compression";

import http from "../../../utils/http-common";
import "../deleteDialog/DeleteDialog.css";
import { SECONDARY, WHITE, SECONDARY_LIGHT } from "../../../styles/colors";
import { StyledButton, TwitterPicker } from "../..";

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: SECONDARY,
    color: WHITE,
    borderRadius: 0,
  },
  content: {
    color: WHITE,
  },
  field: {
    width: "100%",
    backgroundColor: SECONDARY_LIGHT + "!important",
    "&:-webkit-autofill": {
      webkitBoxSha: "0 0 0 30px white inset !important",
    },
  },
  label: {
    textTransform: "none",
    marginTop: "8px",
    fontSize: "13px",
  },
  multiline: {
    borderRadius: 0,
    color: WHITE,
  },
  error: {
    color: "#F26C51",
  },
  actions: {
    padding: "12px 24px",
  },
  select: {
    padding: "10.5px 14px",
    backgroundColor: SECONDARY_LIGHT + "!important",
    "&:hover": {
      backgroundColor: fade(WHITE, 0.25) + "!important",
    },
    color: WHITE,
    width: "100%",
    fontSize: "16px",
  },
  selectIcon: {
    color: "#ffffff80!important",
  },
  required: {
    fontSize: "13px",
    marginTop: "16px",
    color: "#fffc !important",
  },
  imagePicker: {
    width: "100%",
    backgroundColor: SECONDARY_LIGHT + "!important",
    "&:-webkit-autofill": {
      webkitBoxSha: "0 0 0 30px white inset !important",
    },
    "&:hover": {
      backgroundColor: fade(WHITE, 0.25) + "!important",
      cursor: "pointer",
    },
  },
}));

export const EditDialog = ({
  isOpen,
  handleClose,
  handleCloseOnSubmit,
  form,
  type,
  callback,
  item,
}) => {
  const classes = useStyles();
  const [isError, setError] = useState({});
  const [values, setValues] = useState({});
  const imageUploader = React.useRef(null);
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [color, setColor] = useState("#1a617f");

  useEffect(() => {
    const fetchSponsor = async () => {
      await http
        .get("/sponsor/" + item.id)
        .then(function (response) {
          if (response.data.result === "Token is Expired") {
          } else {
            //setUser(response.data.user);
            //console.log(response.data.sponsor)
            setImagePreview(response.data.sponsor.baseImage);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    if (type === "sponsor" && item && item.id) {
      fetchSponsor();
    }
  }, [item, type]);

  useEffect(() => {
    const formValues = {};
    formValues.id = item.id;
    form.forEach((field) => {
      formValues[field.id] = item[field.id] ?? "";
    });
    setValues(formValues);
    setColor(formValues.color);
  }, [item, form]);


  // Color Picker
  const changeColor = (color, event, fieldId) => {
    setColor(color.hex);
    setValues({ ...values, [fieldId]: color.hex });
    setError({ ...isError, [fieldId]: undefined });
  }

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      var filesize = (e.target.files[0].size / 1024 / 1024).toFixed(4); // MB
      reader.onload = (e) => {
        if (filesize > 2) {
          setError({
            ...isError,
            image:
              "Maximale Dateigröße von 2MB überschritten. Bitte wählen Sie ein kleineres Bild.",
          });
          setValues({ ...values, image: "" });
          setImage("");
          setImagePreview("");
        } else {
          //setValues({ ...values, image: e.target.result });
          /* setImage(file);
          setImagePreview(e.target.result);
          setError({ ...isError, image: undefined }); */
          const options = {
            maxSizeMB: 0.1,
            useWebWorker: true,
          };
          imageCompression(file, options)
            .then((compressedBlob) => {
              const convertedBlobFile = new File([compressedBlob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });
              setImage(convertedBlobFile);
              setImagePreview(e.target.result);
              setError({ ...isError, image: undefined });
            })
            .catch((e) => {
              console.log(e);
            })
        }
      };
      reader.onerror = (e) => {
        setError({ ...isError, image: e.target.error });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (fieldId) => (event) => {
    setValues({ ...values, [fieldId]: event.target.value });
    setError({ ...isError, [fieldId]: undefined });
    form.forEach((field) => {
      if (fieldId === field.id && event.target.value.length > field.max) {
        setError({
          ...isError,
          [fieldId]:
            "Die maximale Länge dieses Feldes entspricht " +
            field.max +
            " Zeichen.",
        });
      }
    });
  };

  const closeDialog = () => {
    setValues({});
    setError({});
    setImage("");
    handleClose();
  };

  const handleOnBlur = (fieldId) => (event) => {
    setError({ ...isError, [fieldId]: undefined });
    form.forEach((field) => {
      if (
        fieldId === field.id &&
        !document.getElementById(fieldId).validity.valid
      ) {
        if (field.required === true) {
          setError({
            ...isError,
            [fieldId]: "Füllen Sie bitte dieses Feld aus.",
          });
        }
        if (field.inputType === "email") {
          setError({ ...isError, [fieldId]: "Ungültige Emailadresse." });
        }
        if (field.inputType === "url") {
          setError({
            ...isError,
            [fieldId]:
              "Ungültige URL. Bitte benutzen Sie folgendes Schema: 'urlschema://restderurl'",
          });
        }
        if (field.inputType === "tel") {
          setError({ ...isError, [fieldId]: "Ungültige Nummer." });
        }
      }
    });
  };

  const handleConfirm = async (event) => {
    event.preventDefault();

    let errorList = {};
    for (const [key, value] of Object.entries(isError)) {
      if (value !== undefined) {
        errorList[key] = value;
      }
    }

    if (Object.keys(errorList).length === 0) {
      if (type === "sponsor") {
        let data = new FormData();
        if (image && image !== "") {
          data.append("image", image);
        }
        data.append("name", values.name);
        data.append("website", values.website);
        data.append("id", item.id);
        /* for (var value of data.values()) {
          console.log(value);
        } */
        http
          .post("/" + type + "/update", data, {
            headers: {
              "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
            },
          })
          .then((result) => {
            if (result.status === 200 || result.status === 201) {
              callback(["success", "Erfolgreich bearbeitet."]);
            } else {
              console.log(
                "Status: " + result.status + ", " + result.statusText
              );
              callback(["error", "Fehler beim Bearbeiten."]);
            }
            setImage("");
            setValues({});
          })
          .catch(function (error) {
            console.log(error);
            callback(["error", "Fehler beim Bearbeiten."]);
          })
          .finally(function () {
            setValues({});
            setError({});
            handleCloseOnSubmit(type);
          });
      } else {
        http
          .put("/" + type + "/update", values)
          .then((result) => {
            if (result.status === 200 || result.status === 201) {
              callback(["success", "Erfolgreich bearbeitet."]);
            } else {
              console.log(
                "Status: " + result.status + ", " + result.statusText
              );
              callback(["error", "Fehler beim Bearbeiten."]);
            }
          })
          .catch(function (error) {
            console.log(error);
            callback(["error", "Fehler beim Bearbeiten."]);
          })
          .finally(function () {
            setValues({});
            setError({});
            handleCloseOnSubmit(type);
          });
      }
    }
  };

  const formFields = form.map((field) => {
    if (field.type === "text") {
      return (
        <Col key={field.id} lg={field.colLg} md={field.colMd} sm={field.colSm}>
          <div className={classes.label}>
            {field.label}
            {field.required === true ? "*" : ""}
          </div>
          <OutlinedInput
            id={field.id}
            type={field.inputType}
            required={field.required}
            margin="dense"
            size="small"
            className={classes.field}
            classes={{ input: classes.field }}
            onChange={handleChange(field.id)}
            onBlur={handleOnBlur(field.id)}
            autoComplete="off"
            defaultValue={item[field.id] ?? ""}
            inputProps={{
              "aria-label": field.label
            }}
          />
          {isError[field.id] && (
            <FormHelperText
              id={"error-text-" + field.id}
              className={classes.error}
            >
              {isError[field.id]}
            </FormHelperText>
          )}
        </Col>
      );
    }
    if (field.type === "textarea") {
      return (
        <Col key={field.id} lg={field.colLg} md={field.colMd} sm={field.colSm}>
          <div className={classes.label}>
            {field.label}
            {field.required === true ? "*" : ""}
          </div>
          <OutlinedInput
            id={field.id}
            type={field.type}
            required={field.required}
            margin="dense"
            size="small"
            className={classes.field}
            classes={{ input: classes.field, multiline: classes.multiline }}
            multiline
            rows={3}
            onChange={handleChange(field.id)}
            onBlur={handleOnBlur(field.id)}
            autoComplete="off"
            defaultValue={item[field.id] ?? ""}
            inputProps={{
              "aria-label": field.label
            }}
          />
        </Col>
      );
    }
    if (field.type === "select") {
      return (
        <Col key={field.id} lg={field.colLg} md={field.colMd} sm={field.colSm}>
          <FormControl style={{ width: "100%" }}>
            <div className={classes.label}>
              {field.label}
              {field.required === true ? "*" : ""}
            </div>
            <Select
              disableUnderline={true}
              value={values[field.id] ? values[field.id] : ""}
              onChange={handleChange(field.id)}
              className={classes.field}
              classes={{ root: classes.select, icon: classes.selectIcon }}
              MenuProps={{
                disableScrollLock: true,
              }}
            >
              <MenuItem value="">
                <em>Kein Typ</em>
              </MenuItem>
              {field.options.map((option) => {
                return (
                  <MenuItem
                    key={option.value}
                    className={classes.selectValues}
                    value={option.value}
                  >
                    {option.text}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Col>
      );
    }
    if (field.type === "image") {
      return (
        <Col key={field.id} lg={field.colLg} md={field.colMd} sm={field.colSm}>
          <div className={classes.label}>
            {field.label}
            {field.required === true ? "*" : ""}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <input
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              onChange={handleImageUpload}
              ref={imageUploader}
              style={{
                display: "none",
              }}
            />
            <div
              className={classes.imagePicker}
              style={{
                height: "100px",
                padding: "10.5px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() => imageUploader.current.click()}
            >
              {imagePreview && imagePreview !== "" ? (
                <img
                  src={imagePreview}
                  style={{
                    width: "auto",
                    height: "100%",
                  }}
                  alt=""
                />
              ) : (
                  <p style={{ fontSize: "14px", margin: "auto" }}>
                    Bild auswählen <br />
                  Maximale Dateigröße: 2MB, erlaubte Dateiendungen: png, jpg,
                  jpeg
                  </p>
                )}
            </div>
          </div>
          {isError[field.id] ? (
            <FormHelperText
              id={"error-text-" + field.id}
              className={classes.error}
            >
              {isError[field.id]}
            </FormHelperText>
          ) : (
              ""
            )}
        </Col>
      );
    } if (field.type === "color") {
      return (
        <Col key={field.id} lg={field.colLg} md={field.colMd} sm={field.colSm}>
          <div className={classes.label}>{field.label}{field.required === true ? "*" : ""}</div>
          <TwitterPicker
            onChangeComplete={(color, event) => changeColor(color, event, field.id)}
            color={color}
            triangle="hide"
            width="100%"
            hexColor={color}
          />
        </Col >
      )
    } else {
      return "Input-Type not defined";
    }
  });

  let textValueDescription;
  let name;
  if (type === "category") {
    textValueDescription = "die Kategorie";
    name = item.category_name;
  }
  if (type === "topic") {
    textValueDescription = "das Veranstaltungs-Thema";
    name = item.topic_name;
  }
  if (type === "organizer") {
    textValueDescription = "den Veranstalter";
    name = item.organizer_name;
  }
  if (type === "sponsor") {
    textValueDescription = "den Sponsor";
    name = item.name;
  }
  if (type === "company") {
    textValueDescription = "die Klinik / Praxis";
    name = item.company_name;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.paper }}
      disableScrollLock={true}
    >
      <DialogTitle id="alert-dialog-title">"{name}" bearbeiten</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          classes={{ root: classes.content }}
        >
          Um {textValueDescription} "{name}" zu bearbeiten, ändern Sie die Werte
          in den nachfolgenden Feldern.
        </DialogContentText>
        <form>
          <Container style={{ padding: 0 }}>
            <Row>{formFields}</Row>
          </Container>
        </form>
        <div className={classes.required}>* Pflichtfeld</div>
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <Button
          onClick={closeDialog}
          color="secondary"
          className="secondaryBtn"
        >
          Abbrechen
        </Button>
        <StyledButton
          variant="contained"
          color="primary"
          className={classes.button}
          endIcon={<SaveSharpIcon />}
          onClick={handleConfirm}
          autoFocus
          type="submit"
        >
          speichern
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

EditDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCloseOnSubmit: PropTypes.func.isRequired,
  form: PropTypes.array.isRequired,
  callback: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
};

export default EditDialog;
