import React, {useState} from "react";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import PropTypes from "prop-types";


const Alert = (props) => {
    const [snackbarOpen, setSnackbarOpen] = useState(true);
    props.callBack(false);

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);

        if (props.type === "success"){
            props.callBack(true);
        }

    };

    return (
        <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <MuiAlert
                elevation={0}
                variant="filled"
                severity={props.type}
                onClose={handleSnackbarClose}
            >
                {props.message}
            </MuiAlert>
        </Snackbar>
        )

}

Alert.propTypes = {
    callBack: PropTypes.func.isRequired,
};

export default Alert;