import React from 'react';
import { useHistory } from "react-router-dom";

import ProfilePic from "../../img/defaul_user.jpg"
import Role from "../role/Role";
import { makeStyles } from "@material-ui/core/styles";
import { getAuthUser } from "../../utils/authUser";

const useStyles = makeStyles(() => ({
    image: {
        width: "85px",
        height: "85px",
        float: "right",
        objectFit: "cover",
    },
    floating: {
        float: "right",
        height: "105px",
        color: '#fff!important',
        '&:hover': {
            color: '#fff!important'
        },
        cursor: "pointer"
    },
    textBlock: {
        float: "left",
        marginRight: "8px",
        textAlign: "right",
        position: "realtive",
    },
    signedInAs: {
        fontSize: "12px",
    },
    name: {
        fontSize: "20px",
        fontWeight: 400,
    },
    bottomFloat: {
        marginTop: "10px"
    },
    userHeader: {
        marginBottom: "2px",
        cursor: "pointer"
    },
    userIcon: {
        width: "25px",
        marginRight: "5px",
    },
    userName: {
        fontSize: "12px",
        paddingRight: "8px",
    }
}));



const Profile = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const user = getAuthUser();


    if (props.viewPort === "mobile") {
        return (
            <div className={classes.userHeader}>
                <span onClick={() => { history.push("/user") }} className={classes.userName}>angemeldet als: {user && user.first_name} {user && user.name}</span>
                <span>|</span>
            </div>
        )
    }


    return (
        <div className={classes.floating} onClick={() => { history.push("/user") }}>
            <div className={classes.textBlock}>
                <div>
                    <span className={classes.signedInAs}>angemeldet als:</span>
                </div>
                <div className={classes.bottomFloat}>
                    <span className={classes.name}>{user && user.first_name} {user && user.name}</span><br />
                    <Role role={user.roles} />
                </div>
            </div>
            <img className={classes.image} width="85px" height="85px" src={user.baseImage ? user.baseImage : ProfilePic} alt="Profilbild" />
        </div>
    );
}

export default Profile;