import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, fade } from "@material-ui/core/styles";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import { Container, Col, Row } from "react-bootstrap";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import SaveSharpIcon from "@material-ui/icons/SaveSharp";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import deLocale from "date-fns/locale/de";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from "@material-ui/icons/AddSharp";
import DeleteForeverSharpIcon from "@material-ui/icons/DeleteForeverSharp";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import {
  TopContent,
  StyledButton,
  StyledTabs,
  StyledTab,
  TabPanel,
  TableIconButton,
  StyledTooltip,
  CKEditorComponent,
} from "../../components";
import {
  SECONDARY,
  SECONDARY_LIGHT,
  WHITE,
  PRIMARY_LIGHT,
} from "../../styles/colors";
import { generalDataForm, addressDataForm } from "./eventData";
import http from "../../utils/http-common";
import { isBefore, format, isSameDay, isDate, isValid} from "date-fns";

export const itemDataForm = {
  id: "",
  item_description: "",
  date_from: "",
  date_to: "",
  time_from: "",
  time_to: "",
};

const eventValues = {
  event_name: "",
  date_from: "",
  date_to: "",
  time_from: "",
  time_to: "",
  description: "",
  participant_limit: "",
  costs: "",
  published: "",
  published_from: "",
  published_to: "",
  items: [{}],
  addresses: [
    {
      address_name: "",
      street: "",
      addition: "",
      post_code: "",
      city: "",
      country: "",
      tel: "",
      fax: "",
      email: "",
    },
  ],
  category_id: "",
  topic_id: "",
  organizer_id: "",
  speakers: [{}],
  sponsors: [{}],
};

const abbottTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: SECONDARY,
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: PRIMARY_LIGHT + "!important",
      },
      current: {
        color: PRIMARY_LIGHT,
      },
    },
    MuiButton: {
      root: {
        color: SECONDARY + "!important",
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: PRIMARY_LIGHT + "!important",
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: PRIMARY_LIGHT + "!important",
      },
      thumb: {
        backgroundColor: PRIMARY_LIGHT + "!important",
        border: "14px solid " + PRIMARY_LIGHT + "!important",
      },
    },
  },
});

const useStyles = makeStyles(() => ({
  darkerSection: {
    backgroundColor: SECONDARY,
  },
  label: {
    textTransform: "none",
    marginTop: "8px",
    fontSize: "13px",
  },
  field: {
    width: "100%",
    backgroundColor: SECONDARY_LIGHT + "!important",
    "&:-webkit-autofill": {
      webkitBoxSha: "0 0 0 30px white inset !important",
    },
  },
  multiline: {
    borderRadius: 0,
    color: WHITE,
  },
  select: {
    padding: "10.5px 14px",
    backgroundColor: SECONDARY_LIGHT + "!important",
    "&:hover": {
      backgroundColor: fade(WHITE, 0.25) + "!important",
    },
    color: WHITE,
    width: "100%",
    fontSize: "16px",
  },
  selectIcon: {
    color: "#ffffff80!important",
  },
  required: {
    fontSize: "13px",
    marginTop: "16px",
    color: "#fffc !important",
  },
  datePickerInput: {
    width: "100%",
    backgroundColor: SECONDARY_LIGHT + "!important",
    "&:-webkit-autofill": {
      webkitBoxSha: "0 0 0 30px white inset !important",
    },
    padding: "10.5px 14px",
  },
  icon: {
    color: "#ffffff80!important",
  },
  error: {
    color: "#F26C51",
  },
  listButtonMargin: {
    marginTop: "19px!important",
  },
}));

function a11yProps(index) {
  return {
    id: `event-tab-${index}`,
    "aria-controls": `event-tabpanel-${index}`,
  };
}

const EventNew = () => {
  //console.log(eventValues)
  const history = useHistory();
  const classes = useStyles();
  const [tab, setTab] = React.useState(0);
  const [isError, setError] = useState({});
  const [categories, setCategories] = useState([]);
  const [topics, setTopics] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [organizers, setOrganizers] = useState([]);
  const [values, setValues] = useState(eventValues);
  const [inputList, setInputList] = useState([
    { date_from: "", time_from: "", item_description: "" },
  ]);
  const [referentInputList, setReferentInputList] = useState([
    { speaker: "", company_name: "", company_id: "" },
  ]);
  const filter = createFilterOptions();
  const [sponsorInputList, setSponsorInputList] = useState([{ id: "" }]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState([]);
  const [autocompleteValue, setAutocompleteValue] = useState(organizers[0]);

  // Change Tab
  const handleChangeTab = (event, newTab) => {
    setTab(newTab);
  };

  // Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);

    if (successMessage[0] === "success") {
      history.goBack();
    }
  };

  // Input Fields of normal forms
  const handleChange = (fieldId) => (event) => {
    if (fieldId === "participant_limit") {
      setValues({ ...values, [fieldId]: parseInt(event.target.value) });
    } else {
      setValues({ ...values, [fieldId]: event.target.value });
    }

    setError({ ...isError, [fieldId]: undefined });

    generalDataForm.forEach((field) => {
      if (fieldId === field.id && event.target.value.length > field.max) {
        setError({
          ...isError,
          [fieldId]:
            "Die maximale Länge dieses Feldes entspricht " +
            field.max +
            " Zeichen.",
        });
      }
    });
  };

  const handleAddressChange = (fieldId) => (event) => {
    let temp = [...values.addresses];
    temp[0][fieldId] = event.target.value;

    setValues({ ...values, addresses: temp });
    setError({ ...isError, [fieldId]: undefined });

    addressDataForm.forEach((field) => {
      if (fieldId === field.id && event.target.value.length > field.max) {
        setError({
          ...isError,
          [fieldId]:
            "Die maximale Länge dieses Feldes entspricht " +
            field.max +
            " Zeichen.",
        });
      }
    });
  };

  const handleDateChange = (date, fieldId) => {
    let array = fieldId.split("_");

    if (date !== null) {
      if (validateDateFormat(date, fieldId)) {
        if (array[1] === "from") {
          if (values[array[0] + "_to"] === "") {
            setValues({
              ...values,
              [fieldId]: format(date, "yyyy-MM-dd"),
              [array[0] + "_to"]: format(date, "yyyy-MM-dd"),
            });
            setError({ ...isError, [fieldId]: undefined });
          } else {
            compareDates(date, new Date(values[array[0] + "_to"]), fieldId);
          }
        }
        if (array[1] === "to") {
          if (values[array[0] + "_from"] === "") {
            setValues({ ...values, [fieldId]: format(date, "yyyy-MM-dd") });
            setError({ ...isError, [fieldId]: undefined });
          } else {
            compareDates(new Date(values[array[0] + "_from"]), date, fieldId);
          }
        }
      }
    } else {
      setError({ ...isError, [fieldId]: undefined });
      setValues({ ...values, [fieldId]: "" });
    }

    //console.log(format(date, 'yyyy-MM-dd' ))
  };

  const compareDates = (date1, date2, fieldId) => {
    let array = fieldId.split("_");

    if (isSameDay(date1, date2) || isBefore(date1, date2)) {
      setError({
        ...isError,
        [array[0] + "_from"]: undefined,
        [array[0] + "_to"]: undefined,
      });
      if (array[1] === "from") {
        setValues({ ...values, [fieldId]: format(date1, "yyyy-MM-dd") });
      }
      if (array[1] === "to") {
        setValues({ ...values, [fieldId]: format(date2, "yyyy-MM-dd") });
      }
    } else {
      if (array[1] === "from") {
        setValues({ ...values, [fieldId]: format(date1, "yyyy-MM-dd") });
      }
      if (array[1] === "to") {
        setValues({ ...values, [fieldId]: format(date2, "yyyy-MM-dd") });
      }
      setError({
        ...isError,
        [fieldId]: "Das Startdatum muss vor dem Enddatum liegen.",
      });
    }
  };

  const validateDateFormat = (date, fieldId) => {
    if (isDate(date)) {
      if (isValid(date)) {
        setError({ ...isError, [fieldId]: undefined });
        return true;
      } else {
        setError({
          ...isError,
          [fieldId]: "Bitte geben Sie ein gültiges Datum ein.",
        });
        return false;
      }
    } else {
      setError({ ...isError, [fieldId]: "Bitte geben Sie ein Datum ein." });
      return false;
    }
  };

  const validateTimeFormat = (date, fieldId) => {
    if (isDate(date)) {
      if (isValid(date)) {
        setError({ ...isError, [fieldId]: undefined });
        return true;
      } else {
        setError({
          ...isError,
          [fieldId]: "Bitte geben Sie eine gültige Zeit ein.",
        });
        return false;
      }
    } else {
      setError({ ...isError, [fieldId]: "Bitte geben Sie eine Zeit ein." });
      return false;
    }
  };

  const handleTimeChange = (date, fieldId) => {
    if (date !== null) {
      if (validateTimeFormat(date, fieldId)) {
        setValues({ ...values, [fieldId]: date });
      }
    } else {
      setError({ ...isError, [fieldId]: undefined });
      setValues({ ...values, [fieldId]: "" });
    }

    // Formatter: date.toTimeString().split(' ')[0]
  };

  const updateDescriptionValue = (val) => {
    setValues({ ...values, "description": val });
  }

  //console.log(values)

  const handleOnBlur = (fieldId) => (event) => {
    setError({ ...isError, [fieldId]: undefined });
    if (tab === 0) {
      generalDataForm.forEach((field) => {
        if (
          fieldId === field.id &&
          !document.getElementById(fieldId).validity.valid
        ) {
          if (field.required === true) {
            setError({
              ...isError,
              [fieldId]: "Füllen Sie bitte dieses Feld aus.",
            });
          }
          if (field.inputType === "email") {
            setError({ ...isError, [fieldId]: "Ungültige Emailadresse." });
          }
          if (field.inputType === "url") {
            setError({
              ...isError,
              [fieldId]:
                "Ungültige URL. Bitte benutzen Sie folgendes Schema: 'urlschema://restderurl'",
            });
          }
          if (field.inputType === "tel") {
            setError({ ...isError, [fieldId]: "Ungültige Nummer." });
          }
        }
      });
    }
    if (tab === 1) {
      addressDataForm.forEach((field) => {
        if (
          fieldId === field.id &&
          !document.getElementById(fieldId).validity.valid
        ) {
          if (field.required === true) {
            setError({
              ...isError,
              [fieldId]: "Füllen Sie bitte dieses Feld aus.",
            });
          }
          if (field.inputType === "email") {
            setError({ ...isError, [fieldId]: "Ungültige Emailadresse." });
          }
          if (field.inputType === "url") {
            setError({
              ...isError,
              [fieldId]:
                "Ungültige URL. Bitte benutzen Sie folgendes Schema: 'urlschema://restderurl'",
            });
          }
          if (field.inputType === "tel") {
            setError({ ...isError, [fieldId]: "Ungültige Nummer." });
          }
        }
      });
    }
  };

  // submit complete form
  const handleConfirm = async (event) => {
    event.preventDefault();

    let errorList = {};
    for (const [key, value] of Object.entries(isError)) {
      if (value !== undefined) {
        errorList[key] = value;
        setSuccessMessage([
          "error",
          "Füllen Sie die Felder bitte richtig aus.",
        ]);
        setSnackbarOpen(true);
      }
    }

    generalDataForm.forEach((field) => {
      if (field.required && values[field.id] === "") {
        errorList[field.id] = "Füllen Sie bitte dieses Feld aus.";
        setSuccessMessage([
          "error",
          "Füllen Sie bitte alle erforderlichen Felder aus.",
        ]);
        setSnackbarOpen(true);
      }
      setError(errorList);
    });

    if (Object.keys(errorList).length === 0) {
      http
        .post("/event/insert", values)
        .then((result) => {
          if (result.status === 200 || result.status === 201) {
            setSuccessMessage(["success", "Erfolgreich erstellt."]);
            setValues(eventValues);
            setInputList([
              { date_from: "", time_from: "", item_description: "" },
            ]);
            setReferentInputList([
              { speaker: "", company_name: "", company_id: "" },
            ]);
            setSponsorInputList([{ id: "" }]);
            setError({});
          } else {
            console.log("Status: " + result.status + ", " + result.statusText);
            setSuccessMessage(["error", "Fehler beim Erstellen."]);
          }
        })
        .catch(function (error) {
          console.log(error);
          setSuccessMessage(["error", "Fehler beim Erstellen."]);
        })
        .finally(function () {
          setSnackbarOpen(true);
        });
    }
  };

  // data fetching for dropdown menus
  useEffect(() => {
    const fetchCategories = async () => {
      await http
        .get("/categories")
        .then(function (response) {
          setCategories(response.data.categories);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchTopics = async () => {
      await http
        .get("/topics")
        .then(function (response) {
          setTopics(response.data.topics);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    fetchTopics();
  }, []);

  useEffect(() => {
    const fetchCompanies = async () => {
      await http
        .get("/companies/autocomplete")
        .then(function (response) {
          setCompanies(response.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    fetchCompanies();
  }, []);

  useEffect(() => {
    const fetchSponsors = async () => {
      await http
        .get("/sponsors/1")
        .then(function (response) {
          setSponsors(response.data.sponsors.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    fetchSponsors();
  }, []);

  useEffect(() => {
    const fetchOrganizers = async () => {
      await http
        .get("/organizers/1/200")
        .then(function (response) {
          setOrganizers(response.data.organizers.original.organizers.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    fetchOrganizers();
  }, []);

  //generate text fields for general data and address
  const generateFormFields = (formFields, type) =>
    formFields.map((field) => {
      if (field.type === "text") {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
          >
            <div className={classes.label}>
              {field.label}
              {field.required === true ? "*" : ""}
            </div>
            
            <OutlinedInput
              id={field.id}
              type={field.inputType}
              required={field.required}
              margin="dense"
              size="small"
              className={classes.field}
              classes={{ input: classes.field }}
              onChange={
                type === "address"
                  ? handleAddressChange(field.id)
                  : handleChange(field.id)
              }
              onBlur={handleOnBlur(field.id)}
              autoComplete="off"
              /* value={values[field.id] || ""} */
              value={
                type === "address"
                  ? values.addresses[0][field.id] || ""
                  : values[field.id] || ""
              }
              inputProps={field.inputType==="number" ? {
                "aria-label": field.label,
                step: "0.01",
              } : {
                "aria-label": field.label,
              }}
            />
            {isError[field.id] ? (
              <FormHelperText
                id={"error-text-" + field.id}
                className={classes.error}
              >
                {isError[field.id]}
              </FormHelperText>
            ) : (
              ""
            )}
          </Col>
        );
      }
      if (field.type === "wysiwyg") {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
          >
            <div className={classes.label}>
              {field.label}
              {field.required === true ? "*" : ""}
            </div>
            <CKEditorComponent returnDescription={updateDescriptionValue} initialValue={""}/>
            
          </Col>
        );
      }
      if (field.type === "autocomplete") {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
          >
            <div className={classes.label}>
              {field.label}
              {field.required === true ? "*" : ""}
            </div>
            <Autocomplete
              id={field.id}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              value={autocompleteValue || null}
              onChange={(event, newValue) => {
                setAutocompleteValue(newValue);
                if (newValue) {
                  setValues({ ...values, [field.id]: newValue.id });
                } else {
                  setValues({ ...values, [field.id]: "" });
                }
              }}
              options={organizers}
              getOptionLabel={(option) => {
                if (!option.organizer_name) {
                  return "";
                }
                return option.organizer_name;
              }}
              className={classes.field}
              classes={{
                input: classes.field,
                popupIndicator: classes.selectIcon,
                clearIndicator: classes.selectIcon,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  margin="dense"
                  style={{ margin: "0px" }}
                />
              )}
            />
            {isError[field.id] ? (
              <FormHelperText
                id={"error-text-" + field.id}
                className={classes.error}
              >
                {isError[field.id]}
              </FormHelperText>
            ) : (
              ""
            )}
          </Col>
        );
      }
      if (field.type === "textarea") {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
          >
            <div className={classes.label}>
              {field.label}
              {field.required === true ? "*" : ""}
            </div>
            <OutlinedInput
              id={field.id}
              type={field.type}
              required={field.required}
              margin="dense"
              size="small"
              className={classes.field}
              classes={{ multiline: classes.multiline }}
              multiline
              rows={5}
              onChange={handleChange(field.id)}
              onBlur={handleOnBlur(field.id)}
              autoComplete="off"
              value={values[field.id] || ""}
              inputProps={{
                "aria-label": field.label
              }}
            />
          </Col>
        );
      }
      if (field.type === "date") {
        if (field.inputType === "date") {
          return (
            <Col
              key={field.id}
              lg={field.colLg}
              md={field.colMd}
              sm={field.colSm}
            >
              <div className={classes.label}>
                {field.label}
                {field.required === true ? "*" : ""}{" "}
              </div>
              <ThemeProvider theme={abbottTheme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                  <KeyboardDatePicker
                    clearable
                    value={Date.parse(values[field.id]) || null}
                    onChange={(date) => handleDateChange(date, field.id)}
                    disablePast
                    format="dd.MM.yyyy"
                    className={classes.field}
                    orientation="portrait"
                    InputProps={{
                      classes: {
                        input: classes.datePickerInput,
                      },
                      disableUnderline: true,
                    }}
                    inputProps={{
                      "aria-label": field.label,
                    }}
                    cancelLabel="Abbrechen"
                    clearLabel="Zurücksetzen"
                    KeyboardButtonProps={{
                      classes: {
                        root: classes.icon,
                      },
                      "aria-label": field.label,
                    }}
                    helperText={null}
                    DialogProps={{
                      disableScrollLock: true,
                    }}
                  />
                </MuiPickersUtilsProvider>
              </ThemeProvider>
              {isError[field.id] ? (
                <FormHelperText
                  id={"error-text-" + field.id}
                  className={classes.error}
                >
                  {isError[field.id]}
                </FormHelperText>
              ) : (
                ""
              )}
            </Col>
          );
        }
        if (field.inputType === "time") {
          return (
            <Col
              key={field.id}
              lg={field.colLg}
              md={field.colMd}
              sm={field.colSm}
            >
              <div className={classes.label}>
                {field.label}
                {field.required === true ? "*" : ""}{" "}
              </div>
              <ThemeProvider theme={abbottTheme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                  <KeyboardTimePicker
                    clearable
                    ampm={false}
                    value={values[field.id] || null}
                    /* value={values[field.id] || null} */

                    onChange={(date) => handleTimeChange(date, field.id)}
                    className={classes.field}
                    classes={{ root: classes.field }}
                    orientation="portrait"
                    minutesStep={5}
                    helperText={null}
                    cancelLabel="Abbrechen"
                    clearLabel="Zurücksetzen"
                    InputProps={{
                      classes: {
                        input: classes.datePickerInput,
                      },
                      disableUnderline: true,
                    }}
                    inputProps={{
                      "aria-label": field.label,
                    }}
                    KeyboardButtonProps={{
                      classes: {
                        root: classes.icon,
                      },
                      "aria-label": field.label,
                    }}
                    DialogProps={{
                      disableScrollLock: true,
                    }}
                  />
                </MuiPickersUtilsProvider>
              </ThemeProvider>
              {isError[field.id] ? (
                <FormHelperText
                  id={"error-text-" + field.id}
                  className={classes.error}
                >
                  {isError[field.id]}
                </FormHelperText>
              ) : (
                ""
              )}
            </Col>
          );
        }
      }
      if (field.type === "select") {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
          >
            <FormControl style={{ width: "100%" }}>
              <div className={classes.label}>
                {field.label}
                {field.required === true ? "*" : ""}
              </div>
              <Select
                disableUnderline={true}
                value={values[field.id]}
                onChange={handleChange(field.id)}
                defaultValue={""}
                className={classes.field}
                classes={{ root: classes.select, icon: classes.selectIcon }}
                MenuProps={{
                  disableScrollLock: true,
                }}
              >
                <MenuItem className={classes.selectValues} value={""}>
                  <em>Keine Angabe</em>
                </MenuItem>
                {field.inputType === "topics" &&
                  topics.map((option) => {
                    return (
                      <MenuItem
                        key={option.id}
                        className={classes.selectValues}
                        value={option.id}
                      >
                        {option.topic_name}
                      </MenuItem>
                    );
                  })}
                {field.inputType === "categories" &&
                  categories.map((option) => {
                    return (
                      <MenuItem
                        key={option.id}
                        className={classes.selectValues}
                        value={option.id}
                      >
                        {option.category_name}
                      </MenuItem>
                    );
                  })}
                {field.inputType === "custom" &&
                  field.options.map((option) => {
                    return (
                      <MenuItem
                        key={option.value}
                        className={classes.selectValues}
                        value={option.value}
                      >
                        {option.text}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            {isError[field.id] ? (
              <FormHelperText
                id={"error-text-" + field.id}
                className={classes.error}
              >
                {isError[field.id]}
              </FormHelperText>
            ) : (
              ""
            )}
          </Col>
        );
      } else {
        return "Input-Type existiert nicht.";
      }
    });

  // Functions for Programm Items
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    //console.log(name, value)
    const list = inputList;
    list[index][name] = value;
    setInputList(list);
    setValues({ ...values, items: list });
  };

  const handleRemoveClick = (index) => {
    const list = inputList;
    list.splice(index, 1);
    setInputList(list);
    setValues({ ...values, items: list });
  };
  //console.log(values)

  const handleAddClick = () => {
    const list = inputList;
    list.push({ date_from: "", time_from: "", item_description: "" });
    //setInputList([...inputList, { date_from: "", time_from: "", item_description: "" }]);
    setInputList(list);
    setValues({ ...values, items: list });
  };

  const handleProgrammDateChange = (date, index, field) => {
    const list = [...inputList];
    if (isDate(date)) {
      if (isValid(date)) {
        list[index][field] = date.toISOString().split("T")[0];
        setInputList(list);
        setValues({ ...values, items: list });
      }
    }
  };

  const handleProgrammTimeChange = (date, index, field) => {
    const list = [...inputList];
    list[index][field] = date;
    setInputList(list);
    setValues({ ...values, items: list });
  };

  // Functions for Referents
  const handleReferentInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = referentInputList;
    list[index][name] = value;
    setReferentInputList(list);
    setValues({ ...values, speakers: list });
  };

  const handleReferentRemoveClick = (index) => {
    const list = referentInputList;
    list.splice(index, 1);
    setReferentInputList(list);
    setValues({ ...values, speakers: list });
  };

  const handleReferentAddClick = () => {
    const list = referentInputList;
    // Notiz: company_id noch hinzufügen???
    list.push({ speaker: "", company_name: "" });
    //setReferentInputList([...referentInputList, { referent_name: "", referent_company: "" }]);
    setReferentInputList(list);
    setValues({ ...values, speakers: list });
  };

  const handleReferentAutocompleteChange = (e, i, newValue) => {
    const list = [...referentInputList];

    if (newValue && newValue.inputValue) {
      //list[i]["referent_company"] = { "id": 0, "company_name": newValue.inputValue }
      list[i]["company_name"] = newValue.inputValue;
    } else if (newValue === null) {
      //list[i]["referent_company"] = null
      list[i]["company_name"] = null;
      list[i]["company_id"] = null;
    } else {
      list[i]["company_name"] = newValue.company_name;
      list[i]["company_id"] = newValue.id;
    }
    setReferentInputList(list);
    setValues({ ...values, speakers: list });
  };

  // Functions for Sponsors
  const handleSponsorInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = sponsorInputList;
    list[index][name] = value;
    setSponsorInputList(list);
    setValues({ ...values, sponsors: list });
  };

  const handleSponsorRemoveClick = (index) => {
    const list = sponsorInputList;
    list.splice(index, 1);
    setSponsorInputList(list);
    setValues({ ...values, sponsors: list });
  };

  const handleSponsorAddClick = () => {
    const list = sponsorInputList;
    list.push({ id: "" });
    //setSponsorInputList([...sponsorInputList, { id: "" }]);
    //setSponsorInputList([...sponsorInputList, list]);
    setSponsorInputList(list);
    setValues({ ...values, sponsors: list });
  };

  return (
    <section className={classes.darkerSection}>
      <div className="wrapper">
        <TopContent page="Event anlegen" />
        <div className="buttonSection">
          <StyledButton
            startIcon={<ArrowBackIosSharpIcon />}
            onClick={() => {
              history.goBack();
            }}
          >
            zurück
          </StyledButton>
        </div>
        <div>
          <div>
            <StyledTabs
              value={tab}
              onChange={handleChangeTab}
              aria-label="disabled tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              <StyledTab label="Allgemeine Daten" {...a11yProps(0)} />
              <StyledTab label="Adresse" {...a11yProps(1)} />
              <StyledTab label="Programmpunkte" {...a11yProps(2)} />
              <StyledTab label="Verantwortliche" {...a11yProps(3)} />
            </StyledTabs>
          </div>
          <TabPanel value={tab} index={0}>
            <form>
              <Container fluid style={{ padding: 0, margin: 0 }}>
                <Row>{generateFormFields(generalDataForm, "general")}</Row>
                
              </Container>
            </form>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <form>
              <Container fluid style={{ padding: 0, margin: 0 }}>
                <Row>{generateFormFields(addressDataForm, "address")}</Row>
              </Container>
            </form>
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <form>
              <Container fluid>
                <p style={{ color: "#ffffff80", marginLeft: "-15px" }}>
                  Hier können Sie das Programm für die Veranstaltung festlegen.
                  Um neue Programmpunkte hinzuzufügen, drücken Sie den
                  "+"-Button.
                </p>
                {inputList.map((x, i) => {
                  return (
                    <Row
                      key={x + i}
                      style={{
                        marginBottom: "12px",
                        paddingBottom: "12px",
                        border: "1px solid #1A617F",
                      }}
                    >
                      <Col lg={5} md={5} sm={6}>
                        <div className={classes.label}>Startdatum</div>
                        <ThemeProvider theme={abbottTheme}>
                          <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={deLocale}
                          >
                            <KeyboardDatePicker
                              clearable
                              name="date_from"
                              value={Date.parse(x.date_from) || null}
                              onChange={(date) =>
                                handleProgrammDateChange(date, i, "date_from")
                              }
                              disablePast
                              format="dd.MM.yyyy"
                              className={classes.field}
                              orientation="portrait"
                              InputProps={{
                                classes: {
                                  input: classes.datePickerInput,
                                },
                                disableUnderline: true,
                              }}
                              inputProps={{
                                "aria-label": "Startdatum",
                              }}
                              cancelLabel="Abbrechen"
                              clearLabel="Zurücksetzen"
                              KeyboardButtonProps={{
                                classes: {
                                  root: classes.icon,
                                },
                                "aria-label": "Startdatum"
                              }}
                              DialogProps={{
                                disableScrollLock: true,
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </ThemeProvider>
                      </Col>
                      <Col lg={5} md={5} sm={6}>
                        <div className={classes.label}>Startzeit</div>
                        <ThemeProvider theme={abbottTheme}>
                          <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={deLocale}
                          >
                            <KeyboardTimePicker
                              clearable
                              ampm={false}
                              value={Date.parse(x.time_from) || null}
                              onChange={(date) =>
                                handleProgrammTimeChange(date, i, "time_from")
                              }
                              className={classes.field}
                              classes={{ root: classes.field }}
                              orientation="portrait"
                              minutesStep={5}
                              cancelLabel="Abbrechen"
                              clearLabel="Zurücksetzen"
                              InputProps={{
                                classes: {
                                  input: classes.datePickerInput,
                                },
                                disableUnderline: true,
                              }}
                              inputProps={{
                                "aria-label": "Startzeit",
                              }}
                              KeyboardButtonProps={{
                                classes: {
                                  root: classes.icon,
                                },
                                "aria-label": "Startzeit",
                              }}
                              DialogProps={{
                                disableScrollLock: true,
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </ThemeProvider>
                      </Col>
                      <Col lg={10} md={10} sm={9}>
                        <div className={classes.label}>Beschreibung</div>
                        <OutlinedInput
                          id={x.item_description}
                          value={x.item_description}
                          name="item_description"
                          type="text"
                          required={false}
                          margin="dense"
                          size="small"
                          className={classes.field}
                          classes={{ multiline: classes.multiline }}
                          multiline
                          rows={2}
                          onChange={(e) => handleInputChange(e, i)}
                          autoComplete="off"
                          inputProps={{
                            "aria-label": "Beschreibung"
                          }}
                        />
                      </Col>
                      <Col
                        style={{ alignSelf: "flex-end", textAlign: "right" }}
                      >
                        {inputList.length !== 1 && (
                          <StyledTooltip
                            title="Programmpunkt löschen"
                            arrow={true}
                            enterDelay={600}
                            enterNextDelay={600}
                          >
                            <TableIconButton
                              className="mr10"
                              onClick={() => handleRemoveClick(i)}
                              classes={{ root: classes.listButtonMargin }}
                              style={{ marginTop: "19px" }}
                            >
                              <DeleteForeverSharpIcon />
                            </TableIconButton>
                          </StyledTooltip>
                        )}
                        {inputList.length - 1 === i && (
                          <StyledTooltip
                            title="Neuen Programmpunkt erstellen"
                            arrow={true}
                            enterDelay={600}
                            enterNextDelay={600}
                          >
                            <TableIconButton
                              aria-label="add"
                              size="small"
                              onClick={handleAddClick}
                              style={{ marginTop: "19px" }}
                            >
                              <AddIcon style={{ fontSize: 18 }} />
                            </TableIconButton>
                          </StyledTooltip>
                        )}
                      </Col>
                    </Row>
                  );
                })}
                {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
              </Container>
            </form>
          </TabPanel>
          <TabPanel value={tab} index={3}>
            <form>
              <Container fluid>
                <b style={{ marginLeft: "-15px" }}>Referenten</b>
                {referentInputList.map((x, i) => {
                  return (
                    <Row
                      key={x + i}
                      style={{
                        marginBottom: "12px",
                        paddingBottom: "12px",
                        border: "1px solid #1A617F",
                      }}
                    >
                      <Col lg={5} md={5} sm={12}>
                        <div className={classes.label}>Name</div>
                        <OutlinedInput
                          id="speaker"
                          type="text"
                          required={false}
                          margin="dense"
                          size="small"
                          name="speaker"
                          className={classes.field}
                          classes={{ input: classes.field }}
                          autoComplete="off"
                          value={x.speaker}
                          onChange={(e) => handleReferentInputChange(e, i)}
                          inputProps={{
                            "aria-label": "Name"
                          }}
                        />
                      </Col>
                      <Col lg={5} md={5} sm={12}>
                        <div className={classes.label}>Klinik / Praxis</div>
                        <Autocomplete
                          id="referent_company"
                          value={{
                            id: x.company_id,
                            company_name: x.company_name,
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          options={companies}
                          renderOption={(option) => option.company_name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              margin="dense"
                              style={{ margin: "0px" }}
                            />
                          )}
                          className={classes.field}
                          classes={{
                            input: classes.field,
                            popupIndicator: classes.selectIcon,
                          }}
                          getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === "string") {
                              return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            // null option
                            if (!option.company_name) {
                              return "";
                            }
                            // Regular option
                            return option.company_name;
                          }}
                          freeSolo={true}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            // Suggest the creation of a new value
                            if (params.inputValue !== "") {
                              filtered.push({
                                inputValue: params.inputValue,
                                company_name: `Hinzufügen: "${params.inputValue}"`,
                              });
                            }
                            return filtered;
                          }}
                          onChange={(e, newValue) =>
                            handleReferentAutocompleteChange(e, i, newValue)
                          }
                          getOptionSelected={(option, value) =>
                            option.company_name === value.company_name
                          }
                        />
                      </Col>
                      <Col
                        lg={2}
                        md={2}
                        sm={12}
                        style={{
                          alignSelf: "flex-end",
                          textAlign: "right",
                          height: "100%",
                        }}
                      >
                        {referentInputList.length !== 1 && (
                          <StyledTooltip
                            title="Programmpunkt löschen"
                            arrow={true}
                            enterDelay={600}
                            enterNextDelay={600}
                          >
                            <TableIconButton
                              className="mr10"
                              onClick={() => handleReferentRemoveClick(i)}
                              style={{ marginTop: "19px" }}
                            >
                              <DeleteForeverSharpIcon />
                            </TableIconButton>
                          </StyledTooltip>
                        )}
                        {referentInputList.length - 1 === i && (
                          <StyledTooltip
                            title="Neuen Programmpunkt erstellen"
                            arrow={true}
                            enterDelay={600}
                            enterNextDelay={600}
                          >
                            <TableIconButton
                              aria-label="add"
                              size="small"
                              onClick={handleReferentAddClick}
                              style={{ marginTop: "19px" }}
                            >
                              <AddIcon style={{ fontSize: 18 }} />
                            </TableIconButton>
                          </StyledTooltip>
                        )}
                      </Col>
                    </Row>
                  );
                })}
              </Container>
              <Container fluid style={{ marginTop: "40px" }}>
                <b style={{ marginLeft: "-15px" }}>Sponsoren</b>
                {sponsorInputList.map((x, i) => {
                  return (
                    <Row
                      key={x + i}
                      style={{
                        marginBottom: "12px",
                        paddingBottom: "12px",
                        border: "1px solid #1A617F",
                      }}
                    >
                      <Col lg={10} md={10} sm={10} xs={12}>
                        <FormControl style={{ width: "100%" }}>
                          <div className={classes.label}>Sponsor</div>
                          <Select
                            disableUnderline={true}
                            onChange={(e) => handleSponsorInputChange(e, i)}
                            defaultValue={""}
                            className={classes.field}
                            classes={{
                              root: classes.select,
                              icon: classes.selectIcon,
                            }}
                            name="id"
                            value={x.id || ""}
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                          >
                            <MenuItem value="">
                              <em>Keine</em>
                            </MenuItem>
                            {sponsors.map((sponsor) => {
                              return (
                                <MenuItem
                                  key={sponsor.id}
                                  className={classes.selectValues}
                                  value={sponsor.id}
                                >
                                  {sponsor.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Col>
                      <Col
                        lg={2}
                        md={2}
                        sm={2}
                        style={{
                          alignSelf: "flex-end",
                          textAlign: "right",
                          height: "100%",
                        }}
                      >
                        {sponsorInputList.length !== 1 && (
                          <StyledTooltip
                            title="Programmpunkt löschen"
                            arrow={true}
                            enterDelay={600}
                            enterNextDelay={600}
                          >
                            <TableIconButton
                              className="mr10"
                              onClick={() => handleSponsorRemoveClick(i)}
                              style={{ marginTop: "19px" }}
                            >
                              <DeleteForeverSharpIcon />
                            </TableIconButton>
                          </StyledTooltip>
                        )}
                        {sponsorInputList.length - 1 === i && (
                          <StyledTooltip
                            title="Neuen Programmpunkt erstellen"
                            arrow={true}
                            enterDelay={600}
                            enterNextDelay={600}
                          >
                            <TableIconButton
                              aria-label="add"
                              size="small"
                              onClick={handleSponsorAddClick}
                              style={{ marginTop: "19px" }}
                            >
                              <AddIcon style={{ fontSize: 18 }} />
                            </TableIconButton>
                          </StyledTooltip>
                        )}
                      </Col>
                    </Row>
                  );
                })}
              </Container>
            </form>
          </TabPanel>
        </div>
        <div className={classes.required}>* Pflichtfeld</div>
        <div style={{ textAlign: "end", marginTop: "12px" }}>
          <StyledButton
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={<SaveSharpIcon />}
            onClick={handleConfirm}
            autoFocus
            type="submit"
          >
            erstellen
          </StyledButton>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MuiAlert
          elevation={0}
          variant="filled"
          severity={successMessage[0]}
          onClose={handleSnackbarClose}
        >
          {successMessage[1]}
        </MuiAlert>
      </Snackbar>
    </section>
  );
};

export default EventNew;
