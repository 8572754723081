import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import DeleteForeverSharpIcon from '@material-ui/icons/DeleteForeverSharp';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from "@material-ui/core/Typography";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import http from "../../utils/http-common";
import clsx from 'clsx';

import { TopContent, ProgressBar, DeleteDialog, StyledTableRow, TableIconButton, NormalTableCell, FunctionsTableCell, StyledButton, StyledTooltip, FilterableTableHead, } from "../../components";
import { SECONDARY, WHITE_TRANSPARENT, WHITE, DESIGN_GREEN, DESIGN_GREEN_LIGHT, DESIGN_RED, MEDIUMGRAY, } from "../../styles/colors";
import { getAuthUser } from "../../utils/authUser";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 800,
  },
  title: {
    flex: "1 1 100%",
    textTransform: "uppercase",
  },
  tableToolbar: {
    padding: "0",
    minHeight: "40px",
    marginTop: "50px",
  },
  darkerSection: {
    backgroundColor: SECONDARY,
  },
  pagination: {
    color: WHITE_TRANSPARENT,
    border: 0,
    backgroundColor: "#1a617f",
  },
  selectIcon: {
    color: WHITE_TRANSPARENT,
  },
  buttonMargin: {
    marginRight: "8px",
  },
  buttonMarginSmall: {
    marginTop: "5px",
    marginRight: "8px",
  },
  status: {
    height: "24px",
    width: "100%",
    padding: "2px 8px"
  },
  open: {
    backgroundColor: MEDIUMGRAY,
  },
  send: {
    backgroundColor: DESIGN_GREEN_LIGHT,
  },
  confirmed: {
    backgroundColor: DESIGN_GREEN,
  },
  rejected: {
    backgroundColor: DESIGN_RED,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  disabled: {
    color: "#fff",
    boxShadow: "none",
    backgroundColor: "#000"
  },
  hoverTableRow: {
    '&:hover': {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      cursor: "pointer",
    }
  },
}));

const headCells = [
  { id: "events.id", label: "ID", width: "4%", filterable: true },
  { id: "events.date_from", label: "Datum", width: "9%", filterable: true },
  { id: "events.city", label: "Ort", width: "12%", filterable: true },
  { id: "events.event_name", label: "Bezeichnung", width: "36%", filterable: true },
  { id: "events.capacity", label: "Auslastung", width: "18%", filterable: false },
  { id: "physician.state", label: "Status", width: "18%", filterable: false },
];

const DoctorDetail = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const classes = useStyles();
  const [doctor, setDoctor] = useState({});
  const history = useHistory();
  let { id } = useParams();
  const [isPhysicianDeleteOpen, setIsPhysicianDeleteOpen] = useState(false);
  const [name, setName] = useState("");
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const matches = useMediaQuery('(max-width:450px)');
  const user = getAuthUser();
  const [disableDeleteButton, setDisableDeleteButton] = useState(false);
  const [successMessage, setSuccessMessage] = useState([]);
  const [order, setOrder] = useState("ASC");
  const [orderBy, setOrderBy] = useState("events.date_from");

  useEffect(() => {
    if (user) {
      setDisableDeleteButton(user.roles.includes("external"));
    }
  }, [user])

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
    history.goBack();
  };

  function openPhysicianDeleteDialog(name) {
    setIsPhysicianDeleteOpen(true);
    setName(name);
  }

  const handlePhysicianDeleteDialogClose = () => {
    setIsPhysicianDeleteOpen(false);
  };

  const handlePhysicianDeleteDialogCloseOnSubmit = () => {
    setIsPhysicianDeleteOpen(false);
    setSnackbarOpen(true);
  };

  const formatDate = (dateStr) => {
    //let date = new Date(dateStr + "Z");
    let date = new Date(dateStr);
    return (date.toLocaleDateString("de-DE"));
  }

  const alert = (result) => {
    setSuccessMessage(result);
    setSnackbarOpen(true);
  };

  const sorting = (order, orderBy) => {
    setOrder(order.toUpperCase());
    setOrderBy(orderBy);
  };

  useEffect(() => {
    const fetchDoctor = async () => {
      await http
        .get("/physician/" + id)
        .then(function (response) {
          setDoctor(response.data.physician);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    fetchDoctor();
  }, [id]);

  useEffect(() => {
    const fetchEvents = async () => {
      setIsLoading(true);
      await http
        .get(`/events/1`, {
          params: { physician_filter: id, sort: orderBy, sort_dir: order },
        })
        .then(function (response) {
          setEvents(response.data.events.data);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchEvents();
  }, [id, orderBy, order]);

  return (
    <div>
      <section>
        <div className="wrapper">
          <TopContent
            page={
              Object.keys(doctor).length !== 0
                ? (doctor.academic_degree ? doctor.academic_degree + " " : "") +
                (doctor.first_name ? doctor.first_name + " " : "") +
                doctor.last_name
                : ""
            }
            subheading={Object.keys(doctor).length !== 0 ? doctor.email : null}
          />
          <div className="buttonSection">
            <StyledButton
              startIcon={<ArrowBackIosSharpIcon />}
              onClick={() => {
                history.goBack();
              }}
              classes={!matches ? { root: classes.buttonMargin } : { root: classes.buttonMarginSmall }}
            >
              zurück
            </StyledButton>
            <StyledButton
              endIcon={<EditSharpIcon />}
              classes={!matches ? { root: classes.buttonMargin } : { root: classes.buttonMarginSmall }}
              onClick={() => {
                history.push("/doctor_edit/" + id)
              }}
            >
              bearbeiten
            </StyledButton>
            {!disableDeleteButton &&
              <StyledButton
                endIcon={<DeleteForeverSharpIcon />}
                classes={!matches ? { root: classes.buttonMargin } : { root: classes.buttonMarginSmall }}
                onClick={() =>
                  openPhysicianDeleteDialog(
                    (doctor.academic_degree ? doctor.academic_degree + " " : "") +
                    (doctor.first_name ? doctor.first_name + " " : "") +
                    doctor.last_name
                  )
                }
              >
                löschen
            </StyledButton>
            }

          </div>
          <Toolbar className={classes.tableToolbar}>
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >Veranstaltungen</Typography>
          </Toolbar>
          <TableContainer >
            <Table className={classes.table} aria-label="customized table">
              
              <FilterableTableHead headCells={headCells} sortFunc={sorting} />
              <TableBody>
                {events.map((event) => (
                  <StyledTableRow key={event.id} classes={{ root: classes.hoverTableRow }}>
                    <NormalTableCell component="th" scope="row" onClick={() => { history.push("/event/" + event.id) }}>
                      {event.id}
                    </NormalTableCell>
                    <NormalTableCell onClick={() => { history.push("/event/" + event.id) }}>{formatDate(event.date_from)}</NormalTableCell>
                    <NormalTableCell onClick={() => { history.push("/event/" + event.id) }}>{event.city}</NormalTableCell>
                    <NormalTableCell onClick={() => { history.push("/event/" + event.id) }} styles={{ backgroundColor: WHITE }}>{event.event_name}</NormalTableCell>
                    <NormalTableCell onClick={() => { history.push("/event/" + event.id) }} style={{ padding: "10px" }}>
                      <ProgressBar participant_limit={event.participant_limit ? event.participant_limit : 0} participant_number={event.counted_physicians ? event.counted_physicians : 0} />
                    </NormalTableCell>
                    <NormalTableCell onClick={() => { history.push("/event/" + event.id) }} style={{ padding: "10px" }}>
                      {event.state === "open" && <div className={clsx(classes.status, classes.open)}>offen</div>}
                      {event.state === "invited" && <div className={clsx(classes.status, classes.send)}>versendet</div>}
                      {event.state === "accepted" && <div className={clsx(classes.status, classes.confirmed)}>bestätigt</div>}
                      {event.state === "rejected" && <div className={clsx(classes.status, classes.rejected)}>abgelehnt</div>}
                    </NormalTableCell>
                    <FunctionsTableCell align="right">
                      <StyledTooltip title="Ansehen" arrow={true} enterDelay={600} enterNextDelay={600}>
                        <TableIconButton aria-label="ansehen" size="small" onClick={() => { history.push("/event/" + event.id) }}>
                          <VisibilityOutlinedIcon style={{ fontSize: 18 }} />
                        </TableIconButton>
                      </StyledTooltip>
                    </FunctionsTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </section>
      <DeleteDialog
        callback={alert}
        isOpen={isPhysicianDeleteOpen}
        handleClose={handlePhysicianDeleteDialogClose}
        handleCloseOnSubmit={handlePhysicianDeleteDialogCloseOnSubmit}
        name={name}
        id={parseInt(id)}
        type="physician"
        message=" wird dauerhaft gelöscht und ist kein Teilnehmer mehr in den jeweiligen Veranstaltungen."
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MuiAlert
          variant="filled"
          elevation={0}
          severity={successMessage[0]}
          onClose={handleSnackbarClose}
        >
          {successMessage[1]}
        </MuiAlert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DoctorDetail;
