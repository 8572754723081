import "date-fns";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import deLocale from "date-fns/locale/de";
import DateFnsUtils from "@date-io/date-fns";
import { fade, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { PRIMARY_LIGHT, SECONDARY, SECONDARY_LIGHT } from "../../styles/colors";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    backgroundColor: SECONDARY_LIGHT + "!important",
    paddingLeft: "12px",
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25) + "!important",
    },
    marginLeft: "12px",
    minWidth: "200px",
    height: "33px",
  },
  input: {
    color: "#ffffff80!important",
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "12px!important",
  },
  icon: {
    color: "#ffffff80!important",
  },
}));

const abbottTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: SECONDARY,
      },
    },
    MuiPickersYear: {
      yearSelected: {
        color: PRIMARY_LIGHT,
      },
      root: {
        "&:focus": {
          color: PRIMARY_LIGHT,
        },
      },
    },
    MuiPickersMonth: {
      monthSelected: {
        color: PRIMARY_LIGHT,
      },
      root: {
        "&:focus": {
          color: PRIMARY_LIGHT,
        },
      },
    },
    MuiButton: {
      root: {
          color: SECONDARY + "!important",
      }
  },
  },
});

const DatePicker = (props) => {
  const [selectedDate, setSelectedDate] = React.useState(null);
  const classes = useStyles();

  useEffect(()=> {
    if(props.currentValue !== ""){
      setSelectedDate(new Date(props.currentValue))
    } else {
      setSelectedDate(null)
    }
  }, [props.currentValue])

  const handleDateChange = (date) => {
    props.callBack(date);
    setSelectedDate(date);
  };

  return (
    <div>
      <ThemeProvider theme={abbottTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
          <KeyboardDatePicker
            views={["year", "month"]}
            orientation="portrait"
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              "aria-label": "Nach Monat filtern",
              classes: { root: classes.input },
            }}
            cancelLabel="Abbrechen"
            clearLabel="Zurücksetzen"
            InputProps={{
              classes: {
                input: classes.input,
                root: classes.inputRoot,
              },
            }}
            inputProps= {{
              "aria-label": "Nach Monat filtern",
            }}
            clearable
            emptyLabel="Monat"
            DialogProps={{
              disableScrollLock: true,
            }}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
};

DatePicker.propTypes = {
  callBack: PropTypes.func.isRequired,
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
};

export default DatePicker;
