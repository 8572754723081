import { fade, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { WHITE, SECONDARY_LIGHT } from "../../styles/colors";

const SelectStyling = withStyles(() => ({
  root: {
    backgroundColor: SECONDARY_LIGHT + "!important",
    paddingLeft: "12px",
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
    "&:hover": {
      backgroundColor: fade(WHITE, 0.25) + "!important",
    },
    color: "#ffffff80!important",
    width: "164px",
    fontSize: "12px",
    marginLeft: "12px",
    height: "20px",
  },
  icon: {
    color: "#ffffff80!important",
  },
}))(Select);

const StyledSelect = (props) => {
  const [category, setCategory] = React.useState(props.currentValue);

  const handleChange = (event) => {
    props.callBack(event.target.value);
    setCategory(event.target.value);
  };

  return (
    <SelectStyling
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={category}
      onChange={handleChange}
      displayEmpty
      disableUnderline
      MenuProps={{
        disableScrollLock: true,
      }}
    >
      <MenuItem value="">
        {props.default}
      </MenuItem>
      {props.array.map((element) => (
        <MenuItem key={element.id} value={element.id}>
          {element.category_name}
        </MenuItem>
      ))}
    </SelectStyling>
  );
};

StyledSelect.propTypes = {
  default: PropTypes.string.isRequired,
  array: PropTypes.array.isRequired,
  callBack: PropTypes.func.isRequired,
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default StyledSelect;
