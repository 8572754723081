// External Imports
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/AddSharp";
import EditSharpIcon from "@material-ui/icons/EditSharp";
import DeleteForeverSharpIcon from "@material-ui/icons/DeleteForeverSharp";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";

// Internal Imports
import {
  TopContent,
  TableIconButton,
  NormalTableCell,
  StyledTableRow,
  FunctionsTableCell,
  StyledTooltip,
  AddDialog,
  DeleteDialog,
  EditDialog,
  TablePaginationActions,
  SearchBar,
} from "../../components";
import { SECONDARY, WHITE_TRANSPARENT } from "../../styles/colors";
import http from "../../utils/http-common";
import {
  categoryForm,
  topicForm,
  organizerForm,
  sponsorForm,
  companyForm,
} from "./settingsData";

// Custom Styling
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 420,
  },
  title: {
    textTransform: "uppercase",
    letterSpacing: "1px",
  },
  toolbarSpacing: {
    flex: "1 1 1%",
  },
  tableToolbar: {
    padding: "0",
    minHeight: "40px",
    marginTop: "50px",
    marginBottom: "10px",
  },
  darkerSection: {
    backgroundColor: SECONDARY,
  },
  pagination: {
    color: WHITE_TRANSPARENT,
    border: 0,
    backgroundColor: "#1a617f",
  },
  selectIcon: {
    color: WHITE_TRANSPARENT,
  },
  buttonMargin: {
    marginRight: "8px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  noHoverOnRow: {
    pointerEvents: "none",
  },
}));

const Settings = (props) => {
  // Declaring all Variables of Component
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [topics, setTopics] = useState([]);
  const [organizers, setOrganizers] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [categoriesCounter, incrementCategoriesCounter] = useState(0);
  const [topicsCounter, incrementTopicsCounter] = useState(0);
  const [organizersCounter, incrementOrganizersCounter] = useState(0);
  const [sponsorsCounter, incrementSponsorsCounter] = useState(0);
  const [companiesCounter, incrementCompaniesCounter] = useState(0);
  const [successMessage, setSuccessMessage] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [name, setName] = useState("");
  const [id, setId] = useState(0);
  const [item, setItem] = useState({});
  const [form, setForm] = useState([]);
  const [type, setType] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalEntries, setTotalEntries] = useState(0);
  const [companyPage, setCompanyPage] = useState(0);
  const [companyRowsPerPage, setCompanyRowsPerPage] = useState(10);
  const [companyTotalEntries, setCompanyTotalEntries] = useState(0);
  const [companySearchStr, setCompanySearchStr] = useState("");
  const [organizerSearchStr, setOrganizerSearchStr] = useState("");

  //Company Search
  const companySearch = async (str) => {
    setCompanySearchStr(str);
    setCompanyPage(0);
  };

  //Organizer Search
  const organizerSearch = async (str) => {
    setOrganizerSearchStr(str);
    setPage(0);
  };

  // Edit
  function openEditDialog(item, type) {
    setIsEditOpen(true);
    setItem(item);
    setType(type);
    getForm(type);
  }

  const handleEditDialogClose = () => {
    setIsEditOpen(false);
  };

  const handleEditDialogCloseOnSubmit = (type) => {
    setIsEditOpen(false);
    increment(type);
  };

  // Delete
  function openDeleteDialog(id, name, type) {
    setType(type);
    setName(name);
    setId(id);
    setIsDeleteOpen(true);
  }

  const handleDeleteDialogClose = () => {
    setIsDeleteOpen(false);
  };

  const handleDeleteDialogCloseOnSubmit = (type) => {
    setIsDeleteOpen(false);
    if (type === "organizer") {
      if (totalEntries % rowsPerPage === 1) {
        setPage(page - 1);
      } else {
        increment(type);
      }
    }
    if (type === "company") {
      if (companyTotalEntries % companyRowsPerPage === 1) {
        setCompanyPage(companyPage - 1);
      } else {
        increment(type);
      }
    } else {
      increment(type);
    }
  };

  // Add
  const openAddDialog = (type) => {
    setType(type);
    getForm(type);
    setIsAddOpen(true);
  };

  const handleAddDialogClose = () => {
    setIsAddOpen(false);
  };

  const handleAddDialogCloseOnSubmit = (type) => {
    setIsAddOpen(false);
    increment(type);
  };

  // General Functions
  const alert = (result) => {
    setSuccessMessage(result);
    setSnackbarOpen(true);
  };

  const increment = (type) => {
    if (type === "category") {
      incrementCategoriesCounter(categoriesCounter + 1);
    }
    if (type === "topic") {
      incrementTopicsCounter(topicsCounter + 1);
    }
    if (type === "organizer") {
      incrementOrganizersCounter(organizersCounter + 1);
    }
    if (type === "sponsor") {
      incrementSponsorsCounter(sponsorsCounter + 1);
    }
    if (type === "company") {
      incrementCompaniesCounter(companiesCounter + 1);
    }
  };

  const getForm = (type) => {
    if (type === "category") {
      setForm(categoryForm);
    }
    if (type === "topic") {
      setForm(topicForm);
    }
    if (type === "organizer") {
      setForm(organizerForm);
    }
    if (type === "sponsor") {
      setForm(sponsorForm);
    }
    if (type === "company") {
      setForm(companyForm);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  // Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangePageCompany = (event, newPage) => {
    setCompanyPage(newPage);
  };

  const handleChangeRowsPerPageCompany = (event) => {
    setCompanyRowsPerPage(parseInt(event.target.value));
    setCompanyPage(0);
  };

  // Data fetching
  useEffect(() => {
    const fetchCategories = async () => {
      await http
        .get("/categories")
        .then((response) => {
          setCategories(response.data.categories);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchCategories();
  }, [categoriesCounter]);

  useEffect(() => {
    const fetchTopics = async () => {
      await http
        .get("/topics")
        .then((response) => {
          setTopics(response.data.topics);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchTopics();
  }, [topicsCounter]);

  useEffect(() => {
    const fetchOrganizers = async () => {
      await http
        .get(`/organizers/${page + 1}/${rowsPerPage}`, {
          params: {search: organizerSearchStr}
        })
        .then((response) => {
          setOrganizers(response.data.organizers.original.organizers.data);
          setTotalEntries(response.data.organizers.original.organizers.total);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchOrganizers();
  }, [organizersCounter, page, rowsPerPage, organizerSearchStr]);

  useEffect(() => {
    const fetchSponsors = async () => {
      await http
        .get("/sponsors/1")
        .then((response) => {
          setSponsors(response.data.sponsors.data);
          //console.log(response.data.sponsors.data)
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchSponsors();
  }, [sponsorsCounter]);

  useEffect(() => {
    const fetchCompanies = async () => {
      await http
        .get(`/companies/${companyPage + 1}/${companyRowsPerPage}`, {
          params: { search: companySearchStr },
        })
        .then((response) => {
          setCompanies(response.data.companies.data);
          setCompanyTotalEntries(response.data.companies.total);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchCompanies();
  }, [companiesCounter, companyPage, companyRowsPerPage, companySearchStr]);

  return (
    <div>
      <section className={classes.darkerSection}>
        <div className="wrapper">
          <TopContent page="Einstellungen" />

          {/* Kategorien */}
          <Grid container spacing={8}>
            <Grid item sm={12} md={6}>
              <Toolbar className={classes.tableToolbar}>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  VA Kategorien
                </Typography>
                <StyledTooltip
                  title="erstellen"
                  arrow
                  enterDelay={600}
                  enterNextDelay={600}
                >
                  <TableIconButton
                    aria-label="add"
                    size="small"
                    onClick={() => openAddDialog("category")}
                  >
                    <AddIcon style={{ fontSize: 18 }} />
                  </TableIconButton>
                </StyledTooltip>
              </Toolbar>
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-label="Veranstaltungs-Kategorien Tabelle"
                >
                  <TableHead>
                    <TableRow>
                      <NormalTableCell>ID</NormalTableCell>
                      <NormalTableCell>Titel</NormalTableCell>
                      <FunctionsTableCell align="right">
                        Funktionen
                      </FunctionsTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {categories.map((category) => (
                      <StyledTableRow
                        key={
                          category.id
                        } /* classes={{root: classes.noHoverOnRow}} */
                      >
                        <NormalTableCell component="th" scope="row">
                          {category.id}
                        </NormalTableCell>
                        <NormalTableCell>
                          {category.category_name}
                        </NormalTableCell>
                        <FunctionsTableCell align="right">
                          <StyledTooltip
                            title="bearbeiten"
                            arrow
                            enterDelay={600}
                            enterNextDelay={600}
                          >
                            <TableIconButton
                              aria-label="bearbeiten"
                              size="small"
                              onClick={() =>
                                openEditDialog(category, "category")
                              }
                            >
                              <EditSharpIcon style={{ fontSize: 18 }} />
                            </TableIconButton>
                          </StyledTooltip>
                          <StyledTooltip
                            title="löschen"
                            arrow
                            enterDelay={600}
                            enterNextDelay={600}
                          >
                            <TableIconButton
                              aria-label="delete"
                              size="small"
                              onClick={() =>
                                openDeleteDialog(
                                  category.id,
                                  category.category_name,
                                  "category"
                                )
                              }
                            >
                              <DeleteForeverSharpIcon
                                style={{ fontSize: 18 }}
                              />
                            </TableIconButton>
                          </StyledTooltip>
                        </FunctionsTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Themen */}
              <Toolbar className={classes.tableToolbar}>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  VA Themen
                </Typography>
                <StyledTooltip
                  title="erstellen"
                  arrow
                  enterDelay={600}
                  enterNextDelay={600}
                >
                  <TableIconButton
                    aria-label="add"
                    size="small"
                    onClick={() => openAddDialog("topic")}
                  >
                    <AddIcon style={{ fontSize: 18 }} />
                  </TableIconButton>
                </StyledTooltip>
              </Toolbar>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <NormalTableCell>ID</NormalTableCell>
                      <NormalTableCell>Titel</NormalTableCell>
                      <FunctionsTableCell align="right">
                        Funktionen
                      </FunctionsTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {topics.map((topic) => (
                      <StyledTableRow key={topic.id}>
                        <NormalTableCell component="th" scope="row">
                          {topic.id}
                        </NormalTableCell>
                        <NormalTableCell>{topic.topic_name}</NormalTableCell>
                        <FunctionsTableCell align="right">
                          <StyledTooltip
                            title="bearbeiten"
                            arrow
                            enterDelay={600}
                            enterNextDelay={600}
                          >
                            <TableIconButton
                              aria-label="bearbeiten"
                              size="small"
                              onClick={() => openEditDialog(topic, "topic")}
                            >
                              <EditSharpIcon style={{ fontSize: 18 }} />
                            </TableIconButton>
                          </StyledTooltip>
                          <StyledTooltip
                            title="löschen"
                            arrow
                            enterDelay={600}
                            enterNextDelay={600}
                          >
                            <TableIconButton
                              aria-label="delete"
                              size="small"
                              onClick={() =>
                                openDeleteDialog(
                                  topic.id,
                                  topic.topic_name,
                                  "topic"
                                )
                              }
                            >
                              <DeleteForeverSharpIcon
                                style={{ fontSize: 18 }}
                              />
                            </TableIconButton>
                          </StyledTooltip>
                        </FunctionsTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Unternehmen */}
              <Toolbar className={classes.tableToolbar}>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  Klinik / Praxis
                </Typography>
                <div className={classes.toolbarSpacing}>
                  <StyledTooltip
                    title="erstellen"
                    arrow
                    enterDelay={600}
                    enterNextDelay={600}
                  >
                    <TableIconButton
                      aria-label="add"
                      size="small"
                      onClick={() => openAddDialog("company")}
                    >
                      <AddIcon style={{ fontSize: 18 }} />
                    </TableIconButton>
                  </StyledTooltip>
                </div>
                <SearchBar callBack={companySearch} />
              </Toolbar>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <NormalTableCell>ID</NormalTableCell>
                      <NormalTableCell>Titel</NormalTableCell>
                      <FunctionsTableCell align="right">
                        Funktionen
                      </FunctionsTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {companies.map((company) => (
                      <StyledTableRow key={company.id}>
                        <NormalTableCell component="th" scope="row">
                          {company.id}
                        </NormalTableCell>
                        <NormalTableCell>
                          {company.company_name}
                        </NormalTableCell>
                        <FunctionsTableCell align="right">
                          <StyledTooltip
                            title="bearbeiten"
                            arrow
                            enterDelay={600}
                            enterNextDelay={600}
                          >
                            <TableIconButton
                              aria-label="bearbeiten"
                              size="small"
                              onClick={() => openEditDialog(company, "company")}
                            >
                              <EditSharpIcon style={{ fontSize: 18 }} />
                            </TableIconButton>
                          </StyledTooltip>
                          <StyledTooltip
                            title="löschen"
                            arrow
                            enterDelay={600}
                            enterNextDelay={600}
                          >
                            <TableIconButton
                              aria-label="delete"
                              size="small"
                              onClick={() =>
                                openDeleteDialog(
                                  company.id,
                                  company.company_name,
                                  "company"
                                )
                              }
                            >
                              <DeleteForeverSharpIcon
                                style={{ fontSize: 18 }}
                              />
                            </TableIconButton>
                          </StyledTooltip>
                        </FunctionsTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <StyledTableRow>
                      <TablePagination
                        className={classes.pagination}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        colSpan={3}
                        count={companyTotalEntries}
                        rowsPerPage={companyRowsPerPage}
                        page={companyPage}
                        SelectProps={{
                          inputProps: { "aria-label": "Einträge pro Seite" },
                          classes: { icon: classes.selectIcon },
                          MenuProps: { disableScrollLock: true },
                        }}
                        onChangePage={handleChangePageCompany}
                        onChangeRowsPerPage={handleChangeRowsPerPageCompany}
                        ActionsComponent={TablePaginationActions}
                      />
                    </StyledTableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Grid>

            {/* Veranstalter */}
            <Grid item sm={12} md={6}>
              <Toolbar className={classes.tableToolbar}>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  Veranstalter
                </Typography>
                <div className={classes.toolbarSpacing}>
                  <StyledTooltip
                    title="erstellen"
                    arrow
                    enterDelay={600}
                    enterNextDelay={600}
                  >
                    <TableIconButton
                      aria-label="add"
                      size="small"
                      onClick={() => openAddDialog("organizer")}
                    >
                      <AddIcon style={{ fontSize: 18 }} />
                    </TableIconButton>
                  </StyledTooltip>
                </div>
                <SearchBar callBack={organizerSearch} />
              </Toolbar>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <NormalTableCell>ID</NormalTableCell>
                      <NormalTableCell>Titel</NormalTableCell>
                      <FunctionsTableCell align="right">
                        Funktionen
                      </FunctionsTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {organizers.map((organizer) => (
                      <StyledTableRow key={organizer.id}>
                        <NormalTableCell component="th" scope="row">
                          {organizer.id}
                        </NormalTableCell>
                        <NormalTableCell>
                          {organizer.organizer_name}
                        </NormalTableCell>
                        <FunctionsTableCell align="right">
                          <StyledTooltip
                            title="bearbeiten"
                            arrow
                            enterDelay={600}
                            enterNextDelay={600}
                          >
                            <TableIconButton
                              aria-label="bearbeiten"
                              size="small"
                              onClick={() =>
                                openEditDialog(organizer, "organizer")
                              }
                            >
                              <EditSharpIcon style={{ fontSize: 18 }} />
                            </TableIconButton>
                          </StyledTooltip>
                          <StyledTooltip
                            title="löschen"
                            arrow
                            enterDelay={600}
                            enterNextDelay={600}
                          >
                            <TableIconButton
                              aria-label="delete"
                              size="small"
                              onClick={() =>
                                openDeleteDialog(
                                  organizer.id,
                                  organizer.organizer_name,
                                  "organizer"
                                )
                              }
                            >
                              <DeleteForeverSharpIcon
                                style={{ fontSize: 18 }}
                              />
                            </TableIconButton>
                          </StyledTooltip>
                        </FunctionsTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <StyledTableRow>
                      <TablePagination
                        className={classes.pagination}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        colSpan={3}
                        count={totalEntries}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { "aria-label": "Einträge pro Seite" },
                          classes: { icon: classes.selectIcon },
                          MenuProps: { disableScrollLock: true },
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </StyledTableRow>
                  </TableFooter>
                </Table>
              </TableContainer>

              {/* Sponsoren */}
              <Toolbar className={classes.tableToolbar}>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  Sponsoren
                </Typography>
                <StyledTooltip
                  title="erstellen"
                  arrow
                  enterDelay={600}
                  enterNextDelay={600}
                >
                  <TableIconButton
                    aria-label="add"
                    size="small"
                    onClick={() => openAddDialog("sponsor")}
                  >
                    <AddIcon style={{ fontSize: 18 }} />
                  </TableIconButton>
                </StyledTooltip>
              </Toolbar>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <NormalTableCell>ID</NormalTableCell>
                      <NormalTableCell>Titel</NormalTableCell>
                      <FunctionsTableCell align="right">
                        Funktionen
                      </FunctionsTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sponsors.map((sponsor) => (
                      <StyledTableRow key={sponsor.id}>
                        <NormalTableCell component="th" scope="row">
                          {sponsor.id}
                        </NormalTableCell>
                        <NormalTableCell>{sponsor.name}</NormalTableCell>
                        <FunctionsTableCell align="right">
                          <StyledTooltip
                            title="bearbeiten"
                            arrow
                            enterDelay={600}
                            enterNextDelay={600}
                          >
                            <TableIconButton
                              aria-label="bearbeiten"
                              size="small"
                              onClick={() => openEditDialog(sponsor, "sponsor")}
                            >
                              <EditSharpIcon style={{ fontSize: 18 }} />
                            </TableIconButton>
                          </StyledTooltip>
                          <StyledTooltip
                            title="löschen"
                            arrow
                            enterDelay={600}
                            enterNextDelay={600}
                          >
                            <TableIconButton
                              aria-label="delete"
                              size="small"
                              onClick={() =>
                                openDeleteDialog(
                                  sponsor.id,
                                  sponsor.name,
                                  "sponsor"
                                )
                              }
                            >
                              <DeleteForeverSharpIcon
                                style={{ fontSize: 18 }}
                              />
                            </TableIconButton>
                          </StyledTooltip>
                        </FunctionsTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>
      </section>
      <AddDialog
        isOpen={isAddOpen}
        handleClose={handleAddDialogClose}
        handleCloseOnSubmit={handleAddDialogCloseOnSubmit}
        callback={alert}
        form={form}
        type={type}
      />
      <EditDialog
        isOpen={isEditOpen}
        handleClose={handleEditDialogClose}
        handleCloseOnSubmit={handleEditDialogCloseOnSubmit}
        form={form}
        callback={alert}
        type={type}
        item={item}
      />
      <DeleteDialog
        callback={alert}
        isOpen={isDeleteOpen}
        handleClose={handleDeleteDialogClose}
        handleCloseOnSubmit={handleDeleteDialogCloseOnSubmit}
        name={name}
        id={id}
        type={type}
        message=" wird dauerhaft gelöscht. Sie sollten sicher sein, dass Sie diese Kategorie nicht mehr benötigen."
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MuiAlert
          elevation={0}
          variant="filled"
          severity={successMessage[0]}
          onClose={handleSnackbarClose}
        >
          {successMessage[1]}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Settings;
