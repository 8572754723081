import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import http from "../../utils/http-common"
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import VpnKeySharpIcon from '@material-ui/icons/VpnKeySharp';
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { Container, Col, Row } from "react-bootstrap";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { TopContent, StyledButton, ChangePasswordDialog, StyledTooltip, TableIconButton, NormalTableCell, FunctionsTableCell, StyledTableRow } from "../../components";
import { SECONDARY, WHITE_TRANSPARENT } from "../../styles/colors";
import { userDetailForm } from "../users/userData";

const useStyles = makeStyles((theme) => ({
    darkerSection: {
        backgroundColor: SECONDARY,
    },
    buttonMargin: {
        marginRight: "8px",
    },
    buttonMarginSmall: {
        marginTop: "5px",
        marginRight: "8px",
    },
    label: {
        textTransform: "none",
        marginTop: "8px",
        fontSize: "14px",
        color: WHITE_TRANSPARENT
    },
    column: {
        marginBottom: "12px",
    },
    table: {
        minWidth: 800,
    },
    title: {
        flex: "1 1 100%",
        textTransform: "uppercase",
        letterSpacing: "1px",
    },
    tableToolbar: {
        padding: "0",
        minHeight: "40px",
        marginTop: "50px",
        marginBottom: "10px",
    },
}));



const User = () => {
    const history = useHistory();
    const [user, setUser] = useState([]);
    const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState([])
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const classes = useStyles();
    const [doctors, setDoctors] = useState([]);
    const matches = useMediaQuery('(max-width:450px)');


    const getSalutation = (salutation) => {
        switch(salutation){
          case "mrs": return "Frau";
          case "mr": return "Herr";
          default: return "";
        }
      }

    // Change Password Dialog
    const openChangePasswordDialog = (id) => {
        setIsChangePasswordOpen(true);
    }

    const handleChangePasswordDialogClose = () => {
        setIsChangePasswordOpen(false);
    }

    const handleChangePasswordDialogCloseOnSubmit = () => {
        setIsChangePasswordOpen(false);
    }

    const alert = (result) => {
        setSuccessMessage(result);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    const getName = (lastname, title, firstname) => {
        let name = lastname;
        if (title !== null || firstname !== "") {
            name += (", ");
            if (title !== null) {
                name += title;
                name += " ";
            }
            if (firstname !== "") {
                name += firstname;
            }
        }
        return name
    }

    const navigateToDetail = (id) => {
        history.push("/doctor/" + id);
    };


    useEffect(() => {
        const fetchUser = async () => {
            await http
                .get("/user")
                .then(function (response) {
                    setUser(response.data.result);
                    //console.log(response.data.result)
                    //localStorage.setItem("user", JSON.stringify(response.data.result));
                })
                .catch(function (error) {
                    console.log(error);
                });

        };
        fetchUser();
    }, [])

    useEffect(() => {
        let initalFavoritesList = []
        const fetchDoctors = async () => {
            await http
                .get("/physicians/1/25", {
                    params: { sort: "fav", sort_dir: "DESC" }
                })
                .then(function (response) {
                    response.data.physicians.data.forEach((physician) => {
                        if (physician.fav === 1) {
                            initalFavoritesList = initalFavoritesList.concat(physician);
                        }
                    })
                    setDoctors(initalFavoritesList);
                })
                .catch(function (error) {
                    console.log(error);
                });

        };
        fetchDoctors();
    }, [])

    const mapRole = role => {
        switch (role){
            case "admin": return "Admin";
            case "internal": return "Innendienst";
            case "external": return "Außendienst";
            default: return role;
        }
    }


    const generateFields = (formFields) => formFields.map((field) => {
        if (field.id === "roles") {
            return (
                <Col key={field.id} lg={field.colLg} md={field.colMd} sm={field.colSm} className={classes.column}>
                    <div className={classes.label}>{field.label}{field.required === true ? "*" : ""}</div>
                    <Typography>{user[field.id] ? mapRole(user[field.id][0]) : " "}</Typography>
                </Col>
            )
        } if (field.id === "salutation") {
            return (
                <Col key={field.id} lg={field.colLg} md={field.colMd} sm={field.colSm} className={classes.column}>
                    <div className={classes.label}>{field.label}{field.required === true ? "*" : ""}</div>
                    <Typography>{user[field.id] ? getSalutation(user[field.id]) : " "}</Typography>
                </Col>
            )
        } else {
            return (
                <Col key={field.id} lg={field.colLg} md={field.colMd} sm={field.colSm} className={classes.column}>
                    <div className={classes.label}>{field.label}{field.required === true ? "*" : ""}</div>
                    <Typography>{user[field.id] ? user[field.id] : " "}</Typography>
                </Col>
            )
        }
    })



    return (
        <section className={classes.darkerSection}>
            <div className="wrapper">
                <TopContent page="Profil" />

                <div className="buttonSection">
                    <StyledButton
                        startIcon={<ArrowBackIosSharpIcon />}
                        onClick={() => { history.goBack(); }}
                        classes={!matches ? { root: classes.buttonMargin } : { root: classes.buttonMarginSmall }}
                    >zurück</StyledButton>
                    <StyledButton
                        endIcon={<EditSharpIcon />}
                        classes={!matches ? { root: classes.buttonMargin } : { root: classes.buttonMarginSmall }}
                        onClick={() => {
                            history.push("/profile_edit")
                        }}
                    >
                        bearbeiten
                        </StyledButton>
                    <StyledButton
                        endIcon={<VpnKeySharpIcon />}
                        classes={!matches ? { root: classes.buttonMargin } : { root: classes.buttonMarginSmall }}
                        onClick={openChangePasswordDialog}
                    >
                        Passwort ändern
                        </StyledButton>
                </div>

                <Container fluid style={{ padding: 0, margin: "70px 0 0 0" }}>
                    <Row>
                        {generateFields(userDetailForm)}
                    </Row>
                </Container>

                <Toolbar className={classes.tableToolbar}>
                    <Typography
                        className={classes.title}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Favorisierte Ärzte
                    </Typography>
                </Toolbar>
                <TableContainer>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <NormalTableCell /* style={{ width: "4%" }} */>ID</NormalTableCell>
                                <NormalTableCell /* style={{ width: "20%" }} */>Name</NormalTableCell>
                                <NormalTableCell /* style={{ width: "20%" }} */>Klinik / Praxis</NormalTableCell>
                                <NormalTableCell /* style={{ width: "35%" }} */>Email</NormalTableCell>
                                <FunctionsTableCell style={{ paddingRight: "16px" }} align="right">Funktionen</FunctionsTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {doctors.map((doctor) => (
                                <StyledTableRow key={doctor.id} classes={{ root: classes.hoverTableRow }}>
                                    <NormalTableCell component="th" scope="row" >
                                        {doctor.id}
                                    </NormalTableCell>
                                    <NormalTableCell >
                                        {getName(doctor.last_name, doctor.academic_degree, doctor.first_name)}
                                    </NormalTableCell>
                                    <NormalTableCell >
                                        {doctor.company_name}
                                    </NormalTableCell>
                                    <NormalTableCell>
                                        {doctor.email}
                                    </NormalTableCell>
                                    <FunctionsTableCell align="right">
                                        <StyledTooltip
                                            title="Ansehen"
                                            arrow={true}
                                            enterDelay={600}
                                            enterNextDelay={600}
                                        >
                                            <TableIconButton
                                                aria-label="ansehen"
                                                size="small"
                                                onClick={() => navigateToDetail(doctor.id)}
                                            >
                                                <VisibilityOutlinedIcon style={{ fontSize: 18 }} />
                                            </TableIconButton>
                                        </StyledTooltip>
                                    </FunctionsTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </div>
            <ChangePasswordDialog
                isOpen={isChangePasswordOpen}
                handleClose={handleChangePasswordDialogClose}
                handleCloseOnSubmit={handleChangePasswordDialogCloseOnSubmit}
                callback={alert}
                id={user.id ?? 0}
            />
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <MuiAlert
                    elevation={0}
                    variant="filled"
                    severity={successMessage[0]}
                    onClose={handleSnackbarClose}
                >
                    {successMessage[1]}
                </MuiAlert>
            </Snackbar>
        </section>
    );
}

export default User;