import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/core/styles';

import { NormalTableCell, FunctionsTableCell } from "../../components";
import { WHITE } from '../../styles/colors';

const useStyles = makeStyles(() => ({
    root: {
        "&:hover": {
            color: WHITE + "8a!important",
        },
        "&:focus": {
            color: WHITE + "de!important",
        },
    },
    active: {
        color: WHITE + "de!important",
    },
    icon: {
        color: WHITE + "8a!important",
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));


function FilterableTableHead(props) {
    const classes = useStyles();
    const { headCells, headFunctionsCell, sortFunc } = props;
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        sortFunc(isAsc ? 'desc' : 'asc', property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };


    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    headCell.filterable ?
                        <NormalTableCell
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}
                            style={{width: headCell.width}}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                classes={{ active: classes.active, icon: classes.icon, root: classes.root }}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'absteigend sortiert' : 'aufsteigend sortiert'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </NormalTableCell> : <NormalTableCell
                            key={headCell.id}
                            style={{width: headCell.width}}
                        >{headCell.label}</NormalTableCell>
                ))}
                {props.functionCells === false ? null : <FunctionsTableCell style={{paddingRight: headFunctionsCell}} align="right">Funktionen</FunctionsTableCell>}
            </TableRow>
        </TableHead>
    );
}

FilterableTableHead.propTypes = {
    headCells: PropTypes.array.isRequired,
    sortFunc: PropTypes.func.isRequired,
    functionCells: PropTypes.bool,
};

export default FilterableTableHead;