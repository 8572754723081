import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Container, Col, Row } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import EditSharpIcon from "@material-ui/icons/EditSharp";
import Typography from "@material-ui/core/Typography";
import DeleteForeverSharpIcon from "@material-ui/icons/DeleteForeverSharp";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { TopContent, StyledButton, DeleteDialog } from "../../components";
import { SECONDARY, WHITE_TRANSPARENT } from "../../styles/colors";
import http from "../../utils/http-common";
import { userDetailForm } from "./userData";
import { getAuthUser } from "../../utils/authUser";

const useStyles = makeStyles(() => ({
  darkerSection: {
    backgroundColor: SECONDARY,
  },
  buttonMargin: {
    marginRight: "8px",
  },
  buttonMarginSmall: {
    marginTop: "5px",
    marginRight: "8px",
  },
  label: {
    textTransform: "none",
    marginTop: "8px",
    fontSize: "14px",
    color: WHITE_TRANSPARENT,
  },
  column: {
    marginBottom: "12px",
  },
}));

const UserDetail = () => {
  const [user, setUser] = useState([]);
  const history = useHistory();
  let { id } = useParams();
  const classes = useStyles();
  const [successMessage, setSuccessMessage] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [name, setName] = useState("");
  const matches = useMediaQuery("(max-width:450px)");
  const currentUser = getAuthUser();


  useEffect(() => {
    if (currentUser && user && user.roles) {
      switch (currentUser.roles[0]) {
        case "external":
          switch (user.roles[0].name) {
            case "external": break;
            default:
              history.push("/error401");
              break;
          }
          break;
        case "internal":
          switch (user.roles[0].name) {
            case "admin":
              history.push("/error401"); break;
            default: break;
          }
          break;
        case "admin":
          break;
        default:
          history.push("/error401");
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, user]);

  // Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  // Delete Dialog
  function openDeleteDialog(name) {
    setIsDeleteOpen(true);
    setName(name);
  }

  const handleDeleteDialogClose = () => {
    setIsDeleteOpen(false);
  };

  const handleDeleteDialogCloseOnSubmit = () => {
    setIsDeleteOpen(false);
    history.goBack();
  };

  const alert = (result) => {
    setSuccessMessage(result);
    setSnackbarOpen(true);
  };

  // Data fetching
  useEffect(() => {
    const fetchUser = async () => {
      await http
        .get("/user/" + id)
        .then(function (response) {
          if (response.data.result === "Token is Expired") {
          } else {
            setUser(response.data.user);
            //console.log(response.data.user);
          }
        })
        .catch(function (error) {
          console.log(error);
          if(error.message === "Request failed with status code 403"){
            /* setSuccessMessage(["error", error.message])
            setSnackbarOpen(true); */
            history.push("/error401");
          }
        });
    };
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getSalutation = (salutation) => {
    switch(salutation){
      case "mrs": return "Frau";
      case "mr": return "Herr";
      default: return "";
    }
  }

  const generateFields = (formFields) =>
    formFields.map((field) => {
      if (field.id === "roles") {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
            className={classes.column}
          >
            <div className={classes.label}>
              {field.label}
            </div>
            <Typography>
              {user[field.id] ? user[field.id][0].label : " "}
            </Typography>
          </Col>
        );
      } if (field.id === "salutation") {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
            className={classes.column}
          >
            <div className={classes.label}>
              {field.label}
            </div>
            <Typography>
            {user[field.id] ? getSalutation(user[field.id]) : " "}
            </Typography>
          </Col>
        );
      } else {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
            className={classes.column}
          >
            <div className={classes.label}>
              {field.label}
            </div>
            <Typography>{user[field.id] ? user[field.id] : " "}</Typography>
          </Col>
        );
      }
    });

  const generateName = (firstName, lastName) => {
    if (firstName && firstName !== null) {
      return (firstName + " " + lastName)
    } else {
      return (lastName);
    }
  }

  return (
    <section className={classes.darkerSection}>
      <div className="wrapper">
        <TopContent page={generateName(user.first_name, user.name)} />

        <div className="buttonSection">
          <StyledButton
            startIcon={<ArrowBackIosSharpIcon />}
            onClick={() => {
              history.goBack();
            }}
            classes={
              !matches
                ? { root: classes.buttonMargin }
                : { root: classes.buttonMarginSmall }
            }
          >
            zurück
          </StyledButton>
          <StyledButton
            endIcon={<EditSharpIcon />}
            classes={
              !matches
                ? { root: classes.buttonMargin }
                : { root: classes.buttonMarginSmall }
            }
            onClick={() => {
              history.push("/user_edit/" + user.id);
            }}
          >
            bearbeiten
          </StyledButton>
          <StyledButton
            endIcon={<DeleteForeverSharpIcon />}
            classes={
              !matches
                ? { root: classes.buttonMargin }
                : { root: classes.buttonMarginSmall }
            }
            onClick={() =>
              openDeleteDialog(
                (user.academic_degree ? user.academic_degree + " " : "") +
                (user.first_name ? user.first_name + " " : "") +
                user.name
              )
            }
          >
            löschen
          </StyledButton>
        </div>

        <Container fluid style={{ padding: 0, margin: "70px 0 0 0" }}>
          <Row>{generateFields(userDetailForm)}</Row>
        </Container>
      </div>

      <DeleteDialog
        callback={alert}
        isOpen={isDeleteOpen}
        handleClose={handleDeleteDialogClose}
        handleCloseOnSubmit={handleDeleteDialogCloseOnSubmit}
        name={name}
        id={parseInt(id)}
        type="user"
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MuiAlert
          variant="filled"
          severity={successMessage[0]}
          onClose={handleSnackbarClose}
        >
          {successMessage[1]}
        </MuiAlert>
      </Snackbar>
    </section>
  );
};

export default UserDetail;
