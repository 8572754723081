import React, { useState } from "react";
import { makeStyles, fade } from "@material-ui/core/styles";
import { Container, Row, Col } from "react-bootstrap";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { OutlinedInput } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import { userForm, userData } from "./userData";
import { SECONDARY_LIGHT, WHITE } from "../../styles/colors";
import { StyledButton } from "../../components";
import http from "../../utils/http-common";

const useStyles = makeStyles(() => ({
  registerTitle: {
    textAlign: "center!important",
    fontWeight: "400!important",
    marginTop: 200,
  },
  form: {
    marginTop: 100,
  },
  field: {
    width: "100%",
    backgroundColor: SECONDARY_LIGHT + "!important",
    "&:-webkit-autofill": {
      webkitBoxSha: "0 0 0 30px white inset !important",
    },
  },
  label: {
    textTransform: "none",
    marginTop: "8px",
    fontSize: "13px",
  },
  required: {
    fontSize: "13px",
    marginTop: "16px",
    color: "#fffc !important",
    padding: "0 15px",
  },
  select: {
    padding: "10.5px 14px",
    backgroundColor: SECONDARY_LIGHT + "!important",
    "&:hover": {
      backgroundColor: fade(WHITE, 0.25) + "!important",
    },
    color: WHITE,
    width: "100%",
    fontSize: "14px",
  },
  selectIcon: {
    color: "#ffffff80!important",
  },
  error: {
    color: "#F26C51",
  },
}));

const Register = () => {
  const classes = useStyles();
  const [values, setValues] = useState(userData);
  const [error, setError] = useState("");
  const history = useHistory();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState([]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);

    if (successMessage[0] === "success") {
      history.push("login");
    }
  };

  const handleChange = (fieldId) => (event) => {
    setValues({ ...values, [fieldId]: event.target.value });
    removeError(fieldId);

    userForm.forEach((field) => {
      if (fieldId === field.id && event.target.value.length > field.max) {
        setError({
          ...error,
          [fieldId]:
            "Die maximale Länge dieses Feldes entspricht " +
            field.max +
            " Zeichen.",
        });
      }
    });
  };

  const handleOnBlur = (fieldId) => (event) => {
    removeError(fieldId);
    userForm.forEach((field) => {
      if (
        fieldId === field.id &&
        !document.getElementById(fieldId).validity.valid
      ) {
        if (field.required === true) {
          setError({
            ...error,
            [fieldId]: "Pflichtfeld",
          });
        }
        if (fieldId === "email" && event.target.value !== "") {
          setError({ ...error, [fieldId]: "Ungültige Emailadresse." });
        }
      }

      if (
        fieldId === field.id &&
        document.getElementById(fieldId).validity.valid &&
        fieldId === "email"
      ) {
        if (checkAbbotEmail(values[fieldId]) === false) {
          setError({ ...error, [fieldId]: "Keine Emailadresse von Abbott." });
        }
      }
      if (fieldId === field.id && event.target.value.length < field.min) {
        setError({
          ...error,
          [fieldId]:
            "Bitte geben Sie mindestens " + field.min + " Zeichen ein.",
        });
      }
      if (
        fieldId === "password_confirmation" &&
        values["password_confirmation"] !== values["password"]
      ) {
        setError({ ...error, [fieldId]: "Unterschiedliche Passwörter" });
      }
    });
  };

  const checkAbbotEmail = (email) => {
    email = email.trim();
    return email.endsWith("@abbott.com");
  };

  const isEmpty = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const removeError = (fieldId) => {
    const { [fieldId]: tmp, ...rest } = error;
    setError(rest);
  };

  const handleConfirm = async () => {
    checkFilled();

    if (isEmpty(error)) {
      await http
        .post("/register", values)
        .then(function (response) {
          if (response.status === 200) {
            let message = ["success", "Ihr Benutzerkonto wurde erfolgreich angelegt. Sie können sich nun einloggen."];
            setSuccessMessage(message);
            setSnackbarOpen(true);
          }
        })
        .catch(function (error) {
          if (error.response.data) {
            let message = ["error", error.response.data];
            setSuccessMessage(message);
            setSnackbarOpen(true);
          }
          console.log(error);
        });
    }
  };

  const checkFilled = () => {
    userForm.forEach((field) => {
      if (field.required === true && values[field.id] === "") {
        setError((error) => ({
          ...error,
          [field.id]: "Pflichtfeld",
        }));
      }
    });
  };

  const generateFormFields = () =>
    userForm.map((field) => {
      if (field.type === "text") {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
          >
            <div className={classes.label}>
              {field.label}
              {field.required === true ? "*" : ""}
            </div>
            <OutlinedInput
              id={field.id}
              type={field.inputType}
              required={field.required}
              margin="dense"
              size="small"
              className={classes.field}
              classes={{ input: classes.field }}
              onChange={handleChange(field.id)}
              onBlur={handleOnBlur(field.id)}
              autoComplete="off"
              value={values[field.id]}
              inputProps={{
                "aria-label": field.label,
              }}
            />
            {error[field.id] && (
              <FormHelperText
                id={"error-text-" + field.id}
                className={classes.error}
              >
                {error[field.id]}
              </FormHelperText>
            )}
          </Col>
        );
      }
      if (field.type === "select") {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
          >
            <FormControl style={{ width: "100%" }}>
              <div className={classes.label}>
                {field.label}
                {field.required === true ? "*" : ""}
              </div>
              <Select
                disableUnderline={true}
                value={values[field.id]}
                onChange={handleChange(field.id)}
                defaultValue={""}
                className={classes.field}
                classes={{ root: classes.select, icon: classes.selectIcon }}
                MenuProps={{
                  disableScrollLock: true,
                }}
              >
                <MenuItem className={classes.selectValues} value={""}>
                  <em>Keine Angabe</em>
                </MenuItem>
                {field.options.map((option) => {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      {option.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Col>
        );
      } else {
        return null;
      }
    });

  return (
    <div className="wrapper">
      <h2 className={classes.registerTitle}>Registrierung</h2>
      <form className={classes.form}>
        <Container fluid>
          <Row>{generateFormFields()}</Row>
        </Container>
      </form>
      <div className={classes.required}>* Pflichtfeld</div>
      <div style={{ textAlign: "end", marginTop: "12px" }}>
        <StyledButton
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleConfirm}
          type="submit"
        >
          registrieren
        </StyledButton>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MuiAlert
          variant="filled"
          severity={successMessage[0]}
          onClose={handleSnackbarClose}
        >
          {successMessage[1]}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Register;
