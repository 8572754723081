import { withStyles } from '@material-ui/core/styles';
import {WHITE, PRIMARY_DARK, PRIMARY_LIGHT} from "../../styles/colors";
import {emphasize} from "@material-ui/core/styles/colorManipulator";
import Button from "@material-ui/core/Button";

const FormButton = withStyles(() => ({
        root: {
            backgroundColor: PRIMARY_LIGHT,
            "&:hover, &:focus": {
                backgroundColor: emphasize(PRIMARY_DARK, 0.08),
                boxShadow: "none",
            },
            "&:active": {
                backgroundColor: emphasize(PRIMARY_DARK, 0.08),
                boxShadow: "none",
            },
            color: WHITE,
            borderRadius: 0,
            margin: 0,
            boxShadow: "none",
            height: "30px",
            textTransform: "none",
            padding: "0px 20px",
            marginTop: "30px",
            alignSelf: "flex-end",
            fontWeight: "400",
            "& .MuiSvgIcon-root": {
                fontSize: "18px"
            }
        }
    }
))(Button);

export default FormButton;