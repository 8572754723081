import axios from "axios";
import * as SecureLS from 'secure-ls';
import jwtDecode from "jwt-decode";

let ls = new SecureLS({ encodingType: 'aes' });

const http = axios.create({
  /* Wenn vor Ort: */
  // baseURL: "http://marge6-php72.grips.local/abbott-veranstaltungskalender-v2/web_laravel/public/api/",
  // baseURL: "https://marge6-php74.grips.local/abbott-veranstaltungskalender-v2/web_laravel/public/api/",
  /* Wenn via VPN: */
  // baseURL: "http://192.168.0.234:82/abbott-veranstaltungskalender-v2/web_laravel/public/api/",
  /* baseURL: "https://192.168.0.234:32769/abbott-veranstaltungskalender-v2/web_laravel/public/api/", */
  /* Online: */
  baseURL: "https://api.veranstaltungs-kalender.de/api",
  /* Devilbox: */
  // baseURL: "http://abbott-veranstaltungskalender-v2.loc/public/api/",
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": true
  }
});

http.interceptors.request.use(
  function (config) {
    const token = ls.get("token").data;
    //abgelaufenes Token für Testzwecke
    //const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9tYXJnZTYtcGhwNzIuZ3JpcHMubG9jYWxcL2FiYm90dC12ZXJhbnN0YWx0dW5nc2thbGVuZGVyLXYyXC93ZWJfbGFyYXZlbFwvcHVibGljXC9hcGlcL2xvZ2luIiwiaWF0IjoxNjA3NTAwODE1LCJleHAiOjE2MDc1MDQ0MTUsIm5iZiI6MTYwNzUwMDgxNSwianRpIjoib2hNUUYwYlN3aFdJZEdnQSIsInN1YiI6MSwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.SBWNyP9rEZQlIiJFFyh7JcLGAetWsWSbBwGA39GEKus"
    if (token) {
      const { exp } = jwtDecode(token)
      const expirationTime = (exp * 1000) - 60000
      // Apply authorization token to every request if logged in
      config.headers["Authorization"] = 'Bearer ' + token;
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        window.location.href = "/login"
      } 
    }
    else {
      delete config.headers["Authorization"];
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default http;