import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import {
  PRIMARY_LIGHT,
  SECONDARY_LIGHT,
  WHITE_TRANSPARENT,
} from "../../styles/colors";

const StyledCheckbox = withStyles({
  root: {
    color: WHITE_TRANSPARENT,
    paddingLeft: "20px",
    "&$checked": {
      color: PRIMARY_LIGHT,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(() => ({
  label: {
    fontSize: "12px",
    color: WHITE_TRANSPARENT,
    whiteSpace: "nowrap",
  },
  root: {
    backgroundColor: SECONDARY_LIGHT,
    paddingLeft: "12px",
    height: "33px",
    margin: "0 0 0 12px",
    width: "200px"
  },
}));

const CheckboxFilter = (props) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(props.currentValue);

  const handleChange = () => {
    props.callBack(!checked);
    setChecked(!checked);
  };

  return (
    <FormControlLabel
      control={
        <StyledCheckbox
          name={props.name}
          checked={checked}
          onChange={handleChange}
        />
      }
      label={props.label}
      labelPlacement="start"
      classes={{
        root: classes.root,
        label: classes.label,
      }}
    />
  );
};

CheckboxFilter.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  callBack: PropTypes.func.isRequired,
  currentValue: PropTypes.bool.isRequired,
};

export default CheckboxFilter;
