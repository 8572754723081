import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from "@material-ui/core/Typography";
import ChevronRightSharpIcon from '@material-ui/icons/ChevronRightSharp';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from "react-router-dom";

import { TopContent, ProgressBar, StyledTableRow, TableIconButton, NormalTableCell, FunctionsTableCell, StyledButton, StyledTooltip } from "../../components";
import { SECONDARY, SECONDARY_LIGHT } from "../../styles/colors";
import http from "../../utils/http-common";


const useStyles = makeStyles({
    table: {
        minWidth: 800,
    },
    tableButton: {
        padding: "0",
        marginRight: "10px",
    },
    title: {
        flex: "1 1 100%",
        textTransform: "uppercase",
        letterSpacing: "1px",
    },
    tableToolbar: {
        padding: "0",
        minHeight: "40px",
        marginTop: "100px",
        marginBottom: "10px",
    },
    topDistance: {
        marginTop: "50px",
    },
    darkerSection: {
        backgroundColor: SECONDARY,
        paddingBottom: "100px",
        "& .wrapper": {
            marginBottom: "auto",
        }
    },
    lighterSection: {
        backgroundColor: SECONDARY_LIGHT,
        padding: "100px 0px",
        "& .wrapper": {
            margin: "auto",
        }
    },
    lighterSectionTableRow: {
        backgroundColor: SECONDARY_LIGHT,
        '&:nth-of-type(even)': {
            backgroundColor: "rgba(255, 255, 255, 0.05)"
        },
        '&:hover': {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            cursor: "pointer",
        },
    },
    lighterSectionTableToolbar: {
        padding: "0px",
        minHeight: "40px",
        marginTop: "0px",
        marginBottom: "10px",
    },
    button: {
        minWidth: "153px"
    },
    hoverTableRow: {
        '&:hover': {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            cursor: "pointer",
        }
    },
});


const Overview = (props) => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:992px)');
    const [myUpcomingEvents, setMyUpcomingEvents] = useState([]);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const page = 0;
    const rowsPerPage = 6; const userId = JSON.parse(localStorage.getItem("user")).id;
    const history = useHistory();

    const formatDate = (dateStr) => {
        //let date = new Date(dateStr + "Z");
        let date = new Date(dateStr);
        return (date.toLocaleDateString("de-DE"));
    }

    useEffect(() => {
        const fetchMyUpcomingEvents = async () => {
            await http
                .get(`/events/${page + 1}`, {
                    params: { user_filter: userId, sort: "date_from", sort_dir: "ASC", count_physicians_state: "accepted" },
                })
                .then(function (response) {
                    // console.log(response)
                    setMyUpcomingEvents(response.data.events.data);
                })
                .catch(function (error) {
                    console.error(error);
                })
        };

        fetchMyUpcomingEvents();

        const fetchUpcomingEvents = async () => {
            await http
                .get(`/events/${page + 1}/${rowsPerPage}`, {
                    params: {sort: "date_from", sort_dir: "ASC"}
                })
                .then(function (response) {
                    setUpcomingEvents(response.data.events.data);
                })
                .catch(function (error) {
                    console.error(error);
                })
        };
        fetchUpcomingEvents();
    }, [userId, props]);

    return (
        <div>
            <section className={classes.darkerSection}>
                <div className="wrapper">
                    <TopContent page="Übersicht" />

                    <Toolbar className={classes.tableToolbar}>
                        <Typography
                            className={classes.title}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                        >
                            Meine Veranstaltungen
                    </Typography>
                        {matches === true ? <StyledButton
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            endIcon={<ChevronRightSharpIcon />}
                            onClick={() => {
                                history.push("/event")
                            }}
                        >alle anzeigen</StyledButton> : <TableIconButton aria-label="delete" size="small">
                                <ChevronRightSharpIcon style={{ fontSize: 18 }} />
                            </TableIconButton>}
                    </Toolbar>
                    <TableContainer >
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <NormalTableCell style={{ width: "4%" }}>ID</NormalTableCell>
                                    <NormalTableCell style={{ width: "9%" }}>Datum</NormalTableCell>
                                    <NormalTableCell style={{ width: "12%" }}>Ort</NormalTableCell>
                                    <NormalTableCell style={{ width: "35%" }}>Bezeichnung</NormalTableCell>
                                    <NormalTableCell style={{ width: "20%" }}>Auslastung</NormalTableCell>
                                    <FunctionsTableCell style={{ paddingRight: "16px" }} align="right">Funktionen</FunctionsTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {myUpcomingEvents.map((event) => (
                                    <StyledTableRow key={event.id} classes={{root: classes.hoverTableRow}}>
                                        <NormalTableCell component="th" scope="row" onClick={() => { history.push("/event/" + event.id) }}>
                                            {event.id}
                                        </NormalTableCell>
                                        <NormalTableCell onClick={() => { history.push("/event/" + event.id) }}>{formatDate(event.date_from)}</NormalTableCell>
                                        <NormalTableCell onClick={() => { history.push("/event/" + event.id) }}>{event.city}</NormalTableCell>
                                        <NormalTableCell onClick={() => { history.push("/event/" + event.id) }}>{event.event_name}</NormalTableCell>
                                        <NormalTableCell onClick={() => { history.push("/event/" + event.id) }}>
                                            <ProgressBar participant_limit={event.participant_limit ? event.participant_limit : 0} participant_number={event.counted_physicians ? event.counted_physicians : 0} />
                                        </NormalTableCell>
                                        <FunctionsTableCell align="right">
                                            <StyledTooltip title="Ansehen" arrow={true} enterDelay={600} enterNextDelay={600}>
                                                <TableIconButton aria-label="ansehen" size="small" onClick={() => {
                                                    history.push("/event/" + event.id)
                                                }}>
                                                    <VisibilityOutlinedIcon style={{ fontSize: 18 }} />
                                                </TableIconButton>
                                            </StyledTooltip>
                                        </FunctionsTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div >
            </section>

            <section className={classes.lighterSection}>
                <div className="wrapper" >

                    <Toolbar className={classes.lighterSectionTableToolbar}>
                        <Typography
                            className={classes.title}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                        >
                            Weitere Veranstaltungen
                    </Typography>
                    </Toolbar>
                    <TableContainer >
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <NormalTableCell style={{ backgroundColor: "rgba(255, 255, 255, 0.1)", width: "4%" }}>ID</NormalTableCell>
                                    <NormalTableCell style={{ backgroundColor: "rgba(255, 255, 255, 0.1)", width: "9%" }}>Datum</NormalTableCell>
                                    <NormalTableCell style={{ backgroundColor: "rgba(255, 255, 255, 0.1)", width: "12%" }}>Ort</NormalTableCell>
                                    <NormalTableCell style={{ backgroundColor: "rgba(255, 255, 255, 0.1)", width: "35%" }}>Bezeichnung</NormalTableCell>
                                    <NormalTableCell style={{ backgroundColor: "rgba(255, 255, 255, 0.1)", width: "20%" }}>Auslastung</NormalTableCell>
                                    <FunctionsTableCell style={{ backgroundColor: "rgba(255, 255, 255, 0.1)", paddingRight: "16px" }} align="right">Funktionen</FunctionsTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {upcomingEvents.map((event) => (
                                    <StyledTableRow key={event.id} className={classes.lighterSectionTableRow}>
                                        <NormalTableCell component="th" scope="row" onClick={() => { history.push("/event/" + event.id) }}>
                                            {event.id}
                                        </NormalTableCell>
                                        <NormalTableCell onClick={() => { history.push("/event/" + event.id) }}>{formatDate(event.date_from)}</NormalTableCell>
                                        <NormalTableCell onClick={() => { history.push("/event/" + event.id) }}>{event.city}</NormalTableCell>
                                        <NormalTableCell onClick={() => { history.push("/event/" + event.id) }}>{event.event_name}</NormalTableCell>
                                        <NormalTableCell onClick={() => { history.push("/event/" + event.id) }}>
                                            <ProgressBar participant_limit={event.participant_limit ? event.participant_limit : 0} participant_number={event.counted_physicians ? event.counted_physicians : 0} />
                                        </NormalTableCell>
                                        <FunctionsTableCell align="right">
                                            <StyledTooltip title="Ansehen" arrow={true} enterDelay={600} enterNextDelay={600}>
                                                <TableIconButton aria-label="ansehen" size="small" onClick={() => {
                                                    history.push("/event/" + event.id)
                                                }}>
                                                    <VisibilityOutlinedIcon style={{ fontSize: 18 }} />
                                                </TableIconButton>
                                            </StyledTooltip>
                                        </FunctionsTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div >
            </section>
        </div>
    );
}

export default Overview;