import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import EditSharpIcon from "@material-ui/icons/EditSharp";
import DeleteForeverSharpIcon from "@material-ui/icons/DeleteForeverSharp";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { Container, Col, Row } from "react-bootstrap";
import { format } from "date-fns";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getAuthUser } from "../../utils/authUser";
import ReactHtmlParser from "react-html-parser";
import ReactExport from "react-data-export";
import GetAppSharpIcon from "@material-ui/icons/GetAppSharp";

import http from "../../utils/http-common";
import clsx from "clsx";
import {
  eventDetailFormOverview,
  eventDetailFormGeneral,
  eventDetailFormAddress,
  eventDetailFormItems,
  eventDetailFormSponsors,
  eventDetailFormSpeakers,
} from "./eventData";

import {
  TopContent,
  StyledButton,
  DeleteEventDialog,
  NormalTableCell,
  FunctionsTableCell,
  StyledTableRow,
  StyledTooltip,
  TableIconButton,
  FilterableTableHead,
  SearchBar,
  TablePaginationActions,
  AddDoctorToEventDialog,
  StyledTab,
  StyledTabs,
  TabPanel,
} from "../../components";
import {
  SECONDARY,
  WHITE_TRANSPARENT,
  DESIGN_GREEN,
  DESIGN_GREEN_LIGHT,
  DESIGN_RED,
  MEDIUMGRAY,
  WHITE,
} from "../../styles/colors";

const headFunctionsCell = "119px";
const headCells = [
  { id: "physicians.id", label: "ID", filterable: true, width: "4%" },
  { id: "physicians.last_name", label: "Name", filterable: true, width: "21%" },
  {
    id: "companies.company_name",
    label: "Klinik / Praxis",
    filterable: true,
    width: "30%",
  },
  {
    id: "physicians.invited_from",
    label: "Eingeladen von",
    filterable: false,
    width: "21%",
  },
  { id: "physicians.status", label: "Status", filterable: false, width: "18%" },
];

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 800,
  },
  title: {
    flex: "1 1 100%",
    textTransform: "uppercase",
  },
  tableToolbar: {
    padding: "0",
    minHeight: "40px",
    marginTop: "50px",
    marginBottom: "10px",
  },
  darkerSection: {
    backgroundColor: SECONDARY,
  },
  pagination: {
    color: WHITE_TRANSPARENT,
    border: 0,
    backgroundColor: "#1a617f",
  },
  selectIcon: {
    color: WHITE_TRANSPARENT,
  },
  buttonMargin: {
    marginRight: "8px",
  },
  buttonMarginSmall: {
    marginTop: "5px",
    marginRight: "8px",
  },
  status: {
    height: "24px",
    width: "100%",
    padding: "2px 8px",
  },
  open: {
    backgroundColor: MEDIUMGRAY,
  },
  send: {
    backgroundColor: DESIGN_GREEN_LIGHT,
  },
  confirmed: {
    backgroundColor: DESIGN_GREEN,
  },
  rejected: {
    backgroundColor: DESIGN_RED,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  invitation: {
    width: "190px",
    float: "right",
  },
  progressBar: {
    textAlign: "end",
    marginLeft: "auto",
    width: "200px",
    height: "30px",
  },
  progressBarSmall: {
    textAlign: "end",
    width: "100%",
    height: "30px",
    margin: "10px 0",
  },
  progressHeight: {
    height: "30px!important",
  },
  label: {
    color: WHITE,
  },
  labelDetail: {
    textTransform: "none",
    marginTop: "8px",
    fontSize: "14px",
    color: WHITE_TRANSPARENT,
  },
  column: {
    marginBottom: "12px",
  },
  buttonSectionSmall: {
    display: "block",
    marginTop: "20px",
  },
}));

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 30,
    borderRadius: 0,
  },
  colorPrimary: {
    backgroundColor: DESIGN_GREEN_LIGHT,
  },
  bar: {
    borderRadius: 0,
    backgroundColor: DESIGN_GREEN,
  },
}))(LinearProgress);

const ProgressBar = (props) => {
  const classes = useStyles();
  let value = (props.participant_number / props.participant_limit) * 100;

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%">
        <BorderLinearProgress variant="determinate" value={value} />
      </Box>
      <Box minWidth={35} style={{ position: "absolute", paddingLeft: "5px" }}>
        <Typography variant="body2" className={classes.label}>
          {props.participant_number}/{props.participant_limit}
        </Typography>
      </Box>
    </Box>
  );
};

ProgressBar.propTypes = {
  participant_limit: PropTypes.number.isRequired,
  participant_number: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `event-tab-${index}`,
    "aria-controls": `event-tabpanel-${index}`,
  };
}

const EventDetail = (props) => {
  const classes = useStyles();
  const history = useHistory();
  let { id } = useParams();
  const [event, setEvent] = useState([]);
  const [isEventDeleteOpen, setIsEventDeleteOpen] = useState(false);
  const [isAddDoctorToEventOpen, setIsAddDoctorToEventOpen] = useState(false);
  const [name, setName] = useState("");
  const [doctors, setDoctors] = useState([]);
  const [doctorsChanged, setDoctorsChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [order, setOrder] = useState("ASC");
  const [orderBy, setOrderBy] = useState("physicians.id");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalEntries, setTotalEntries] = useState(0);
  const [tab, setTab] = React.useState(0);
  const [category, setCategory] = useState([]);
  const [topic, setTopic] = useState([]);
  const [organizer, setOrganizer] = useState([]);
  const matches = useMediaQuery("(max-width:620px)");
  const user = getAuthUser();
  const [role, setRole] = useState("external");
  const [excelData, setExcelData] = useState([]);
  const [acceptedDoctors, setAcceptedDoctors] = useState([]);

  /* const navigateToDetail = (id) => {
        history.push("/doctor/" + id);
    }; */

  // fetch User-Role
  useEffect(() => {
    if (user) {
      setRole(user.roles[0]);
    }
  }, [user]);

  // Change Tab
  const handleChangeTab = (event, newTab) => {
    setTab(newTab);
  };

  const sorting = (order, orderBy) => {
    setOrder(order.toUpperCase());
    setOrderBy(orderBy);
    setPage(0);
  };

  const search = async (str) => {
    setSearchStr(str);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
    history.goBack();
  };

  // Delete Dialog
  function openEventDeleteDialog(name) {
    setIsEventDeleteOpen(true);
    setName(name);
  }

  const handleEventDeleteDialogClose = () => {
    setIsEventDeleteOpen(false);
  };

  const handleEventDeleteDialogCloseOnSubmit = () => {
    setIsEventDeleteOpen(false);
  };

  // AddDoctorToEventDialog
  const openAddDoctorToEventDialog = (name) => {
    setIsAddDoctorToEventOpen(true);
    setName(name);
  };

  const handleAddDoctorToEventDialogClose = () => {
    setIsAddDoctorToEventOpen(false);
    setDoctorsChanged(!doctorsChanged);
  };

  const handleAddDoctorToEventDialogCloseOnSubmit = () => {
    setIsAddDoctorToEventOpen(false);
    setDoctorsChanged(!doctorsChanged);
  };

  // General Helper Functions
  const formatDate = (dateStr) => {
    let date = new Date(dateStr + "Z");
    return date.toLocaleDateString("de-DE");
  };

  const isPublished = (number) => {
    if (number === 0) {
      return "Nein";
    }
    if (number === 1) {
      return "Ja";
    }
    return "";
  };

  const getName = (lastname, title, firstname) => {
    let name = lastname;
    if (title !== null || firstname !== "") {
      name += ", ";
      if (title !== null) {
        name += title;
      }
      if (firstname !== "") {
        name += firstname;
      }
    }
    return name;
  };

  const sendInvitation = async (doctorId, state) => {
    await http
      .post("/event/invite/physician", {
        physician_id: doctorId,
        event_id: id,
        state: state,
      })
      .then((response) => {
        setDoctorsChanged(!doctorsChanged);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(function () {});
  };

  const alert = (result) => {
    setSuccessMessage(result);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    const fetchEvent = async () => {
      await http
        .get("/event/" + id)
        .then(function (response) {
          //console.log(response.data.event);
          setEvent(response.data.event);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    fetchEvent();
  }, [id]);

  useEffect(() => {
    const fetchCategory = async () => {
      await http
        .get("/category/" + event.category_id)
        .then(function (response) {
          setCategory(response.data.category);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    const fetchTopic = async () => {
      await http
        .get("/topic/" + event.topic_id)
        .then(function (response) {
          setTopic(response.data.topic);
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    if (event && event.category_id && event.category_id !== null) {
      fetchCategory();
    }

    if (event && event.topic_id && event.topic_id !== null) {
      fetchTopic();
    }

    const fetchOrganizer = async () => {
      await http
        .get("/organizer/" + event.organizer_id)
        .then(function (response) {
          setOrganizer(response.data.organizer);
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    if (event && event.organizer_id && event.organizer_id !== null) {
      fetchOrganizer();
    }
  }, [event]);

  useEffect(() => {
    let data = [];
    for (let i = 0; i < acceptedDoctors.length; i++) {
      if (acceptedDoctors[i].state === "accepted") {
        data.push({
          id: acceptedDoctors[i].id,
          name: getName(
            acceptedDoctors[i].last_name,
            acceptedDoctors[i].academic_degree,
            acceptedDoctors[i].first_name
          ),
          company_name: acceptedDoctors[i].company_name,
          email: acceptedDoctors[i].email,
          invitedBy: "",
        });
      }
    }
    setExcelData(data);
  }, [acceptedDoctors]);

  useEffect(() => {
    let mounted = true;
    const fetchDoctors = async () => {
      if (mounted) setIsLoading(true);
      await http
        .get(`/physicians/${page + 1}/${rowsPerPage}`, {
          params: {
            search: searchStr,
            sort: orderBy,
            sort_dir: order,
            event_filter: id,
          },
        })
        .then(function (response) {
          setTotalEntries(response.data.physicians.total);
          setDoctors(response.data.physicians.data);
          //console.log(response.data.physicians.data);
        })
        .catch(function (error) {
          console.log(error);
          if (error.response.data.status === "Token is Expired") {
            props.useLogout();
          }
        })
        .finally(() => {
          if (mounted) setIsLoading(false);
        });
    };

    fetchDoctors();

    return () => {
      mounted = false;
    };
  }, [searchStr, page, rowsPerPage, doctorsChanged, order, orderBy, props, id]);

  useEffect(() => {
    const fetchDoctors = async () => {
      await http
        .get(`/physicians/1`, {
          params: {
            event_filter: id,
          },
        })
        .then(function (response) {
          setAcceptedDoctors(response.data.physicians.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    fetchDoctors();
  }, [id]);

  const getCity = () => {
    if (event.addresses && event.addresses[0].city) {
      return " | " + event.addresses[0].city;
    }
    return "";
  };

  const generateFields = (formFields) =>
    formFields.map((field) => {
      if (field.type === "text") {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
            className={classes.column}
          >
            <div className={classes.labelDetail}>{field.label}</div>
            <Typography>{event[field.id] ? event[field.id] : " "}</Typography>
          </Col>
        );
      }
      if (field.type === "wysiwyg") {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
            className={classes.column}
          >
            <div className={classes.labelDetail}>{field.label}</div>
            <div>{event[field.id] ? ReactHtmlParser(event[field.id]) : ""}</div>
          </Col>
        );
      }
      if (field.type === "category") {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
            className={classes.column}
          >
            <div className={classes.labelDetail}>{field.label}</div>
            <Typography>{category.category_name}</Typography>
          </Col>
        );
      }
      if (field.type === "topic") {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
            className={classes.column}
          >
            <div className={classes.labelDetail}>{field.label}</div>
            <Typography>{topic.topic_name}</Typography>
          </Col>
        );
      }
      if (field.type === "date") {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
            className={classes.column}
          >
            <div className={classes.labelDetail}>{field.label}</div>
            <Typography>
              {event[field.id] ? formatDate(event[field.id]) : " "}
            </Typography>
          </Col>
        );
      }
      if (field.type === "time") {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
            className={classes.column}
          >
            <div className={classes.labelDetail}>{field.label}</div>
            <Typography>
              {event[field.id]
                ? format(new Date(event[field.id]), "H:mm")
                : " "}
            </Typography>
          </Col>
        );
      }
      if (field.type === "published") {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
            className={classes.column}
          >
            <div className={classes.labelDetail}>{field.label}</div>
            <Typography>{isPublished(event[field.id])}</Typography>
          </Col>
        );
      }
      if (field.type === "organizer") {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
            className={classes.column}
          >
            <div className={classes.labelDetail}>{field.label}</div>
            <Typography>{organizer.organizer_name}</Typography>
          </Col>
        );
      }
      if (field.type === "addresses") {
        if (event.addresses && event.addresses[0]) {
          return (
            <Col
              key={field.id}
              lg={field.colLg}
              md={field.colMd}
              sm={field.colSm}
              className={classes.column}
            >
              <div className={classes.labelDetail}>{field.label}</div>
              <Typography>{event.addresses[0][field.id]}</Typography>
            </Col>
          );
        }
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
            className={classes.column}
          >
            <div className={classes.labelDetail}>{field.label}</div>
            <Typography></Typography>
          </Col>
        );
      } else {
        return "";
      }
    });

  const generateItems = (formFields, item) =>
    formFields.map((field) => {
      if (field.inputType === "text") {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
            className={classes.column}
          >
            <div className={classes.labelDetail}>{field.label}</div>
            <Typography>{item[field.id] ? item[field.id] : " "}</Typography>
          </Col>
        );
      }
      if (field.inputType === "date") {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
            className={classes.column}
          >
            <div className={classes.labelDetail}>{field.label}</div>
            <Typography>
              {item[field.id] ? formatDate(item[field.id]) : " "}
            </Typography>
          </Col>
        );
      }
      if (field.inputType === "time") {
        return (
          <Col
            key={field.id}
            lg={field.colLg}
            md={field.colMd}
            sm={field.colSm}
            className={classes.column}
          >
            <div className={classes.labelDetail}>{field.label}</div>
            <Typography>
              {item[field.id] ? format(new Date(item[field.id]), "H:mm") : " "}
            </Typography>
          </Col>
        );
      } else {
        return "";
      }
    });

  return (
    <div>
      <section>
        <div className="wrapper">
          <TopContent
            page={event.event_name}
            subheading={formatDate(event.date_from) + getCity()}
          />

          {matches === false ? (
            <div className="buttonSection">
              <StyledButton
                startIcon={<ArrowBackIosSharpIcon />}
                onClick={() => {
                  history.goBack();
                }}
                classes={{ root: classes.buttonMargin }}
              >
                zurück
              </StyledButton>
              {role === "external" ? null : (
                <StyledButton
                  endIcon={<EditSharpIcon />}
                  classes={{ root: classes.buttonMargin }}
                  onClick={() => {
                    history.push("/event_edit/" + id);
                  }}
                >
                  bearbeiten
                </StyledButton>
              )}
              {role === "external" ? null : (
                <StyledButton
                  endIcon={<DeleteForeverSharpIcon />}
                  classes={{ root: classes.buttonMargin }}
                  onClick={() => openEventDeleteDialog(event.event_name)}
                >
                  löschen
                </StyledButton>
              )}
              <ExcelFile
                filename={"Teilnehmerliste " + event.event_name}
                element={
                  <StyledButton
                    endIcon={<GetAppSharpIcon />}
                    classes={{ root: classes.buttonMargin }}
                  >
                    Teilnehmerliste
                  </StyledButton>
                }
              >
                <ExcelSheet data={excelData} name="Teilnehmer">
                  <ExcelColumn label="ID" value="id" />
                  <ExcelColumn label="Name" value="name" />
                  <ExcelColumn label="Klinik / Praxis" value="company_name" />
                  <ExcelColumn label="E-Mail" value="email" />
                  <ExcelColumn label="Eingeladen von" value="invitedBy" />
                </ExcelSheet>
              </ExcelFile>

              <div className={classes.progressBar}>
                <ProgressBar
                  participant_limit={
                    event.participant_limit ? event.participant_limit : 0
                  }
                  participant_number={
                    event.counted_physicians_accepted
                      ? event.counted_physicians_accepted
                      : 0
                  }
                  classes={{ root: classes.progressHeight }}
                />
              </div>
            </div>
          ) : (
            <div className={classes.buttonSectionSmall}>
              <div className={classes.progressBarSmall}>
                <ProgressBar
                  participant_limit={
                    event.participant_limit ? event.participant_limit : 0
                  }
                  participant_number={
                    event.counted_physicians_accepted
                      ? event.counted_physicians_accepted
                      : 0
                  }
                  classes={{ root: classes.progressHeight }}
                />
              </div>
              <StyledButton
                startIcon={<ArrowBackIosSharpIcon />}
                onClick={() => {
                  history.goBack();
                }}
                classes={{ root: classes.buttonMarginSmall }}
              >
                zurück
              </StyledButton>
              {role === "external" ? null : (
                <StyledButton
                  endIcon={<EditSharpIcon />}
                  classes={{ root: classes.buttonMarginSmall }}
                  onClick={() => {
                    history.push("/event_edit/" + id);
                  }}
                >
                  bearbeiten
                </StyledButton>
              )}
              {role === "external" ? null : (
                <StyledButton
                  classes={{ root: classes.buttonMarginSmall }}
                  endIcon={<DeleteForeverSharpIcon />}
                  onClick={() => openEventDeleteDialog(event.event_name)}
                >
                  löschen
                </StyledButton>
              )}
              <ExcelFile
                filename={"Teilnehmerliste " + event.event_name}
                element={
                  <StyledButton
                    classes={{ root: classes.buttonMarginSmall }}
                    endIcon={<GetAppSharpIcon />}
                  >
                    Teilnehmerliste
                  </StyledButton>
                }
              >
                <ExcelSheet data={excelData} name="Teilnehmer">
                  <ExcelColumn label="ID" value="id" />
                  <ExcelColumn label="Name" value="name" />
                  <ExcelColumn label="Klinik / Praxis" value="company_name" />
                  <ExcelColumn label="E-Mail" value="email" />
                  <ExcelColumn label="Eingeladen von" value="invitedBy" />
                </ExcelSheet>
              </ExcelFile>
            </div>
          )}

          <div>
            <div>
              <StyledTabs
                value={tab}
                onChange={handleChangeTab}
                aria-label="disabled tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                <StyledTab label="Überblick" {...a11yProps(0)} />
                <StyledTab label="Allgemeine Daten" {...a11yProps(1)} />
                <StyledTab label="Adresse" {...a11yProps(2)} />
                <StyledTab label="Programmpunkte" {...a11yProps(3)} />
                <StyledTab label="Verantwortliche" {...a11yProps(4)} />
              </StyledTabs>
            </div>
            <TabPanel value={tab} index={0}>
              <Container fluid style={{ padding: 0, margin: "20px 0 0 0" }}>
                <Row>{generateFields(eventDetailFormOverview)}</Row>
              </Container>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Container fluid style={{ padding: 0, margin: "20px 0 0 0" }}>
                <Row>{generateFields(eventDetailFormGeneral)}</Row>
              </Container>
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <Container fluid style={{ padding: 0, margin: "20px 0 0 0" }}>
                <Row>{generateFields(eventDetailFormAddress)}</Row>
              </Container>
            </TabPanel>
            <TabPanel value={tab} index={3}>
              {event.items && event.items.length > 0 ? (
                <Container fluid style={{ padding: 0, margin: "20px 0 0 0" }}>
                  {event.items.map((item) => (
                    <Row key={item.id}>
                      {generateItems(eventDetailFormItems, item)}
                    </Row>
                  ))}
                </Container>
              ) : (
                <Container fluid style={{ padding: 0, margin: "20px 0 0 0" }}>
                  <Row>
                    <Col className={classes.column}>
                      <div className={classes.labelDetail}>
                        Keine Programmpunkte vorhanden
                      </div>
                    </Col>
                  </Row>
                </Container>
              )}
            </TabPanel>
            <TabPanel value={tab} index={4}>
              {event.speakers && event.speakers.length > 0 ? (
                <Container fluid style={{ padding: 0, margin: "20px 0 0 0" }}>
                  <b /* style={{ marginLeft: "-15px" }} */>Referenten</b>
                  {event.speakers.map((item) => (
                    <Row key={item.id}>
                      {generateItems(eventDetailFormSpeakers, item)}
                    </Row>
                  ))}
                </Container>
              ) : (
                <Container fluid style={{ padding: 0, margin: "20px 0 0 0" }}>
                  <Row>
                    <Col className={classes.column}>
                      <div className={classes.labelDetail}>
                        Keine Referenten vorhanden
                      </div>
                    </Col>
                  </Row>
                </Container>
              )}

              {event.sponsors && event.sponsors.length > 0 ? (
                <Container fluid style={{ padding: 0, margin: "20px 0 0 0" }}>
                  <b /* style={{ marginLeft: "-15px" }} */>Sponsoren</b>
                  {event.sponsors.map((item) => (
                    <Row key={item.id}>
                      {generateItems(eventDetailFormSponsors, item)}
                    </Row>
                  ))}
                </Container>
              ) : (
                <Container fluid style={{ padding: 0, margin: "20px 0 0 0" }}>
                  <Row>
                    <Col className={classes.column}>
                      <div className={classes.labelDetail}>
                        Keine Sponsoren vorhanden
                      </div>
                    </Col>
                  </Row>
                </Container>
              )}
            </TabPanel>
          </div>

          <Toolbar className={classes.tableToolbar}>
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Hinzugefügte Ärzte
              <StyledTooltip
                title="Arzt hinzufügen"
                arrow={true}
                enterDelay={600}
                enterNextDelay={600}
              >
                <TableIconButton
                  aria-label="add"
                  size="small"
                  style={{ marginBottom: "4px" }}
                  onClick={() => openAddDoctorToEventDialog(event.event_name)}
                >
                  <AddSharpIcon style={{ fontSize: 18 }} />
                </TableIconButton>
              </StyledTooltip>
            </Typography>
            <SearchBar callBack={search} />
          </Toolbar>

          <TableContainer>
            <Table className={classes.table} aria-label="customized table">
              <FilterableTableHead
                headCells={headCells}
                headFunctionsCell={headFunctionsCell}
                sortFunc={sorting}
              />
              <TableBody>
                {doctors.map((doctor) => (
                  <StyledTableRow key={doctor.id}>
                    <NormalTableCell
                      component="th"
                      scope="row" /* onClick={() => navigateToDetail(doctor.id)} */
                    >
                      {doctor.id}
                    </NormalTableCell>
                    <NormalTableCell /* onClick={() => navigateToDetail(doctor.id)} */
                    >
                      {getName(
                        doctor.last_name,
                        doctor.academic_degree,
                        doctor.first_name
                      )}
                    </NormalTableCell>
                    <NormalTableCell /* onClick={() => navigateToDetail(doctor.id)} */
                    >
                      {doctor.company_name}
                    </NormalTableCell>
                    <NormalTableCell /* onClick={() => navigateToDetail(doctor.id)} */
                    >
                      {doctor.invited_from || ""}
                    </NormalTableCell>
                    <NormalTableCell
                      /* onClick={() => navigateToDetail(doctor.id)} */ style={{
                        padding: "10px",
                      }}
                    >
                      {doctor.state === "open" && (
                        <div className={clsx(classes.status, classes.open)}>
                          offen
                        </div>
                      )}
                      {doctor.state === "invited" && (
                        <div className={clsx(classes.status, classes.send)}>
                          versendet
                        </div>
                      )}
                      {doctor.state === "accepted" && (
                        <div
                          className={clsx(classes.status, classes.confirmed)}
                        >
                          bestätigt
                        </div>
                      )}
                      {doctor.state === "rejected" && (
                        <div className={clsx(classes.status, classes.rejected)}>
                          abgelehnt
                        </div>
                      )}
                    </NormalTableCell>
                    <FunctionsTableCell>
                      {doctor.state === "open" && (
                        <StyledButton
                          className={classes.invitation}
                          onClick={() => {
                            sendInvitation(doctor.id, "invited");
                          }}
                        >
                          Einladung senden
                        </StyledButton>
                      )}
                      {doctor.state === "invited" && (
                        <StyledButton
                          className={classes.invitation}
                          onClick={() => {
                            sendInvitation(doctor.id, "invited");
                          }}
                        >
                          Einladung wiederholen
                        </StyledButton>
                      )}
                    </FunctionsTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
              <TableFooter>
                <StyledTableRow>
                  <TablePagination
                    className={classes.pagination}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    colSpan={headCells.length + 1}
                    count={totalEntries}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "Einträge pro Seite" },
                      classes: { icon: classes.selectIcon },
                      MenuProps: { disableScrollLock: true },
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </StyledTableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </section>
      <DeleteEventDialog
        isOpen={isEventDeleteOpen}
        handleClose={handleEventDeleteDialogClose}
        handleCloseOnSubmit={handleEventDeleteDialogCloseOnSubmit}
        name={name}
        callback={alert}
        id={parseInt(event.id)}
        type="event"
        invitedCount={parseInt(event.counted_physicians_invited)}
      />
      <AddDoctorToEventDialog
        isOpen={isAddDoctorToEventOpen}
        handleClose={handleAddDoctorToEventDialogClose}
        handleCloseOnSubmit={handleAddDoctorToEventDialogCloseOnSubmit}
        name={name}
        callback={alert}
        id={parseInt(event.id)}
        type="event"
        message=" wird dauerhaft gelöscht. Alle Teilnehmer und Veranstalter werden hierüber informiert."
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MuiAlert
          variant="filled"
          severity={successMessage[0]}
          onClose={handleSnackbarClose}
        >
          {successMessage[1]}
        </MuiAlert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default EventDetail;
