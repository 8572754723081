import { withStyles, } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { emphasize } from "@material-ui/core/styles/colorManipulator";

import { WHITE, PRIMARY_DARK, PRIMARY_LIGHT } from '../../styles/colors';

const StyledButton = withStyles(() => ({
    root: {
        backgroundColor: PRIMARY_LIGHT,
        "&:hover": {
            backgroundColor: emphasize(PRIMARY_DARK, 0.08),
            boxShadow: "none",
        },
        color: WHITE,
        borderRadius: 0,
        margin: 0,
        boxShadow: "none",
        height: "30px",
        textTransform: "none",
        padding: "0px 20px",
        fontWeight: "400",
        "& .MuiSvgIcon-root": {
            fontSize: "18px"
        }
    }
}
))(Button);

export default StyledButton;