import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Navbar, Nav } from "react-bootstrap";
import ExitToAppSharpIcon from "@material-ui/icons/ExitToAppSharp";

import Logo from "../../img/abbott-logo.png";
import Profile from "../profile/Profile";
import "./Header.css";
import { getAuthUser } from "../../utils/authUser";

const useStyles = makeStyles(() => ({
  logout: {
    color: "#fff",
    fontSize: "12px",
    padding: "2px",
  },
}));

const Header = (props) => {
  let path = useLocation().pathname;
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:992px)");
  const user = getAuthUser();
  const [showInternalLinks, setShowInternalLinks] = useState(false);
  const [showAdminLinks, setShowAdminLinks] = useState(false);

  useEffect(() => {
    if (user) {
      setShowInternalLinks(user.roles.includes("internal"));
      setShowAdminLinks(user.roles.includes("admin"));
    }
  }, [user]);

  return (
    <Navbar collapseOnSelect variant="dark" expand="md" fixed="top">
      <div className="nav-container navbar-top">
        {matches === true ? <Profile viewPort="mobile" /> : null}
        <Button
          onClick={props.useLogout}
          className={classes.logout}
          endIcon={<ExitToAppSharpIcon />}
        >
          Logout
        </Button>
      </div>
      <div className="nav-container navbar-bottom">
        <Navbar.Brand
          className="my-auto"
          href="/event"
          rel="noopener noreferrer"
          aria-label="Link zu Abbott"
        >
          <img
            alt="Abbott Logo"
            src={Logo}
            width="103"
            height="26"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav activeKey={path}>
            <Nav.Link
              href="/event"
              active={path.startsWith("/event") ? true : false}
            >
              VERANSTALTUNGEN
            </Nav.Link>
            <Nav.Link
              href="/doctor"
              active={path.startsWith("/doctor") ? true : false}
            >
              ÄRZTE
            </Nav.Link>
            <Nav.Link
              href="/users"
              active={path.startsWith("/user") ? true : false}
              eventKey="/users"
            >
              BENUTZER
            </Nav.Link>
            {showAdminLinks && (
              <Nav.Link href="/settings" eventKey="/settings">
                EINSTELLUNGEN
              </Nav.Link>
            )}
            {showInternalLinks && (
              <Nav.Link href="/settings" eventKey="/settings">
                EINSTELLUNGEN
              </Nav.Link>
            )}
            {showAdminLinks && (
              <Nav.Link href="/log" eventKey="/log">
                PROTOKOLL
              </Nav.Link>
            )}
            {/* {showAdminLinks && (
              <Nav.Link href="/backup" eventKey="/backup">
                BACKUP
              </Nav.Link>
            )} */}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default Header;
