// Externe Imports
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from "@material-ui/core/Typography";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { format } from "date-fns";
import InfoSharpIcon from '@material-ui/icons/InfoSharp';

// Interne Imports
import { TopContent, SearchBar, FilterableTableHead, StyledTableRow, NormalTableCell, TablePaginationActions, FunctionsTableCell, TableIconButton, StyledTooltip, LogDialog } from "../../components";
import { WHITE_TRANSPARENT } from "../../styles/colors";
import http from "../../utils/http-common";

// Styles
const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 800,
    },
    title: {
        flex: "1 1 100%",
        textTransform: "uppercase",
        letterSpacing: "1px",
    },
    tableToolbar: {
        padding: "0",
        minHeight: "40px",
        marginTop: "50px",
        marginBottom: "10px",
    },
    pagination: {
        color: WHITE_TRANSPARENT,
        border: 0,
        backgroundColor: "#1a617f",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    selectIcon: {
        color: WHITE_TRANSPARENT,
    },
}));

// Zellen für Tabellenkopf
const headCells = [
    { id: "protocols.id", label: "ID", filterable: true },
    { id: "protocols.updated_at", label: "Datum / Zeit", filterable: true },
    { id: "protocols.user_id", label: "Benutzer", filterable: true },
    { id: "protocols.tbl", label: "Tabelle", filterable: true },
    { id: "protocols.d_id", label: "Data-ID", filterable: true },
    { id: "protocols.method", label: "Aktion", filterable: true },
];

// Protokoll Komponente
const Log = (props) => {
    // Variablen
    const [protocolData, setProtocolData] = useState([]);
    const classes = useStyles();
    const [searchStr, setSearchStr] = useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [totalEntries, setTotalEntries] = React.useState(0);
    const [order, setOrder] = useState("DESC");
    const [orderBy, setOrderBy] = useState("protocols.updated_at");
    const [isLoading, setIsLoading] = useState(false);
    const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);
    const [selectedLog, setSelectedLog] = useState("");

    // Info Dialog
    const openInfoDialog = (logEntry) => {
        setSelectedLog(logEntry);
        setIsInfoDialogOpen(true);
    }

    const handleClose = () => {
        setIsInfoDialogOpen(false);
    }


    // Suche
    const search = async (str) => {
        setSearchStr(str);
        setPage(0);
    };

    // Sortieren
    const sorting = (order, orderBy) => {
        setOrder(order.toUpperCase());
        setOrderBy(orderBy);
        setPage(0);
    };

    // Pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    // Datum formatieren
    const formatDate = (date) => {
        return format(new Date(date), 'dd.MM.yyy HH:mm:ss')
    }

    // Data fetching
    useEffect(() => {
        const fetchProtocollData = async () => {
            setIsLoading(true);
            await http
                .get(`/protocols/${page + 1}/${rowsPerPage}`, {
                    params: { search: searchStr, sort: orderBy, sort_dir: order }
                })
                .then(function (response) {
                    //console.log(response.data.data.data);
                    setProtocolData(response.data.data.data);
                    setTotalEntries(response.data.data.total);
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
        fetchProtocollData();
    }, [page, rowsPerPage, props, searchStr, order, orderBy])


    // gerendertes HTML
    return (
        <div>
            <section>
                <div className="wrapper">
                    <TopContent page="Protokoll" />
                    <div className="buttonSection">
                    </div>
                    <Toolbar className={classes.tableToolbar}>
                        <Typography
                            className={classes.title}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                        >
                            Protokoll
                        </Typography>
                        <SearchBar callBack={search} />
                    </Toolbar>
                    <TableContainer>
                        <Table className={classes.table} aria-label="Protokoll Tabelle">
                            <FilterableTableHead headCells={headCells} sortFunc={sorting} functionCells={true} />
                            <TableBody>
                                {protocolData.map((data) => (
                                    <StyledTableRow key={data.id}>
                                        <NormalTableCell component="th" scope="row">{data.id}</NormalTableCell>
                                        <NormalTableCell> {formatDate(data.created_at)}</NormalTableCell>
                                        <NormalTableCell>{data.name}, {data.first_name}</NormalTableCell>
                                        <NormalTableCell>{data.tbl}</NormalTableCell>
                                        <NormalTableCell>{data.d_id}</NormalTableCell>
                                        <NormalTableCell>{data.method}</NormalTableCell>
                                        <FunctionsTableCell align="right">
                                            <StyledTooltip
                                                title="info"
                                                arrow={true}
                                                enterDelay={600}
                                                enterNextDelay={600}
                                            >
                                                <TableIconButton aria-label="bearbeiten" size="small" onClick={() => openInfoDialog(data.id)}>
                                                    <InfoSharpIcon style={{ fontSize: 18 }} />
                                                </TableIconButton>
                                            </StyledTooltip>
                                        </FunctionsTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <StyledTableRow>
                                    <TablePagination
                                        className={classes.pagination}
                                        rowsPerPageOptions={[5, 10, 25, 50]}
                                        colSpan={headCells.length + 1}
                                        count={totalEntries}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'Einträge pro Seite' },
                                            classes: { icon: classes.selectIcon },
                                            MenuProps: { disableScrollLock: true },
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                        labelRowsPerPage={"Einträge pro Seite"}
                                    />
                                </StyledTableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
            </section>
            <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <LogDialog 
                isOpen={isInfoDialogOpen}
                handleClose={handleClose}
                logEntry={selectedLog}
            />
        </div>
    );
}

export default Log;