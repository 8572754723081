import React, { useState } from "react";
import PropTypes from "prop-types";
import { fade, makeStyles } from "@material-ui/core/styles";
import SearchSharpIcon from "@material-ui/icons/SearchSharp";
import InputBase from "@material-ui/core/InputBase";

import { SECONDARY_LIGHT, WHITE_TRANSPARENT } from "../../styles/colors";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    borderRadius: 0,
    backgroundColor: fade(SECONDARY_LIGHT, 1),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: "12px",
    width: "100%",
    height: "33px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "12px",
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 1, 0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: WHITE_TRANSPARENT,
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 0, 1, 1),
    // vertical padding + font size from searchIcon
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100px",
      "&:focus": {
        width: "150px",
      },
    },
    fontSize: "12px!important",
  },
}));

const SearchBar = (props) => {
  const classes = useStyles();
  const [searchStr, setSearchStr] = useState("");

  return (
    <div className={classes.search}>
      <InputBase
        placeholder="Suche"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        defaultValue={props.currentValue || ""}
        inputProps={{ "aria-label": "suche" }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            props.callBack(searchStr);
            e.preventDefault();
          }
        }}
        onChange={(e) => {
          setSearchStr(e.target.value);
        }}
      />
      <div className={classes.searchIcon}>
        <SearchSharpIcon />
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  callBack: PropTypes.func.isRequired,
  currentValue: PropTypes.string,
};

export default SearchBar;
