import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/de";
/* import ReactHtmlParser from "react-html-parser"; */
import PropTypes from "prop-types";

import "./custom.css";

const CKEditorComponent = ({returnDescription, initialValue}) => {
  const [val, setVal] = useState("");

  useEffect(()=> {
    setVal(initialValue)
  }, [initialValue])

  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        data={val}
        onChange={(event, editor) => {
          const data = editor.getData();
          /* console.log({ event, editor, data }); */
          setVal(data);
          returnDescription(data);
        }}
        config={{
          toolbar: {
            items: [
              "heading",
              "|",
              "bold",
              "italic",
              "link",
              "bulletedList",
              "numberedList",
              "|",
              "undo",
              "redo",
            ],
          },
          language: "de",
        }}
      />
      {/* <div>{val ? ReactHtmlParser(val) : ""}</div> */}
    </div>
  );
};

CKEditorComponent.propTypes = {
  returnDescription: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
};

export default CKEditorComponent;
