import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import http from "../../utils/http-common";

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

import { useAuth } from "../../context/auth";
import { Colors } from '../../styles';
import "./Login.css";
import { WHITE } from "../../styles/colors";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { setAuthToken } from "../../utils/authToken";
import {setAuthUser} from "../../utils/authUser";




const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '100px'
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        maxWidth: '380px',
        width: '95%',
        backgroundColor: Colors.SECONDARY_LIGHT + "!important",
        '&:-webkit-autofill': {
            webkitBoxSha: "0 0 0 30px white inset !important",
        },
    },
    button: {
        color: '#fff',
        backgroundColor: Colors.PRIMARY,
        outline: 'none',
        borderRadius: 0,
        border: 'none',
        width: '95%',
        maxWidth: '380px',
        marginTop: '10px',
        padding: '12px',
        '&:hover': {
            backgroundColor: '#0078AB',
        }
    },
    error: {
        color: Colors.WHITE
    },
    icon: {
        color: WHITE,
    },
}));



const Login = () => {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [isError, setIsError] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { setAuthTokens } = useAuth();
    const classes = useStyles();
    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    });

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setIsError(false);
    };

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        setPassword(event.target.value);
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    async function postLogin() {
        await http
            .post("/login", {
                email,
                password
            }).then(result => {
                if (result.status === 200) {
                    localStorage.setItem("login", true);
                    setAuthToken(result.data.token);
                    setAuthTokens(result.data);
                    fetchUser();
                } else {
                    setIsError(true);
                }
            }).catch(e => {
                setIsError(true);
            });
    }


    const fetchUser = async () => {
        await http
            .get("/user")
            .then(function (response) {
                setAuthUser(response.data.result)
                setLoggedIn(true);
            })
            .catch(function (error) {
                console.log(error);
            });

    };


    if (isLoggedIn) {
        return <Redirect to='/event' />;
    }


    return (
        <div className="login-container">
            <h2 className="login-title">Login</h2>
            <form className={classes.root} noValidate autoComplete="off">
                <TextField className={clsx(classes.margin, classes.textField)} id="login-user"
                    label="Benutzer"
                    variant="outlined"
                    type="email"
                    value={email}
                    onChange={e => {
                        setEmail(e.target.value);
                    }} />

                <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                    <InputLabel htmlFor="login-password">Passwort</InputLabel>
                    <OutlinedInput
                        id="login-password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChange('password')}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                postLogin()
                            }
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    classes={{ root: classes.icon }}
                                >
                                    {values.showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </IconButton>
                            </InputAdornment>
                        }
                        labelWidth={70}
                    />
                </FormControl>
                <Button onClick={postLogin} className={classes.button}>Login</Button>
            </form>
            <Snackbar
                open={isError}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <MuiAlert
                    elevation={0}
                    variant="filled"
                    severity="error"
                    onClose={handleSnackbarClose}
                >
                    Der angegebene Benutzername oder das Passwort ist falsch!
                </MuiAlert>
            </Snackbar>
        </div>
    );
}

export default Login;