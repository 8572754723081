export const PRIMARY_LIGHT = '#00A8F1';
export const PRIMARY = '#0096D6';
export const PRIMARY_DARK = '#0085BD';

export const SECONDARY = '#004F71';    // BG dunkel
export const SECONDARY_LIGHT = '#1A617F';  // heller BG
export const SECONDARY_EXTRALIGHT = '#31718C';

export const CATEGORY_BLUE = '#004F71';
export const CATEGORY_RED = '#F26C51';
export const CATEGORY_GREEN = '#00AE41';
export const CATEGORY_YELLOW = '#F4B334';
export const CATEGORY_BLACK = '#242F3A';
export const CATEGORY_GRAY = '#959192';

export const DESIGN_GREEN = '#009847';
export const DESIGN_GREEN_LIGHT = '#7ec47b';
export const DESIGN_RED = '#F26C51';

export const WHITE = '#FFFFFF';
export const BLACK = '#000';
export const LIGHTGRAY = '#F5F5F5';
export const MEDIUMGRAY = '#B3B3B3';

export const WHITE_TRANSPARENT = 'rgba(255, 255, 255, 0.5)';
export const BLACK_TRANSPARENT = 'rgba(0, 0, 0, 0.2)';
export const BLACK_OPAQUE = 'rgba(0, 0, 0, 0.6)';

