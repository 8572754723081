import * as SecureLS from 'secure-ls';

const ls = new SecureLS({ encodingType: 'aes' });

export const setAuthUser = (user) => {
  if (user !== null) {
    ls.set('user', { data: user });
  } else {
    ls.remove('user');
  }
};


export const getAuthUser = () => {
  //console.log(ls.get('user').data)
  return ls.get('user').data;
}