import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';

import { WHITE, WHITE_TRANSPARENT, SECONDARY_LIGHT } from '../../../styles/colors';

const FunctionsTableCell = withStyles(() => ({
    head: {
        backgroundColor: SECONDARY_LIGHT,
        color: WHITE_TRANSPARENT,
        paddingLeft: "26px!important",
        lineHeight: "normal",
    },
    body: {
        fontSize: 14,
        padding: "10px 0 10px 16px",
        color: WHITE,
    },
    root: {
        borderBottom: "none",
        width: "177px",
    }
}))(TableCell);

export default FunctionsTableCell;